import moment from "moment";
import {
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAIL,
  RESET_MESSAGES_STATE,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  POST_ADD_MESSAGE_SUCCESS,
  POST_ADD_MESSAGE_FAIL,
  UPDATE_MESSAGE_RECEIVED,
  GET_MESSAGES,
  GET_MESSAGE_NEXT_SUCCESS,
  SELECT_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
} from "./actionTypes"
import { find } from "lodash";

const INIT_STATE = {
  conversationId: 0,
  messages: {
    count: 0,
    groups: [],
    nextGroups: [],
    hasNext: false,
    lastMessageUnixTime: 0,
  },
  selectedMessage: null,
  isRequested: false,
  error: {},
}

const loadMoreMessageState = (state, messages) => {
  let { count, groups, last_message_unix_time, has_next } = messages

  const userGroups = state.messages.nextGroups.concat(groups)
  return {
    count,
    nextGroups: userGroups,
    groups: state.messages.groups,
    hasNext: has_next,
    lastMessageUnixTime: last_message_unix_time
  }
}

const isMessageUnique = (groups, message) => {
  let messages = extractMessageOnly(groups)
  let found = find(messages, { 'id': message.id })

  if (found) {
    return false
  }
  return true
}

const extractMessageOnly = (groups) => {
  let messages = []
  if (!groups || groups.length < 1) {
    return messages
  }
  groups.map(group => {
    group.user_messages.map(userMsg => {
      userMsg.messages.map(msg => {
        messages.push(msg)
      })
    })
  })

  return messages
}

const updateMessageGroup = (groups, message) => {
  return groups.map(group => {
    let userMessages = group.user_messages.map(userMsg => {
      let messages = userMsg.messages.map(msg => {
        if (msg.id === message.id) {
          return message
        }
        return msg
      })

      return {
        user: userMsg.user,
        messages: messages,
      }
    })

    return {
      date: group.date,
      user_messages: userMessages,
    }
  })
}

const updateMessageState = (state, message) => {
  const { messages } = state;
  let { groups, count, hasNext, nextGroups, lastMessageUnixTime } = messages

  groups = updateMessageGroup(messages.groups, message)
  let msg_group = { groups, count, nextGroups, hasNext, lastMessageUnixTime }
  return msg_group
}

const addNewMessage = (state, message) => {
  const { messages } = state;
  const { count, groups, hasNext, nextGroups, lastMessageUnixTime } = messages

  const date = moment(message.created_at).format("YYYY-MM-DD")

  // NOTE: this code is to makesure other device get the same message
  // due to server also send message to sender, its to makesure
  // message is unique, thus we need to check if added message is unique
  if (!isMessageUnique(groups, message)) {
    return messages
  }

  let lastGroup = groups.pop()
  if (!lastGroup) {
    return {
      'groups': [{
        'date': date,
        'user_messages': [{
          'messages': [message],
          'user': {
            'id': message.sender_id,
            'fullname': message.sender_name
          }
        }]
      }],
      'count': 0,
    }
  }


  let isMessageAdded = false
  if (date == lastGroup.date) {
    const { user_messages } = lastGroup
    let lastMessageIndex = user_messages.length - 1
    let lastMessage = user_messages[lastMessageIndex]

    if (lastMessage.sender_id == message.sender_id) {
      lastGroup.user_messages[lastMessageIndex].messages.push(message)
      isMessageAdded = true
    }
  }

  if (isMessageAdded == false) {
    lastGroup.user_messages.push({
      'messages': [message],
      'user': {
        'id': message.sender_id,
        'fullname': message.sender_name,
      }
    })
  }

  groups.push(lastGroup)
  let msg_group = {
    groups,
    nextGroups,
    count,
    hasNext,
    lastMessageUnixTime
  }

  return msg_group

}

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MESSAGES:
      return {
        ...state,
        conversationId: action.payload.roomId,
        isRequested: true,
      }
    case RESET_MESSAGES_STATE:
      return {
        ...state,
        conversationId: 0,
        messages: {
          count: 0,
          groups: [],
          nextGroups: [],
          hasNext: false,
          lastMessageUnixTime: 0,
        },
        selectedMessage: null,
        error: {},
      }
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
      }

    case GET_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MESSAGE_NEXT_SUCCESS:
      return {
        ...state,
        isRequested: false,
        messages: loadMoreMessageState(state, action.payload),
      }

    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        isRequested: false,
        messages: {
          groups: action.payload.groups,
          nextGroups: [],
          count: action.payload.count,
          hasNext: action.payload.has_next,
          lastMessageUnixTime: action.payload.last_message_unix_time
        }
      }

    case GET_MESSAGES_FAIL:
      return {
        ...state,
        isRequested: false,
        error: action.payload,
      }

    case POST_ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: addNewMessage(state, action.payload),
      }

    case POST_ADD_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_MESSAGE_RECEIVED:
      return {
        ...state,
        messages: updateMessageState(state, action.payload)
      }

    case SELECT_MESSAGE:
      return {
        ...state,
        selectedMessage: action.payload,
      }

    default:
      return state
  }
}

export default Chat
