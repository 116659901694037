import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//chat
import chat from "./chat/reducer"

// Websocket
import WebSocket from './websocket/reducer';

//E-commerce
import ecommerce from "./e-commerce/reducer"

// Agent Intent
import AgentIntent from './agent-intent/reducer';

// Platform
import Platform from './platform/reducer'

// Store
import Store from './store/reducer';

// Product
import Product from './product/reducer';

// Ticket
import Ticket from './ticket/reducer';
import TicketComment from './ticket-comment/reducer';
import Conversation from './conversation/reducer';

// DATASET
import DatasetTrain from "./dataset-train/reducer";

// QNA Dictionary
import qnaDictionary from "./qna-dictionary/reducer";

// TRAINING DATASET
import TrainingDataset from "./training/reducer"

// Invoice
import Invoice from './invoices/reducer'

// Billing
import Billing from './billing/reducer'

// Company
import Company from './company/reducer'

// User
import User from './user/reducer'

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  chat,
  QNADictionary: qnaDictionary,
  Conversation,
  WebSocket,
  ecommerce,
  AgentIntent,
  Platform,
  Store,
  Ticket,
  TicketComment,
  Product,
  TrainingDataset,
  Invoice,
  Billing,
  Company,
  DatasetTrain,
  User,
})

export default rootReducer
