import React from "react";
import { Col, Row, Table } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import TableHeadComponent from "./TableHeadComponent";

const TableComponent = ({ dataHead, componentBody, loading }) => {
  const LoadingBodyTable = () => {
    return (
      <tbody>
        {[...Array(8)].map((items, idx) => {
          return (
            <tr key={idx}>
              {[...Array(dataHead.length)].map((item, index) => (
                <td key={index}>
                  <div style={{ display: "block" }}>
                    <Skeleton className="skeleton" height={20} />
                  </div>
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <Row>
      <Col xs={12} lg={12}>
        <div className="section-tabel">
          <Table responsive>
            <TableHeadComponent dataHead={dataHead} />
            {loading ? <LoadingBodyTable /> : componentBody}
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default TableComponent;
