import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import RegisterAuthStepVerification from "pages/Authentication/RegisterAuthStepVerification";

// Dashboard
// import Dashboard from "../pages/Dashboard/index"
import DashboardSaas from "../pages/Dashboard-saas/index";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// CHAT
import Chat from "pages/Chat/Chat";

// TrainingDataset
import TrainingDataset from "pages/TrainDataset/TrainDataset";
import DatasetTrain from "pages/Dataset/DatasetTrain";

// INVOICE
import InvoiceList from "pages/Invoice/InvoiceList";
import InvoiceDetail from "pages/Invoice/InvoiceDetail";
import SimulationChat from "pages/Chat/SimulationChat";

// BILLING
import BillingList from "pages/Billing/BillingList";

// ACCOUNT PAGE
import UserList from "pages/User/UserList";
import CompanyList from "pages/Company/CompanyList";
import StoreDetail from "pages/Store/StoreDetail";
import StoreList from "pages/Store/StoreList";
import LeaderBoard from "pages/Dataset/LeaderBoard";
import QNAKnowledgeList from "pages/KnowledgeBase/QNAKnowledgeList";
import UserDetail from "pages/User/UserDetail";
import ProductDetail from "pages/Product/ProductDetail";

import ListAccount from "pages/AccountManagement/ListAccount";
import ListTokopedia from "pages/AccountManagement/Business/ListTokopedia";
import Finance from "pages/Finance";
import DetailFinance from "pages/Finance/DetailFinance";
import Refund from "pages/Finance/Refund";
import DetailAccount from "pages/AccountManagement/DetailAccount";
import DetailTokopedia from "pages/AccountManagement/Business/DetailTokopedia";

const authProtectedRoutes = [
  { path: "/dashboard", component: DashboardSaas },

  { path: "/account", component: ListAccount },
  { path: "/account/details/:id", component: DetailAccount },

  { path: "/list-tokpedia", component: ListTokopedia },
  { path: "/list-tokpedia/details/:id", component: DetailTokopedia },

  { path: "/finance", component: Finance },
  { path: "/finance/details/:id", component: DetailFinance },

  { path: "/refund", component: Refund },

  { path: "/chat", component: Chat },
  { path: "/chat/:conversationId", component: Chat },
  { path: "/chat-simulation", component: SimulationChat },

  //Ecommerce
  { path: "/training-dataset", component: TrainingDataset },

  // Billing
  { path: "/billing", component: BillingList },
  { path: "/billing/invoice", component: InvoiceList },
  { path: "/billing/invoice-detail/:invoiceNumber", component: InvoiceDetail },

  // DATASET
  { path: "/dataset-train", component: DatasetTrain },
  { path: "/dataset-train/leader-board", component: LeaderBoard },
  // Knowledge base
  { path: "/dataset-train/qna", component: QNAKnowledgeList },

  // ACCOUNT
  { path: "/account/user", component: UserList },
  { path: "/account/user/:id", component: UserDetail },
  { path: "/account/company", component: CompanyList },
  { path: "/account/store", component: StoreList },
  { path: "/account/store/:id", component: StoreDetail },

  // PRODUCTS
  { path: "/product/detail/:id", component: ProductDetail },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  {
    path: "/register/auth-two-step-verification",
    component: RegisterAuthStepVerification,
  },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
];

export { authProtectedRoutes, publicRoutes };
