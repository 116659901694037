import {
  GET_CONVERSATION,
  GET_CONVERSATION_FAIL,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_DETAIL,
  GET_CONVERSATION_DETAIL_FAIL,
  GET_CONVERSATION_DETAIL_SUCCESS,
  GET_CONVERSATION_SIMULATION_STORE,
  NEW_CONVERSATION,
  NEW_CONVERSATION_SUCCESS,
  NEW_CONVERSATION_FAILED,
  UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_SUCCESS,
  UPDATE_CONVERSATION_FAIL,
  GET_CONVERSATION_SIMULATION,
  GET_CONVERSATION_MORE_SUCCESS,
} from "./actionTypes"


export const getConversationDetail = (conversation_id) => ({
  type: GET_CONVERSATION_DETAIL,
  payload: conversation_id,
})

export const getConversationSimulation = () => ({
  type: GET_CONVERSATION_SIMULATION,
})

export const getStoreConversationDetail = (store_id) => ({
  type: GET_CONVERSATION_SIMULATION_STORE,
  payload: store_id,
})

export const getConversationDetailSuccess = conversation => ({
  type: GET_CONVERSATION_DETAIL_SUCCESS,
  payload: conversation,
})

export const getConversationDetailFail = error => ({
  type: GET_CONVERSATION_DETAIL_FAIL,
  payload: error,
})

export const updateConversation = (id, data) => ({
  type: UPDATE_CONVERSATION,
  payload: {
    id,
    data,
  },
})

export const updateConversationSuccess = (payload) => ({
  type: UPDATE_CONVERSATION_SUCCESS,
  payload,
})

export const getConversationMoreSuccess = (payload) => ({
  type: GET_CONVERSATION_MORE_SUCCESS,
  payload,
})

export const updateConversationFail = (error) => ({
  type: UPDATE_CONVERSATION_FAIL,
  payload: error,
})


export const getConversation = (page = 1, platformId = 0, storeId = 0) => ({
  type: GET_CONVERSATION,
  payload: { page, platformId, storeId }
})

export const getConversationSuccess = (payload) => ({
  type: GET_CONVERSATION_SUCCESS,
  payload,
})

export const getConversationFail = error => ({
  type: GET_CONVERSATION_FAIL,
  payload: error,
})

export const newConversation = (payload) => ({
  type: NEW_CONVERSATION,
  payload,
})

export const newConversationSuccess = (payload) => ({
  type: NEW_CONVERSATION_SUCCESS,
  payload,
})

export const newConversationFailed = (payload) => ({
  type: NEW_CONVERSATION_FAILED,
  payload,
})
