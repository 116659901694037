import React from "react";
import { useHistory } from "react-router-dom";
import { STATUS_STYLES, handleStatusName } from "../DefaultData";

const TableBodyPlatformIntegration = ({ dataBody }) => {
  const history = useHistory();

  const onViewDetail = paramsId => {
    history.push(`/account/details/${paramsId}`);
  };

  if (dataBody.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan="5" style={{ textAlign: "center", padding: "20px 0px" }}>
            Platform belum ada.
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {dataBody.map((item, idx) => {
        return (
          <tr key={idx}>
            <td>{item.platform_name}</td>
            <td>{item.account}</td>
            <td>
              <span
                style={{
                  ...STATUS_STYLES[item.status],
                  textTransform: "capitalize",
                  padding: "4px 10px",
                  borderRadius: "12px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                {item.status ? handleStatusName(item.status) : "-"}
              </span>
            </td>
            <td>
              <span
                style={{ color: "#24B39B", cursor: "pointer" }}
                onClick={() => onViewDetail(item.id)}
              >
                Detail
              </span>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBodyPlatformIntegration;
