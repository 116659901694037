import { takeEvery, put, call, all, fork } from "redux-saga/effects";
import * as action from './actions'
import * as types from './actionTypes'
import * as api from './api'

function* onFetchCompanyRequest({ payload: { page, limit } }) {
  try {
    const response = yield call(api.getCompanyList, page, limit)
    yield put(action.fetchCompanySuccess(response))
  } catch (error) {
    yield put(action.fetchCompanyFailed(error))
  }
}


function* onGetCompanyRequest({ payload: { userId } }) {
  try {
    const response = yield call(api.getCompanyDetail, userId)
    yield put(action.getCompanySuccess(response))
  } catch (error) {
    yield put(action.getCompanyFailed(error))
  }
}

export function* watchCompany() {
  yield takeEvery(types.FETCH_COMPANY_REQUEST, onFetchCompanyRequest)
  yield takeEvery(types.GET_COMPANY_REQUEST, onGetCompanyRequest)
}

function* userSaga() {
  yield all([fork(watchCompany)]);
}

export default userSaga;
