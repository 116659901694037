const { get, post, put, del } = require("network/http/api")

export const fetch = (page) => {
  return get(`/admin/training-dataset/?page=${page}`)
}

export const statsTrain = () => {
  return get(`/admin/training-dataset/stats`)
}

export const updateLabel = (data) => {
  return put('/training-dataset/update-label', data)
}

export const corretLabel = (id) => {
  return put(`/admin/training-dataset/correct-label/${id}`)
}

export const predictAnswer = (id) => {
  return post(`/admin/training-dataset/predict-answer/${id}`)
}
