import React from 'react';
import { Alert } from 'reactstrap';

const AlertInfo = () => (
    <Alert color='info'>
    <p>
      INFO: Ketika <strong>Teacher Mode</strong> aktiv maka RISA AI tidak akan meresponse pertanyaan dari Customer dan masuk dalam mode belajar.
    </p>


    Hal ini memastikan agar user tidak mendapatkan dua response dari Teacher dan Risa, yang dapat menyebabkan kebingungan kepada Customer.
  </Alert>
)

export default AlertInfo
