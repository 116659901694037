import React from "react"
import { connect } from "react-redux"
import { Alert, Card, CardBody, CardHeader, List, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Spinner } from "reactstrap"
const UserGeoLocation = ({ userGeoLocation, isFetching }) => {
  if (!userGeoLocation) {
    return (
      <Card>
        <CardHeader>Geo Location</CardHeader>
        <CardBody>
          <Alert color="warning">User Geolocation not found</Alert>
        </CardBody>
      </Card>)
  }

  if (isFetching) {
    return <div className="text-center"><Spinner /></div>
  }

  return (
    <Card>
      <CardHeader>Geo Location</CardHeader>
      <ListGroup>
        <ListGroupItem>
          <ListGroupItemText>
            <strong>IP</strong> <br />
            {userGeoLocation.ip}
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem>
          <ListGroupItemText>
            <strong>Network</strong><br />
            {userGeoLocation.network}
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem>
          <ListGroupItemText>
            <strong>Lat, Lng</strong><br />
            {userGeoLocation.latitude}, {userGeoLocation.longitude}
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem>
          <ListGroupItemText>
            <strong>Country</strong><br />
            {userGeoLocation.country_name} ({userGeoLocation.country_code})
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem>
          <ListGroupItemText>
            <strong>City</strong><br />
            {userGeoLocation.city}
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem>
          <ListGroupItemText>
            <strong>Region</strong><br />
            {userGeoLocation.region}
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem>
          <ListGroupItemText>
            <strong>Timezone</strong>
            <br /> {userGeoLocation.timezone}
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem>
          <ListGroupItemText>
            <strong>ISP:</strong><br />
            {userGeoLocation.org} {userGeoLocation.asn}
          </ListGroupItemText>
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
}

const mapStateToProps = ({ User }) => ({
  'userGeoLocation': User.userGeoLocation,
  'isFetching': User.isFetching,
})

export default connect(
  mapStateToProps,
  null
)(UserGeoLocation)
