import { ExpansionPanel } from '@chatscope/chat-ui-kit-react'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import AlertInfo from './AlertInfo'

const TeacherModeInfo = ({conversation}) => {
  if(!conversation.is_teacher_take_over){
    return null
  }

  return (<ExpansionPanel open title='Teacher Mode'>
    <AlertInfo />
  </ExpansionPanel>)
}

export default TeacherModeInfo;
