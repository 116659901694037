import {
  SHOP_REGISTER_FAILED,
  SHOP_REGISTER_REQUEST,
  SHOP_REGISTER_SUCCES,
  GET_STORE_FAILED,
  GET_STORE_REQUEST,
  GET_STORE_SUCCES,
  GET_STORE_DETAIL_REQUEST,
  GET_STORE_DETAIL_SUCCES,
  GET_STORE_DETAIL_FAILED,
  GET_STORE_TRAIN_STATE_REQUEST,
  GET_STORE_TRAIN_STATE_SUCCES,
  GET_STORE_TRAIN_STATE_FAILED,
  SHOP_REGISTER_RESET,
  UPDATE_STORE_POLICY_REQUEST,
  UPDATE_STORE_POLICY_FAILED,
  UPDATE_STORE_POLICY_SUCCES,

  UPDATE_STORE_REQUEST,
  UPDATE_STORE_SUCCES,
  UPDATE_STORE_FAILED,
  UPDATE_STORE_STATUS_REQUEST
} from "./actionTypes"

const INIT_STATE = {
  detail: {
    isRequested: false,
    isSuccess: false,
    store: null,
    trainState: {
      isRequested: false,
      isSuccess: false,
      state: null,
    },
  },
  isFetching: false,
  stores: [],
  totalStore: 0,
  update: {
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  },
  updatePolicy: {
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  },
  error: {},
}

const Shop = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_STORE_REQUEST:
      return {
        ...state,
        update: {
          isRequested: true,
          isSuccess: false,
          isFailed: false,
        },
      }
    case UPDATE_STORE_SUCCES:
      return {
        ...state,
        update: {
          isRequested: false,
          isSuccess: true,
          isFailed: false,
        },
        detail: {
          isRequested: false,
          isSuccess: false,
          store: action.payload,
        },
      }
    case UPDATE_STORE_FAILED:
      return {
        ...state,
        update: {
          isRequested: false,
          isSuccess: false,
          isFailed: true,
        },
        error: action.payload,
      }
    case UPDATE_STORE_POLICY_REQUEST:
      return {
        ...state,
        updatePolicy: {
          isRequested: true,
          isSuccess: false,
          isFailed: false,
        },
        error: {},
      }
    case UPDATE_STORE_POLICY_SUCCES:
      return {
        ...state,
        detail: {
          isRequested: false,
          isSuccess: false,
          store: action.payload,
        },
        updatePolicy: {
          isRequested: false,
          isSuccess: true,
          isFailed: false,
        },
        error: {},
      }
    case UPDATE_STORE_POLICY_FAILED:
      return {
        ...state,
        updatePolicy: {
          isRequested: false,
          isSuccess: false,
          isFailed: true,
        },
        error: action.payload,
      }
    case GET_STORE_TRAIN_STATE_REQUEST:
      return {
        ...state,
        trainState: {
          isRequested: true,
          isSuccess: false,
          state: null,
        }
      }
    case GET_STORE_TRAIN_STATE_SUCCES:
      return {
        ...state,
        trainState: {
          isRequested: false,
          isSuccess: true,
          state: action.payload,
        }
      }
    case GET_STORE_TRAIN_STATE_FAILED:
      return {
        ...state,
        trainState: {
          isRequested: false,
          isSuccess: false,
          state: null,
        }
      }
    case GET_STORE_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          isRequested: true,
          isSuccess: false,
          store: null,
        },
        update: {
          isRequested: false,
          isSuccess: false,
          isFailed: false,
        },
      }
    case GET_STORE_DETAIL_SUCCES:
      return {
        ...state,
        detail: {
          isRequested: false,
          isSuccess: true,
          store: action.payload.store,
          products: action.payload.products,
        }
      }
    case GET_STORE_DETAIL_FAILED:
      return {
        ...state,
        detail: {
          isRequested: false,
          isSuccess: false,
          store: null,
        }
      }
    case GET_STORE_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_STORE_SUCCES:
      return {
        ...state,
        isFetching: false,
        stores: action.payload[0],
        totalStore: action.payload[1]
      }
    case UPDATE_STORE_STATUS_REQUEST:
      return {
        ...state,
        update: {
          isRequested: true,
          isSuccess: false,
          isFailed: false,
        },
      }
    case GET_STORE_FAILED:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      }
    default:
      return state
  }
}

export default Shop
