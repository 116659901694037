import React from "react"
import { Button, Col, Row, UncontrolledAlert } from "reactstrap"
import AllProductSyncStats from "./AllProductSyncStats"
import { useState } from "react"
import { post } from "network/http/api"
import ProductSyncVectorization from "./ProductSyncVectorization"

const ProductSync = ({ store }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(null)

  const handleOnSubmitSync = () => {
    setIsLoading(true)
    const data = {
      'store_id': store.id
    }
    post(`/admin/product/sync-store-product`, data)
      .then(resp => {
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        setIsError(err.detail)
      })
  }

  return (<Row>
    <Col xs="3">
      <Button disabled={isLoading} onClick={handleOnSubmitSync}>
        Sync with platform
      </Button>
      {isError &&
        <UncontrolledAlert className="font-size-8">
          {isError}
        </UncontrolledAlert>
      }
    </Col>
    <Col xs="3">
      <ProductSyncVectorization store={store} />
    </Col>
    <Col>
      <AllProductSyncStats store={store} />
    </Col>
  </Row>)
}

export default ProductSync
