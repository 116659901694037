import React, { Component, Fragment } from 'react'
import { Card, CardBody, CardHeader, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap'

class StoreShipment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shipments: {}
    }
  }

  componentDidMount() {
    this.setState({
      shipments: this.groupShipmentProduct()
    })
  }

  groupShipmentProduct() {
    const { shipment_info } = this.props.store

    if (!shipment_info) {
      return
    }

    let shipments = {}
    for (let index = 0; index < shipment_info.length; index++) {
      const el = shipment_info[index]
      const shipmentName = el.shipment
      if (shipmentName in shipments) {
        shipments[shipmentName].push(el)
      } else {
        shipments[shipmentName] = [el]
      }
    }

    return shipments
  }

  render() {
    const { shipments } = this.state;

    if (!shipments) {
      return <h4>Shipment Not Found</h4>
    }

    const shipmentObj = Object.keys(shipments).map((key) => (
      <ListGroupItem key={key}>
        <ListGroupItemHeading>
          {key}
        </ListGroupItemHeading>
        <ListGroup>
          {shipments[key].map((v, index) => (
            <Fragment key={index}>
              <ListGroupItem>
                <strong>Product:</strong> {v.product_name}
                <br />
                <strong>Is Available:</strong> {v.is_available ? 'Available' : 'Unavailable'}
              </ListGroupItem>
            </Fragment>
          ))}


        </ListGroup>
      </ListGroupItem>
    ))

    return (<Card>
      <CardHeader tag="h4" color='white'>
        Shipments
      </CardHeader>
      <CardBody>
        <ListGroup>
          {shipmentObj}
        </ListGroup>
      </CardBody>
    </Card>)
  }
}

export default StoreShipment
