import axios from "axios"
import { JWT_TOKEN_KEY } from "./jwt-token-access"

//pass new generated access token here
const token = localStorage.getItem(JWT_TOKEN_KEY)

//apply base url for axios
export const API_URL = process.env.REACT_APP_API_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = 'Bearer ' + token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config })
    .then(response => response.data)
    .catch(function (error) {
      let errorDetail = {
        'detail': handleErrorRequest(error),
        'status_code': error.response.status
      }
      throw errorDetail
    })
}

export const handleErrorRequest = (error) => {
  if (error.detail) {
    console.error('latest detail', error)
    return error.detail
  } else if (error.response) {
    console.error('error response detail', error.response)
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (typeof (error.response.data.detail) != 'string') {
      return 'Server cannot handle request'
    }
    return error.response.data.detail
  } else if (error.request) {
    console.log('error request', error)
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return "Internal server error"
  }

  console.error('someting to request', error)

  // Something happened in setting up the request that triggered an Error
  return error.message
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(function (error) {
      let errorDetail = {
        'detail': handleErrorRequest(error),
        'status_code': error.response.status
      }
      throw errorDetail
    })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(function (error) {
      let errorDetail = {
        'detail': handleErrorRequest(error),
        'status_code': error.response.status
      }
      throw errorDetail
    })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(function (error) {
      let status_code = 500
      if (error.response) {
        status_code = error.response.status
      }
      let errorDetail = {
        'detail': handleErrorRequest(error),
        'status_code': status_code
      }
      throw errorDetail
    })
}

export async function uploadFile(url, file) {
  var formData = new FormData();
  formData.append("file", file);
  return await axiosApi.post(url, formData)
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    })
}
