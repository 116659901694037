import {
  GET_STORE_FAILED,
  GET_STORE_SUCCES,
  GET_STORE_REQUEST,
  GET_STORE_DETAIL_REQUEST,
  GET_STORE_DETAIL_SUCCES,
  GET_STORE_DETAIL_FAILED,
  GET_STORE_TRAIN_STATE_REQUEST,
  GET_STORE_TRAIN_STATE_FAILED,
  GET_STORE_TRAIN_STATE_SUCCES,
  SHOP_REGISTER_RESET,
  UPDATE_STORE_POLICY_REQUEST,
  UPDATE_STORE_POLICY_SUCCES,
  UPDATE_STORE_POLICY_FAILED,
  UPDATE_STORE_REQUEST,
  UPDATE_STORE_SUCCES,
  UPDATE_STORE_FAILED,
  UPDATE_STORE_STATUS_REQUEST
} from "./actionTypes"

export const updateStoreRequest = (id, shop) => ({
  type: UPDATE_STORE_REQUEST,
  payload: {
    id,
    shop: shop,
  }
})

export const updateStoreSuccess = (payload) => ({
  type: UPDATE_STORE_SUCCES,
  payload,
})

export const updateStoreFailed = (error) => ({
  type: UPDATE_STORE_FAILED,
  payload: error,
})

export const registerStoreReset = () => ({
  type: SHOP_REGISTER_RESET,
})

export const registerStore = (payload, history) => ({
  type: SHOP_REGISTER_REQUEST,
  payload: {
    data: payload,
    history: history,
  },
})

export const registerStoreFailed = payload => ({
  type: SHOP_REGISTER_FAILED,
  payload,
})

export const registerStoreSuccess = payload => ({
  type: SHOP_REGISTER_SUCCES,
  payload,
})

export const getStore = (page = 1, limit = 10) => ({
  type: GET_STORE_REQUEST,
  payload: { page, limit }
})

export const getStoreFailed = payload => ({
  type: GET_STORE_FAILED,
  payload,
})

export const getStoreSuccess = payload => ({
  type: GET_STORE_SUCCES,
  payload,
})


export const getStoreDetail = (id) => ({
  type: GET_STORE_DETAIL_REQUEST,
  payload: id,
})

export const getStoreDetailFailed = payload => ({
  type: GET_STORE_DETAIL_FAILED,
  payload,
})

export const getStoreDetailSuccess = payload => ({
  type: GET_STORE_DETAIL_SUCCES,
  payload,
})

export const getStoreTrainState = (id) => ({
  type: GET_STORE_TRAIN_STATE_REQUEST,
  payload: id,
})

export const getStoreTrainStateFailed = payload => ({
  type: GET_STORE_TRAIN_STATE_FAILED,
  payload,
})

export const getStoreTrainStateSuccess = payload => ({
  type: GET_STORE_TRAIN_STATE_SUCCES,
  payload,
})

export const updateStorePolicy = (storeId, policy) => ({
  type: UPDATE_STORE_POLICY_REQUEST,
  payload: {
    storeId,
    policy
  }
})

export const updateStorePolicySuccess = (payload) => ({
  type: UPDATE_STORE_POLICY_SUCCES,
  payload: payload,
})

export const updateStorePolicyFailed = (payload) => ({
  type: UPDATE_STORE_POLICY_FAILED,
  payload,
})

export const updateStoreStatusAction = (store_id, status) => ({
  type: UPDATE_STORE_STATUS_REQUEST,
  payload: {
    store_id,
    status,
  }
})
