import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Container,
  Badge,
  Alert,
} from "reactstrap";
import { convertInt2Money } from "components/Product/ProductCardItem";
import CustomizeTable, { handleValidDate } from "components/CustomizeTable";
import queryString from "query-string";

// redux action
import { getStore } from "store/store/actions";

const columns = [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, user) => <>{order.id}</>,
  },
  {
    dataField: "id",
    text: "Store ID",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to={`/account/store/${row.id}`} className="fw-bold">
        {row.id}
      </Link>
    ),
  },
  {
    dataField: "store_name",
    text: "Store Name",
    formatter: (cellContent, row) => (
      <Link to={`/account/store/${row.id}`} className="fw-bold">
        {cellContent}
      </Link>
    ),
  },
  {
    dataField: "platform",
    text: "Platform",
    formatter: (cell, row) => {
      return row.platform.platform_name
    }
  },
  {
    dataField: "domain",
    text: "Domain",
  },
  {
    dataField: "store_url",
    text: "Store URL",
  },
  {
    dataField: "authorize_status",
    text: "Status",
  },
  {
    dataField: "created_at",
    text: "Date",
    formatter: (cellContent, row) => handleValidDate(row.created_at),
  },
]

const StoreList = ({
  location,
  history,
  onGetStore,
  error,
  isFetching,
  stores,
  totalStore }) => {

  let qs = queryString.parse(location.search)
  const [page, setPage] = useState(parseInt(qs.page) || 1)
  const [pagePerSize, setPagePerSize] = useState(parseInt(qs.limit) || 10)

  useEffect(() => {
    if (isFetching) {
      return
    }

    let qs = queryString.parse(location.search)
    onGetStore(qs.page, qs.limit)
  }, [location])


  const handleOnPageChange = (type, { page, sizePerPage }) => {
    const { pathname } = location
    setPage(page)
    setPagePerSize(sizePerPage)
    history.replace(`${pathname}?page=${page}&&limit=${sizePerPage}`)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {(error && error.detail) &&
            <Alert color="danger">
              {error.detail}
            </Alert>
          }

          <CustomizeTable
            page={page}
            loading={isFetching}
            totalPage={totalStore}
            onPageChange={handleOnPageChange}
            columns={columns}
            pagePerSize={pagePerSize}
            data={stores} />

        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = ({ Store }) => ({
  stores: Store.stores,
  totalStore: Store.totalStore,
  isFetching: Store.isFetching,
  error: Store.error,
});

export default connect(
  mapStateToProps,
  {
    'onGetStore': getStore
  }
)(withRouter(StoreList));
