import React, { useState, useEffect } from "react";
import moment from "moment";
import formatCurrency from "network/helper/currency";
import BackButton from "components/BackButton";
import Breadcrumb from "components/Breadcrumb";
import { useParams } from "react-router-dom";
import { get } from "network/http/api";
import { STATUS_STYLES, handleStatusName } from "./DefaultData";
moment.locale("id");

const DetailFinance = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dataCompany, setDataCompany] = useState(null);
  const breadcrumbItems = [
    { label: "Daftar Transaksi", path: "/finance" },
    { label: "Detail Transaksi", path: `/finance/details/${id}` },
  ];

  const fetchFinanceDetail = async paramsId => {
    setLoading(true);
    try {
      const res = await get(`/admin/billing/invoice/${paramsId}`);
      console.log(res, "invoice");
      setData(res.invoice);
      setDataCompany(res.company);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFinanceDetail(id);
  }, [id]);

  return (
    <div className="page-container">
      <div className="page-content-costume">
        <BackButton />
        <div style={{ margin: "28px 0px 32px" }}>
          <Breadcrumb items={breadcrumbItems} />
        </div>

        <div className="page-detail-finance">
          <div className="section-content-finance">
            <span className="title">Detail Transaksi</span>
            <div className="content-detail">
              <div className="content-detail-items">
                <span className="items-title">Nomor Invoice</span>
                <span className="items">
                  {data ? data.invoice_number : "-"}
                </span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Nominal Transaksi</span>
                <span className="items">
                  {data ? formatCurrency(data.total) : "-"}
                </span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Tanggal Terbit</span>
                <span className="items">
                  {data ? moment(data.created_at).format("DD/MM/YYYY") : "-"}
                </span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Status</span>
                <div className="items">
                  {data ? (
                    <span
                      style={{
                        ...STATUS_STYLES[data.status],
                        textTransform: "capitalize",
                        padding: "4px 10px",
                        borderRadius: "12px",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {handleStatusName(data.status)}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Nomor Referensi</span>
                <span className="items">
                  {data ? data.invoice_number : "-"}
                </span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Paket Basic</span>
                <span className="items">{data ? data.plan_name : "-"}</span>
              </div>
              {/* <div className="content-detail-items">
                <span className="items-title">Paket Add-on Terdaftar</span>
                <span className="items">
                  {data ? data.invoice_number : "-"}
                </span>
              </div> */}
              {data?.status !== "unpaid" && (
                <div className="content-detail-items last">
                  <span className="items-title">Masa Berlaku Paket</span>
                  <span className="items">
                    {data ? moment(data.paid_date).format("DD/MM/YYYY") : "-"}{" "}
                    to {data ? moment(data.due_date).format("DD/MM/YYYY") : "-"}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="section-content-finance">
            <span className="title">Informasi User</span>
            <div className="content-detail">
              <div className="content-detail-items">
                <span className="items-title">Nama Subscriber</span>
                <span className="items">
                  {dataCompany ? dataCompany.name : "-"}
                </span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Nama Perusahaan</span>
                <span className="items primary-color">
                  {dataCompany ? dataCompany.name : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailFinance;
