import moment from "moment";
import {
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_FAIL,
  GET_CONVERSATION_DETAIL_SUCCESS,
  GET_CONVERSATION,
  GET_CONVERSATION_DETAIL,
  GET_CONVERSATION_DETAIL_FAIL,
  NEW_CONVERSATION_FAILED,
  NEW_CONVERSATION_SUCCESS,
  UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_FAIL,
  GET_CONVERSATION_MORE_SUCCESS,
  UPDATE_CONVERSATION_SUCCESS,
} from "./actionTypes"
import { uniqBy } from "lodash";

const INIT_STATE = {
  conversation: {
    conversations: [],
    page: 1,
  },
  totalConversation: 0,
  conversationDetail: {
    title: ''
  },
  fetchingConversation: false,
  fetchingDetail: false,
  updateRequest: false,
  error: {},
}

const addConversation = (state, payload) => {
  const { conversations, page, platforms, users } = payload
  const new_conversation = state.conversation.conversations.concat(conversations)

  return {
    conversations: uniqBy(new_conversation, 'id'),
    platforms,
    users,
    page: page + 1,
  }
}

const updateConversation = (state, payload) => {
  let { conversations, page, platforms, users } = state.conversation

  conversations = conversations.map(v => {
    if (v.id == payload.id) {
      return payload
    }
    return v
  })

  return {
    conversations,
    page,
    platforms,
    users,
  }
}


const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_CONVERSATION:
      return {
        ...state,
        updateRequest: true,
      }
    case UPDATE_CONVERSATION_FAIL:
      return {
        ...state,
        updateRequest: false,
        error: action.payload,
      }
    case UPDATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        updateRequest: false,
        conversationDetail: action.payload,
        conversation: updateConversation(state, action.payload)
      }
    case GET_CONVERSATION_DETAIL:
      return {
        ...state,
        fetchingDetail: true,
        conversationDetail: {
          id: action.payload
        }
      }
    case GET_CONVERSATION_DETAIL_SUCCESS:
      return {
        ...state,
        fetchingDetail: false,
        error: {},
        conversationDetail: {
          ...action.payload.conversation,
          platform: action.payload.platform,
          store: action.payload.store,
        }
      }
    case GET_CONVERSATION_DETAIL_FAIL:
      return {
        ...state,
        fetchingDetail: false,
        error: action.payload,
      }
    case GET_CONVERSATION:
      return {
        ...state,
        fetchingConversation: true
      }
    case GET_CONVERSATION_MORE_SUCCESS:
      return {
        ...state,
        fetchingConversation: false,
        error: {},
        totalConversation: action.payload.total_conversation,
        conversation: addConversation(state, action.payload),
      }
    case GET_CONVERSATION_SUCCESS:
      return {
        ...state,
        fetchingConversation: false,
        error: {},
        totalConversation: action.payload.total_conversation,
        conversation: action.payload,
      }
    case NEW_CONVERSATION_SUCCESS:
      return {
        ...state,
        error: {},
        conversationDetail: action.payload,
      }
    case NEW_CONVERSATION_FAILED:
    case GET_CONVERSATION_FAIL:
      return {
        ...state,
        fetchingConversation: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Chat
