import Breadcrumbs from 'components/Common/Breadcrumb';
import CustomizeTable, { handleValidDate } from 'components/CustomizeTable';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Button, Col, Container, Row, Spinner } from 'reactstrap';
import { getBilling } from 'store/billing/actions';
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import { convertInt2Money } from 'components/PlanService';

const columns = [
  {
    dataField: "id",
    text: "Billing ID",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to={`/account/billing/${row.id}`} className="fw-bold">
        {row.id}
      </Link>
    ),
  },
  {
    dataField: "owner",
    text: "Billing Name",
    sort: true,
    formatter: (c, row) => c['fullname'] || c['username'],
  },
  {
    dataField: "plan_name",
    text: "Plan Name",
  },
  {
    dataField: "limit_conversation",
    text: "Limit Conversation",
  },
  {
    dataField: "limit_sub_account",
    text: "Limit Sub Account",
  },
  {
    dataField: "base_price",
    text: "Base Price",
    formatter: (row) => {
      return convertInt2Money(row)
    }
  },
  {
    dataField: "created_at",
    text: "Created Date",
    sort: true,
    formatter: (cellContent, row) => handleValidDate(cellContent),
  },

]

const BillingList = ({ location, match, history, onGetBilling, billing, totalPage, isFetching }) => {
  let qs = queryString.parse(location.search)
  const [page, setPage] = useState(parseInt(qs.page) || 1)

  useEffect(() => {
    onGetBilling()
  }, [])

  useEffect(() => {
    let qs = queryString.parse(location.search)
    onGetBilling(qs.page)
  }, [location])

  const handleOnPageChange = (type, { page, sizePerPage }) => {
    const { pathname } = location
    setPage(page)
    history.replace(`${pathname}?page=${page}`)
  }

  return (<React.Fragment>
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Billing" breadcrumbItem="All Billing" />

        <Row>
          <Col xs="12">
            <CustomizeTable
              page={page}
              loading={isFetching}
              totalPage={totalPage}
              onPageChange={handleOnPageChange}
              columns={columns}
              data={billing} />
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
  )

}

const mapStateToProps = ({ Billing }) => ({
  'isFetching': Billing.isFetching,
  'billing': Billing.billing,
  'totalPage': Billing.totalPage,
})

export default withRouter(connect(mapStateToProps, {
  'onGetBilling': getBilling
})(BillingList))
