import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
// actions
import { getStore } from 'store/store/actions'


const SelectStoreInput = ({ value, onChange, stores, isFetching, getStore }) => {

  useEffect(() => {
    if (stores && stores.lengt > 0) {
      return
    }

    getStore()
  }, [])

  return (
    <div>
      <Input value={value} disabled={isFetching}
        onChange={(e) => onChange(e.target.value)}
        type='select'>
        <option value={0}>
          All Store
        </option>
        {stores.map((pl, i) => (
          <option value={pl.id} key={i}>
            {pl.store_name}
          </option>
        ))}
      </Input>

    </div>
  )
}

const mapStateToProps = ({ Store }) => {
  return {
    stores: Store.stores,
    isFetching: Store.isFetching
  }
}

export default connect(mapStateToProps, { getStore })(SelectStoreInput)
