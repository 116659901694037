import React, { useState, useEffect } from "react";
import { get } from "network/http/api";
import { debounce } from "lodash";
import SectionHeader from "components/Page/SectionHeader";
import SearchComponentfrom from "components/Page/SearchComponent";
import TableComponent from "components/Page/TableComponent";
import TableBodyTokopedia from "../ComponentsPage/TableBodyTokopedia";
import PagginationButton from "components/PagginationButton";

const ListTokopedia = () => {
  const [dataBusiness, setDataBusiness] = useState([]);
  const [paramsPage, setParamsPage] = useState({
    perPage: 10,
    page: 1,
    query: "",
  });
  const [totalPage, setTotalPage] = useState(0);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchUser = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      let params = `page=${paramsPage.page}&&limit=${paramsPage.perPage}`;
      let url = `/admin/account/store`;
      if (query !== "") {
        url = `/admin/account/search/store?keyword=${query}`;
        const res = await get(url);
        setLoading(false);
        setDataBusiness(res);
      } else {
        url = `${url}?${params}`;
        const res = await get(url);
        setLoading(false);
        setDataBusiness(res.stores);
        const totalPage = res.total_item
          ? Math.ceil(res.total_item / paramsPage.perPage)
          : 0;
        setTotalPage(totalPage);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    if (loading) return;

    const fetch = debounce(fetchUser, 500);
    fetch();

    return () => fetch.cancel();
  }, [query, paramsPage]);

  return (
    <div className="page-container">
      <div className="page-content-costume">
        <SectionHeader
          title="Toko Management"
          subtitle="Daftar Toko dari pengguna layanan RISA AI"
        />
        <div className="section-content page-finance">
          <div className="section-filter">
            <SearchComponentfrom type="full" setQuery={setQuery} />
          </div>
          <div className="section-table">
            <TableComponent
              dataHead={[
                "Nama",
                "Platform",
                "Url Toko",
                "Status",
                "Registered",
                "Aksi",
              ]}
              componentBody={<TableBodyTokopedia dataBody={dataBusiness} />}
              loading={loading}
            />
            <PagginationButton
              paramsPage={paramsPage}
              setParamsPage={setParamsPage}
              amountPage={totalPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListTokopedia;
