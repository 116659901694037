import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import * as actions from "./actions"
import * as api from './api'
import * as types from "./actionTypes"

function* onGetTrainingStats(){
  try{
    const response = yield call(api.statsTrain)

    yield put(actions.getStatsTrainingDatasetSuccess(response))
  } catch(error) {
    console.error(error)
    yield put(actions.getStatsTrainingDatasetFailed(error))
  }
}

function* onCorrectTrainingLabel({payload: {id}}){
  try{
    const response = yield call(api.corretLabel, id)

    yield put(actions.correctTrainingLabelDatasetSuccess(response))
    yield put(actions.getTrainingDataset())
  } catch(error) {
    console.error(error)
    yield put(actions.correctTrainingLabelDatasetFailed(error))
  }
}


function* onUpdateTrainingLabel({payload: {id, newLabel}}){
  try{
    const data = {
      id,
      new_label: newLabel
    }
    const response = yield call(api.updateLabel, data)

    yield put(actions.updateTrainingLabelDatasetSuccess(response))
    yield put(actions.getTrainingDataset())
  } catch(error) {
    console.error(error)
    yield put(actions.updateTrainingLabelDatasetFailed(error))
  }
}

function* onGetTrainingDataset({payload: {page}}){
  try{
    const response = yield call(api.fetch, page)
    yield put(actions.getTrainingDatasetSuccess(response))
  } catch(error) {
    console.error(error)
    yield put(actions.getTrainingDatasetFailed(error))
  }
}

function* onPredictData({payload}){
  try{
    const response = yield call(api.predictAnswer, payload)
    yield put(actions.predictAnswerSuccess(response))
  } catch(error) {
    console.error(error)
    yield put(actions.predictAnswerFailed(error))
  }
}

function* trainingSaga() {
  yield takeEvery(types.GET_TRAINING_DATASET_REQUEST, onGetTrainingDataset)
  yield takeEvery(types.UPDATE_TRAINING_LABEL_REQUEST, onUpdateTrainingLabel)
  yield takeEvery(types.CORRECT_TRAINING_LABEL_REQUEST, onCorrectTrainingLabel)
  yield takeEvery(types.GET_TRAINING_STATS_REQUEST, onGetTrainingStats)
  yield takeEvery(types.PREDICT_TRAINING_LABEL_REQUEST, onPredictData)
}

export default trainingSaga
