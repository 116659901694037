import {
  GET_BILLING,
  GET_BILLING_FAIL,
  GET_BILLING_SUCCESS,
} from "./actionTypes"

export const getBilling = (page = 1, limit = 10) => ({
  type: GET_BILLING,
  payload: { page, limit }
})

export const getBillingSuccess = payload => ({
  type: GET_BILLING_SUCCESS,
  payload: payload,
})

export const getBillingFail = error => ({
  type: GET_BILLING_FAIL,
  payload: error,
})
