import React, { Fragment, useEffect, useRef, useState } from 'react'
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  Avatar,
  ConversationHeader,
} from "@chatscope/chat-ui-kit-react";
import { connect } from 'react-redux';
import zoeIco from 'assets/images/logo.svg';
import {
  addMessage,
  getChats,
  getMessages,
} from "store/actions";
import { withRouter } from 'react-router-dom';
import moment from 'moment';
// import MessageBubbleUIKit from './MessageBubbleUIKit';
import messageBubbleUIKit from './MessageBubble/MessageBubbleUIKit';
import ChatLeftSidebarUI from './ChatLeftSidebarUI';
import ChatRightSidebar from './ChatRightSidebar';
import NewTicketModal from 'components/Ticket/NewTicketModal';
import { Button, UncontrolledAlert } from 'reactstrap';
import SynchronizeChat from './Teacher/SynchronizeChat';
import SimulationRightSidebar from './LiveChat/SimulationRightSidebar';
import TrainingRigthSidebar from './LiveChat/TrainingRigthSidebar';

const ConversationUIKitTrain = ({
  conversation,
  onGetMessages,
  onAddMessage,
  messages,
  selectedMessage,
  currentUser,
  hideInput,
  history,
  disableSidebar,
  fetchingDetail,
  convError,
  children,
  isQuestion,
  isRequested }) => {

  const [messageGroups, setMessageGroups] = useState([])
  const [conversationId, setConversationId] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastMessageId, setLastMessageId] = useState(0)

  useEffect(() => {
    const { groups, nextGroups } = messages

    setConversationId(conversation.id)
    setMessageGroups(groups)
  }, [messages])

  useEffect(() => {
    if (!conversation.id) {
      return
    }

    if (fetchingDetail) {
      return
    }

    const { groups, nextGroups } = messages
    if (groups == messageGroups) {
      return
    }

    setMessageGroups(groups)
    // onGetMessages(conversation.id);
    setConversationId(conversation.id)
  }, [conversation])

  useEffect(() => {
    if (loadingMore !== isRequested) {
      setLoadingMore(isRequested)
    }
  }, [isRequested])


  const handleOnSubmit = (value) => {
    const message = {
      conversation_id: conversation.id,
      is_question: isQuestion,
      message: value,
      createdAt: new Date(),
    };

    onAddMessage(message);
  }

  const handleOnLoadMessage = () => {
    const { groups, nextGroups } = messages
    if (!groups || groups.length < 1) {
      return
    }

    // let group = groups.pop()
    // let userMessages = group.user_messages.pop()

    // groups.map(group => {
    //   return group.user_messages.map((user_message, ind) =>{
    //     let firstMsg = user_message['messages'][0]
    //     let isQuestion = firstMsg['is_question']
    //   })
    // })
    // let group = groups[lastMessageId]
    // setLastMessageId(lastMessageId+1)
    // setMessageGroups(messageGroups.push(group))
  }

  const msgListRef = useRef()
  const scrollToBottom = () => msgListRef.current.scrollToBottom('smooth');

  return (
    <>
      {(convError && convError.detail) &&
        <UncontrolledAlert color='danger'>
          {convError.detail}
        </UncontrolledAlert>
      }
      <MainContainer responsive>
        {!disableSidebar &&
          <ChatLeftSidebarUI
            history={history}
            currentUser={currentUser}
          />
        }
        <ChatContainer>
          <ConversationHeader>
            <Avatar src={zoeIco} name={currentUser.fullname} />
            <ConversationHeader.Content userName={conversation.title} info="Active" />
            <ConversationHeader.Actions>
              {!disableSidebar &&
                <>
                  <SynchronizeChat conversation={conversation} />

                  <NewTicketModal
                    openNewTab={true}
                    conversationId={conversation.id} />
                </>
              }
            </ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList
            ref={msgListRef}
            loading={isRequested}
          >
            {messageGroups && messageGroups.map(group => {
              return group.user_messages.map((user_message, ind) => (
                messageBubbleUIKit(user_message, scrollToBottom, selectedMessage, isQuestion, ind)
              ))
            })}

          </MessageList>

          {!hideInput &&
            <MessageInput
              autoFocus
              disabled={!isQuestion && conversation.is_teacher_take_over == false}
              attachButton={false}
              onSend={handleOnSubmit}
              placeholder="Type message here" />
          }

        </ChatContainer>

        <TrainingRigthSidebar />

      </MainContainer>
    </>
  )
}

const mapStateToProps = ({ chat, Conversation }) => ({
  selectedMessage: chat.selectedMessage,
  messages: chat.messages,
  isRequested: chat.isRequested,
  conversation: Conversation.conversationDetail,
  fetchingConversation: Conversation.fetchingConversation,
  fetchingDetail: Conversation.fetchingDetail,
  convError: Conversation.error,
});

const mapDispatchToProps = dispatch => ({
  onGetChats: () => dispatch(getChats()),
  // onGetGroups: () => dispatch(getGroups()),
  // onGetContacts: () => dispatch(getConversation()),
  onGetMessages: (roomId, unix = 0) => dispatch(getMessages(roomId, unix)),
  onAddMessage: (payload) => dispatch(addMessage(payload)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(ConversationUIKitTrain))
