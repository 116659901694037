import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError } from "./actions"

//Include Both Helper File with needed methods
import * as api from './api';
import { AUTH_USER_KEY, JWT_TOKEN_KEY } from "network/http/jwt-token-access"
import { sleep } from "../register/saga";

function* loginUser({ payload: { user, history } }) {
  try {
    let response = yield call(api.login_user, user)

    localStorage.setItem(JWT_TOKEN_KEY, response.access_token)
    localStorage.setItem(AUTH_USER_KEY, JSON.stringify(response.user))

    sleep(700)
    yield put(loginSuccess(response.user))
    window.location.replace("/dashboard")
  } catch (error) {
    if (error.response) {
      const { data } = error.response
      yield put(apiError(data.detail))
    }
    console.error(error)
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem(JWT_TOKEN_KEY)
    localStorage.removeItem(AUTH_USER_KEY)
    window.location.replace("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
