import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Col, Container, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from "reactstrap";
import { getStoreDetail } from "store/store/actions";
import StoreDetail from "./StoreDetail";
import StorePolicy from "./StorePolicy";
import StoreShipment from "./StoreShipment";
import StoreInfoInput from "./StoreInfoInput";
import StoreProducts from "./StoreProducts";

class StoreOverview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 1,
    }
  }

  componentDidMount() {
    const {
      match: { params },
      getStoreDetail,
    } = this.props;

    getStoreDetail(params.id);
  }

  handleOnUpdateActiveTab = (tabId) => {
    this.setState({ activeTab: tabId })
  }

  render() {
    const { store, isRequested } = this.props;
    const { activeTab } = this.state

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <>
              <Row>
                <Col xs="2">
                  <Nav justified pills>
                    <NavItem>
                      <NavLink active={activeTab == 1} onClick={() => this.handleOnUpdateActiveTab(1)}>
                        Overview
                      </NavLink>
                      <NavLink active={activeTab == 2} onClick={() => this.handleOnUpdateActiveTab(2)}>
                        Store Policy
                      </NavLink>
                      <NavLink active={activeTab == 3} onClick={() => this.handleOnUpdateActiveTab(3)}>
                        Store Shipment
                      </NavLink>
                      <NavLink active={activeTab == 4} onClick={() => this.handleOnUpdateActiveTab(4)}>
                        Product
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col xs="10">
                  {isRequested &&
                    <div style={{ textAlign: 'center' }}>
                      <Spinner color="primary" type="grow" />
                    </div>
                  }
                  {store &&
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1}>
                        <StoreDetail store={store} />
                        <StoreInfoInput store={store} />
                      </TabPane>
                      <TabPane tabId={2}>
                        <StorePolicy title="Store Policy" store={store} />
                      </TabPane>
                      <TabPane tabId={3}>
                        <Card>
                          <StoreShipment store={store} />
                        </Card>
                      </TabPane>
                      <TabContent tabId={4}>
                        {activeTab == 4 &&
                          <StoreProducts store={store} />
                        }
                      </TabContent>
                    </TabContent>
                  }
                </Col>
              </Row>
            </>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ Store }) => ({
  ...Store.detail,
});


export default connect(
  mapStateToProps,
  {
    getStoreDetail,
  }
)(withRouter(StoreOverview));
