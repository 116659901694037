import React, { useState } from 'react'
import { InfoButton } from "@chatscope/chat-ui-kit-react"
import { Tooltip } from 'reactstrap'
import { connect } from 'react-redux';
import { selectMessage } from 'store/chat/actions'
import moment from 'moment';
import AddToKnowledgeBase from './AddToKnowledgeBase';
import SanitizeHTML from 'components/SanitizeHTML';
import MessageDeleteButton from './MessageDeleteButton';
import MessageGenerateAnswer from './MessageGenerateAnswer';

const today = new Date()

const parseDate = (date) => {
  let dt = moment(date)

  if (dt.isSameOrAfter(today, 'day')) {
    return dt.fromNow()
  }

  return dt.format("YYYY-MM-DD hh:mm")
}

const MessageBubbleFooter = ({ message, questionMode, selectMessage }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const divId = `Tooltip${message.id}`
  return (
    <div className='mb-2 mt-1' style={{ 'display': 'block', 'width': '100%', 'position': 'relative' }}>

      <span className='font-size-10'>
        {parseDate(message.created_at)}
      </span>

      {(!questionMode && message.is_question) &&
        <>
          <InfoButton
            id={divId}
            onClick={() => selectMessage(message)}
          />
          <Tooltip
            autohide={true}
            isOpen={tooltipOpen}
            target={divId}
            toggle={toggle}
          >
            Open Message Context
          </Tooltip>
        </>
      }

      {(!message.is_question) &&
        <AddToKnowledgeBase message={message} />
      }

      {!message.is_question &&
        <MessageDeleteButton message={message} />
      }

      {(message.feature && message.feature.correct_answer) &&
        <div>
          <small>corrected answer:</small>
          <SanitizeHTML html={message.feature.correct_answer} />
          <small>correct intention: {message.feature.correct_intent}</small>
        </div>
      }

      {message.is_question &&
        <MessageGenerateAnswer message={message} />
      }

    </div>
  )
}

const mapStateToProps = ({})

export default connect(
  null,
  {
    selectMessage,
  }
)(MessageBubbleFooter)
