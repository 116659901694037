import { takeEvery, put, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

//Include Both Helper File with needed methods
import * as api from './api';

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(api.register_user, user)
    yield put(registerUserSuccessful(response));

    api.setAccessToken(response)

    // sleep to 500ms
    sleep(500);

    history.push('/dashboard')
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

function* accountSaga() {
  yield takeEvery(REGISTER_USER, registerUser);
}

export default accountSaga;
