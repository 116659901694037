import React, { useState, useEffect } from "react";
import { get } from "network/http/api";
import { debounce } from "lodash";
import SectionHeader from "components/Page/SectionHeader";
import SearchComponentfrom from "components/Page/SearchComponent";
import TableComponent from "components/Page/TableComponent";
import TableBodyAccount from "./ComponentsPage/TableBodyAccount";
import PagginationButton from "components/PagginationButton";

const ListAccount = () => {
  const [dataUser, setDataUser] = useState([]);
  const [paramsPage, setParamsPage] = useState({
    perPage: 10,
    page: 1,
    query: "",
  });
  const [totalPage, setTotalPage] = useState(0);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchUser = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      let params = `page=${paramsPage.page}&&limit=${paramsPage.perPage}`;
      let url = `/admin/account/user`;
      if (query !== "") {
        url = `/admin/account/search/user?keyword=${query}`;
      } else {
        url = `${url}?${params}`;
      }

      const res = await get(url);
      setLoading(false);
      setDataUser(res.users);
      const totalPage = res.pagination.total_item
        ? Math.ceil(res.pagination.total_item / paramsPage.perPage)
        : 0;
      setTotalPage(totalPage);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    if (loading) return;

    const fetch = debounce(fetchUser, 500);
    fetch();

    return () => fetch.cancel();
  }, [query, paramsPage]);

  return (
    <div className="page-container">
      <div className="page-content-costume">
        <SectionHeader
          title="Account Management"
          subtitle="Daftar dari pengguna akun dari layanan RISA AI, mulai dari yang gratis, hingga berbayar."
        />
        <div className="section-content page-finance">
          <div className="section-filter">
            <SearchComponentfrom type="full" setQuery={setQuery} />
          </div>
          <div className="section-table">
            <TableComponent
              dataHead={[
                "ID Akun",
                "Nama Akun",
                "Email",
                "No Telephone",
                "Status Akun",
                "Registered",
                "Aksi",
              ]}
              componentBody={<TableBodyAccount dataBody={dataUser} />}
              loading={loading}
            />
            <PagginationButton
              paramsPage={paramsPage}
              setParamsPage={setParamsPage}
              amountPage={totalPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListAccount;
