import React, { Fragment, useState } from "react"
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, UncontrolledAlert } from "reactstrap";
import { loadDatasetRequest, generateAnswer } from "store/dataset-train/actions";
import SubmitDataset from "./SubmitDataset";

const LoadDatasetButton = ({
  onGenerateAnswer,
  onLoadDataset,
  messages,
  dataset,
  isLoading,
  error }) => {

  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleOnLoadDataset = () => {
    if (isLoading) {
      return
    }
    onLoadDataset()
  }

  const isNextConversationExist = () => {
    const { conversations } = dataset
    if (!conversations) {
      return true
    }

    if (!conversations || conversations.length < 1) {
      return false
    }

    return true
  }

  const handleOnGenerateAnswer = () => {
    if (!isNextConversationExist()) {
      return
    }

    onGenerateAnswer(dataset.id)
  }

  const handleOnSubmitConversation = () => {

  }

  return (
    <Fragment>
      <div className="text-center">
        {isLoading &&
          <Spinner color="primary" type="grow" />
        }
        {(error && error.detail) &&
          <UncontrolledAlert color='danger'>
            {error.detail}
          </UncontrolledAlert>
        }
      </div>
      {(!dataset || !dataset.id) &&
        <Button
          onClick={handleOnLoadDataset}
          disabled={isLoading}
          className="p-3 font-size-14"
          color="primary"
          style={{
            width: '100%',
            fontWeight: 600,
          }}>
          Load Dataset
        </Button>
      }

      {(dataset && isNextConversationExist()) &&
        <Button
          disabled={isLoading}
          onClick={handleOnGenerateAnswer}
          className="p-3 font-size-14 btn-full-size"
          color="secondary">
          {dataset.current_progress}
        </Button>
      }

      {(dataset && !isNextConversationExist()) &&
        <SubmitDataset dataset={dataset} />
      }

    </Fragment>
  )
}

const mapStateToProps = ({ DatasetTrain, chat }) => ({
  'isLoading': DatasetTrain.isRequested,
  'error': DatasetTrain.error,
  'dataset': DatasetTrain.dataset,
  'messages': chat.messages,
})

export default connect(
  mapStateToProps,
  {
    'onLoadDataset': loadDatasetRequest,
    'onGenerateAnswer': generateAnswer,
  }
)(LoadDatasetButton);
