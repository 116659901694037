import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Spinner } from "reactstrap"
import { map } from "lodash"

//Import Images
import images from "assets/images"
import SanitizeHTML from "components/SanitizeHTML"
import moment from "moment"

// import { statusClasses } from "common/data/tasks"

class CardTaskBox extends Component {
  onClickDetail = () => {
    const { history, data } = this.props;
    history.push(`/ticket-detail/${data.id}`)
  }

  render() {
    const { data, updateState } = this.props
    const { ticket } = data
    return (
      <React.Fragment>
        <Card className="task-box" onClick={this.onClickDetail}>
          <CardBody className="borad-width">
            {(updateState && updateState.id == data.id && updateState.isRequested) &&
              <div style={{
                position: 'absolute',
                width: '100%', height:'100%',
                top: 0, left: 0,
                background: '#00000057'}}>
                <Spinner type="grow"/>
              </div>
            }
            <div className="float-end ms-2">
            </div>
            <div>
              <h5 className="font-size-15">
                <Link to="#" className="text-dark">
                  {ticket.title}
                </Link>
              </h5>
              <p className="text-muted mb-4">{moment(ticket.created_at).fromNow()}</p>
              <div>
                <SanitizeHTML html={ticket.note} />
              </div>
            </div>

            <div className="avatar-group float-start">
              {map(
                data.members,
                (member, index) =>
                  index < 2 && (
                    <div className="avatar-group-item"  key={index}>
                    <Link
                      to="#"
                      className="d-inline-block"

                    >
                      {member.userImg ? (
                        <img
                          src={images[member.userImg]}
                          className="rounded-circle avatar-xs"
                          alt=""
                        />
                      ) : (
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                            {member.username.charAt(0)}
                          </span>
                        </div>
                      )}
                    </Link>
                    </div>
                  )
              )}

                <div className="avatar-group-item">
                <Link to="#" className="d-inline-block">
                  <div className="avatar-xs">
                    <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                      {ticket.creator ? ticket.creator.fullname.charAt(0) : ''}
                    </span>
                  </div>
                </Link>
                <span className="p-3">
                  {ticket.creator ? ticket.creator.fullname : ''}
                </span>
                </div>
            </div>

            <div className="text-end">
              <h5 className="font-size-15 mb-1">{data.comment_count}</h5>
              <p className="mb-0 text-muted">Comment</p>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

CardTaskBox.propTypes = {
  data: PropTypes.object
}

export default withRouter(CardTaskBox)
