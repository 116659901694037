import { memoize, uniq } from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


const variantIdentifiers = memoize(variants => {
  let identifiers = {}
  variants.map(variant => {
    variant.options.map(opt => {
      if (opt.name in identifiers) {
        identifiers[opt.name].push(opt.option_value)
        identifiers[opt.name] = uniq(identifiers[opt.name])
      } else {
        identifiers[opt.name] = [opt.option_value]
      }
    })
  })


  return identifiers
})

const ProductDetailVariants = ({ variants, onClick }) => {
  const [selectedOptions, setSelectedOptions] = useState({})

  if (!variants || variants.length < 1) {
    return null
  }

  let identifiers = variantIdentifiers(variants)

  const handleOnClickOption = (identifier, opt) => {
    if (selectedOptions[identifier] == opt) {
      // unselect option
      selectedOptions[identifier] = ''
    } else {
      selectedOptions[identifier] = opt
    }

    setSelectedOptions(selectedOptions)
    let opts = []
    for (const [key, value] of Object.entries(selectedOptions)) {
      opts.push(`${key}_${value}`)
    }
    opts.sort()
    onClick(opts.join('_'))
  }

  const isIdentifierActive = (identifier, option) => {
    let opts = selectedOptions[identifier]
    if (!opts) {
      return false
    }

    return opts == option
  }

  return (
    <Fragment>
      {Object.keys(identifiers).map((identifier, index) => (
        <div key={index} className="product-color">
          <h5 className="font-size-14">{identifier.toLocaleUpperCase()} :</h5>
          {identifiers[identifier].map((option, key) => (
            <Link onClick={() => handleOnClickOption(identifier, identifiers[identifier][key])} to="#" className="active" key={key}>
              <div
                style={{ background: isIdentifierActive(identifier, option) ? '#82f982' : 'transparent' }}
                className="p-2 product-color-item border rounded">
                {option}
              </div>
            </Link>
          ))}
        </div>
      ))}
    </Fragment>
  )
}

export default ProductDetailVariants
