import { del, get, post } from "network/http/api"
import React, { useEffect, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, UncontrolledAlert } from "reactstrap"

const ProductSyncState = ({ product }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [syncState, setSyncState] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    get('admin/product/state-sync/' + product.id)
      .then(resp => {
        setIsLoading(false)
        setSyncState(resp)
        setErrorMessage(null)
      })
      .catch(err => {
        setIsLoading(false)
        setErrorMessage(err.detail)
      })
  }, [product])

  const handleOnVectorizationProduct = () => {
    if (syncState.is_embedded) {
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    post('admin/product/add-vectorization-product', { 'product_id': product.id })
      .then(resp => {
        setIsLoading(false)
        setErrorMessage(null)
        setIsOpenModal(false)
      }).catch(err => {
        setErrorMessage(err.detail)
        setIsLoading(false)
      })
  }

  const handleOnDeleteEmbed = () => {
    if (!syncState.is_embedded) {
      return
    }

    setIsLoading(true)
    del('admin/product/product-embed/' + product.id)
      .then(resp => {
        setIsLoading(false)
        setErrorMessage(null)
        setIsOpenModal(false)
      }).catch(err => {
        console.error(err)
        setErrorMessage(err.detail)
        setIsLoading(false)
      })
  }

  return (<>
    <Button style={{ float: 'right' }} onClick={() => setIsOpenModal(true)}>
      <i className="fa fa-fw fa-info" />
    </Button>
    <Modal toggle={() => setIsOpenModal(!isOpenModal)} isOpen={isOpenModal}>
      <ModalHeader>
        Product State
      </ModalHeader>
      <ModalBody>
        {isLoading &&
          <div className="text-center">
            <Spinner type="grow" color="primary" />
          </div>
        }
        {errorMessage &&
          <UncontrolledAlert color="danger">
            {errorMessage}
          </UncontrolledAlert>
        }
        <br />
        <p>Is Embeded: {syncState.is_embedded ? 'Yes' : 'No'}</p>

        {!syncState.is_embedded &&
          <Button disabled={isLoading} onClick={handleOnVectorizationProduct}>Vectorizing Product</Button>
        }
      </ModalBody>
      <ModalFooter>
        <Button color="warning"
          disabled={isLoading}
          onClick={handleOnDeleteEmbed}>Delete Vectorization</Button>
        <Button disabled={isLoading}
          onClick={() => setIsOpenModal(false)}>Close</Button>
      </ModalFooter>
    </Modal>
  </>)
}

export default ProductSyncState
