import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import EmptyTicket from "components/Ticket/EmptyTicket";

class ConversationStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: "series1",
          data: [31, 40, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76],
        },
      ],
      options: {
        chart: {
          toolbar: "false",
          dropShadow: {
            enabled: !0,
            color: "#000",
            top: 18,
            left: 7,
            blur: 8,
            opacity: 0.2,
          },
        },
        dataLabels: {
          enabled: !1,
        },
        colors: ["#556ee6", "#fe123f", "#444444"],
        stroke: {
          curve: "smooth",
          width: 3,
        },
      },
      earningChartData: [1, 2, 3, 4, 5, 6, 12, 123, 123, 5, 23434, 123123,],
      seletedMonth: "jan"
    };
  }

  render() {
    const { seletedMonth } = this.state;
    let { options } = this.state;
    const { conversationStats } = this.props;

    if (!conversationStats || !conversationStats.date) {
      return null
    }

    options['xaxis'] = {
      'categories': conversationStats.date || ['']
    }
    const series = [
      {
        name: "Total Message",
        data: conversationStats.total_message || [0],
      },
      {
        name: "Total AI Reply",
        data: conversationStats.total_message_bot || [0],
      },
      {
        name: "Total Conversation",
        data: conversationStats.total_conversation || [0],
      }
    ];
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="clearfix">
              <h4 className="card-title mb-4">Conversation History Stats</h4>
            </div>
            <div id="line-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                series={series}
                options={this.state.options}
                type="line"
                height={320}
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}


export default ConversationStats;
