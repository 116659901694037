import {
  GET_PRODUCTS,
  GET_STORE_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCTS_CATEGORY,
  GET_PRODUCTS_CATEGORY_SUCCESS,
  GET_ID_CATEGORY_PRODUCTS,
  SEARCH_PRODUCT,
} from "./actionTypes"

export const searchProduct = (keyword) => ({
  type: SEARCH_PRODUCT,
  payload: {
    keyword,
  }
})

export const fetchProductCategory = (categoryId, page=1) => ({
  type: GET_ID_CATEGORY_PRODUCTS,
  payload: {
    categoryId,
    page,
  }
})

export const getProducts = (companyId, page = 1) => ({
  type: GET_PRODUCTS,
  payload: {
    companyId, page
  }
})

export const getStoreProducts = (storeId, page) => ({
  type: GET_STORE_PRODUCTS,
  payload: {
    storeId, page
  }
})

export const getProductsSuccess = (products, title = 'All Products') => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: {
    ...products,
    title: title,
  },
})

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
})

export const getProductDetail = productId => ({
  type: GET_PRODUCT_DETAIL,
  productId,
})

export const getProductDetailSuccess = products => ({
  type: GET_PRODUCT_DETAIL_SUCCESS,
  payload: products,
})

export const getProductDetailFail = error => ({
  type: GET_PRODUCT_DETAIL_FAIL,
  payload: error,
})


export const getProductCategory = (companyId) => ({
  type: GET_PRODUCTS_CATEGORY,
  payload: companyId,
})

export const getProductCategorySuccess = (payload) => ({
  type: GET_PRODUCTS_CATEGORY_SUCCESS,
  payload: payload,
})

export const getProductCategoryFail = (error) => ({
  type: GET_PRODUCTS_CATEGORY_SUCCESS,
  payload: error,
})
