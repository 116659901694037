import { ExpansionPanel } from '@chatscope/chat-ui-kit-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';

const InfoConversationContext = ({ message, converstationDetail }) => {
  if (converstationDetail.current_context == '' || converstationDetail.current_context == 'base') {
    return null
  }

  if (!converstationDetail.object_context) {
    return null
  }

  const { object_context } = converstationDetail

  return (
    <ExpansionPanel title="Context" open>
      <ListGroup>
        <ListGroupItem>
          <strong>Product ID : </strong>
          <Link to={`/product/detail/${object_context.product_id}`}>
            {object_context.product_id}
          </Link>
        </ListGroupItem>
        <ListGroupItem>
          <strong>URL : </strong>
          <a href={object_context.product_url}>
            {object_context.product_url}
          </a>
        </ListGroupItem>
      </ListGroup>

    </ExpansionPanel>
  )
}

export default InfoConversationContext
