export const LOAD_DATASET = 'LOAD_DATASET'
export const USER_LATEST_LOAD_DATASET = 'USER_LATEST_LOAD_DATASET'
export const LOAD_DATASET_SUCCESS = 'LOAD_DATASET_SUCCESS'
export const LOAD_DATASET_FAILED = 'LOAD_DATASET_FAILED'
export const SUBMIT_DATASET_SUCCESS = 'SUBMIT_DATASET_SUCCESS'

export const GENERATE_ANSWER_REQUEST = 'GENERATE_ANSWER_REQUEST'
export const GENERATE_ANSWER_SUCCESS = 'GENERATE_ANSWER_SUCCESS'
export const GENERATE_ANSWER_FAILED = 'GENERATE_ANSWER_FAILED'

export const GET_LEADER_BOARD_REQUEST = 'GET_LEADER_BOARD_REQUEST'
export const GET_LEADER_BOARD_SUCCESS = 'GET_LEADER_BOARD_SUCCESS'
export const GET_LEADER_BOARD_FAILED = 'GET_LEADER_BOARD_FAILED'
