/* CONVERSATION */
export const GET_CONVERSATION = "GET_CONVERSATION"
export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS"
export const GET_CONVERSATION_FAIL = "GET_CONVERSATION_FAIL"
export const GET_CONVERSATION_MORE_SUCCESS = "GET_CONVERSATION_MORE_SUCCESS"

export const GET_CONVERSATION_DETAIL = 'GET_CONVERSATION_DETAIL'
export const GET_CONVERSATION_DETAIL_FAIL = 'GET_CONVERSATION_DETAIL_FAIL'
export const GET_CONVERSATION_DETAIL_SUCCESS = 'GET_CONVERSATION_DETAIL_SUCCESS'

export const NEW_CONVERSATION = 'NEW_CONVERSATION'
export const NEW_CONVERSATION_SUCCESS = 'NEW_CONVERSATION_SUCCESS'
export const NEW_CONVERSATION_FAILED = 'NEW_CONVERSATION_FAILED'

export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION'
export const UPDATE_CONVERSATION_SUCCESS = 'UPDATE_CONVERSATION_SUCCESS'
export const UPDATE_CONVERSATION_FAIL = 'UPDATE_CONVERSATION_FAIL'

export const JOIN_CONVERSATION = 'JOIN_CONVERSATION'
export const JOIN_CONVERSATION_SUCCESS = 'JOIN_CONVERSATION_SUCCESS'
export const JOIN_CONVERSATION_FAILED = 'JOIN_CONVERSATION_FAILED'


// conversation simulation
export const GET_CONVERSATION_SIMULATION = "GET_CONVERSATION_SIMULATION"
export const GET_CONVERSATION_SIMULATION_STORE = 'GET_CONVERSATION_SIMULATION_STORE'
