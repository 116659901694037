import React, { Component, Fragment } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Input,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import StoreIntegrationStatus from "./StoreIntegrationStatus";

class ShopDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }
  render() {
    const { store } = this.props;

    return (
      <Fragment>
        <Card>
          <CardBody>
            <div className="d-flex">
              <div className="avatar-xs me-4">
                <span className="avatar-title">
                  {store.store_name.charAt(0)}
                </span>
              </div>

              <div className="flex-grow-1 overflow-hidden">
                <h5 className="text-truncate font-size-15">
                  {store.store_name}
                </h5>
                <p className="text-muted">{store.tag_line}</p>
              </div>
            </div>

            <p className="font-size-15 mt-4">
              <strong>Description</strong>: {store.description}
            </p>

            <h5 className="font-size-15 mt-4">Store Details :</h5>
            <div className="font-size-14">
              <ListGroup>
                <ListGroupItem>
                  <StoreIntegrationStatus store={store} />

                  {store.authorize_status !== 'completed' &&
                    <Alert className="mt-3">
                      <p>
                        Pengajuan Integrasi menunggu approval dari Anda dan tim Tokopedia.
                      </p>
                      <p>
                        Anda perlu menyetujui sendiri integrasi melalui Email yang dikirimkan pihak Tokopedia ke email Tokopedia merchant anda.
                      </p>
                    </Alert>
                  }
                </ListGroupItem>
                <ListGroupItem>
                  <strong>Platform</strong>:<br /><span></span>{store.platform ? store.platform.platform_name : ''}
                </ListGroupItem>
                <ListGroupItem>
                  <strong>Domain</strong>:<br />{store.domain}
                </ListGroupItem>
                <ListGroupItem>
                  <strong>Store URL</strong>:<br />
                  <a href={store.store_url}> {store.store_url} </a>
                </ListGroupItem>
              </ListGroup>
            </div>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default ShopDetail;
