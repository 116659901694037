import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Container } from "reactstrap";

//Import Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Redux Action
import { getUserLatestDataset } from "store/dataset-train/actions"

// init websocket
import { initialize as initializeWebSocket, close as closeWebSocket } from 'store/websocket/websocket_action'

// Token Access
import { AUTH_USER_KEY } from "network/http/jwt-token-access";
import WebSocketStatus from "components/WebSocket/WebSocketStatus";
import SimulationRightSidebar from "components/Chat/LiveChat/SimulationRightSidebar";
import LoadDatasetButton from "components/Dataset/LoadDatasetButton";
import ConversationUIKitTrain from "components/Chat/ConversationUIKitTrain";

class DatasetTrain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      webSocketInit: false,
      conversationId: null,
    }
  }

  componentDidMount(){
    const {webSocketConnected} = this.props;
    const user = JSON.parse(localStorage.getItem(AUTH_USER_KEY))
    this.setState({
      user,
    })

    this.props.onGetUserLatestDataset()

    if(!webSocketConnected){
      // wait until layer rendered
      setTimeout(() => {
        // connect to websocket
        initializeWebSocket()
        this.setState({
          webSocketInit: true
        })
      }, 500);
    }
  }

  componentDidUpdate(prevProps){
  }

  componentWillUnmount(){
    closeWebSocket()
  }

  render() {
    const {conversationDetail} = this.props;
    const { user } = this.state;
    //meta title
    document.title="Dataset Train | Risa";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Risa" breadcrumbItem="Dataset Train" />

            <WebSocketStatus />

            <div className="w-100 user-chat"
              style={{ height: '80vh', position: "relative" }}>
                <ConversationUIKitTrain
                  hideInput={true}
                  isQuestion={false}
                  disableSidebar={true}
                  currentUser={user}>
                    <SimulationRightSidebar onChangeStore={this.handleOnChangeStore} />
                </ConversationUIKitTrain>
                <div>
                  <LoadDatasetButton />
                </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = ({ Conversation, WebSocket, DatasetTrain }) => ({
  conversationDetail: Conversation.conversationDetail,
  fetchingDetail: Conversation.fetchingDetail,
  webSocketConnected: WebSocket.status === 'connected',
  dataset: DatasetTrain.dataset,
  isLoading: DatasetTrain.isRequested
});

export default connect(mapStateToProps, {
  onGetUserLatestDataset: getUserLatestDataset,
})(DatasetTrain);
