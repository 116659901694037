import React from 'react'
import * as DOMPurify from 'dompurify';

const defaultOptions = {
  ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'ol', 'ul', 'li'],
  ALLOWED_ATTR: ['href']
};

const sanitize = (dirty, options) => ({
  __html: DOMPurify.sanitize(
    dirty,
    { ...defaultOptions, ...options }
  )
});

// sanitize html source: https://stackoverflow.com/questions/38663751/how-to-safely-render-html-in-react/38663813#38663813
const SanitizeHTML = ({ html, options }) => (
  <div dangerouslySetInnerHTML={sanitize(html, options)} />
);

export default SanitizeHTML
