import * as actionTypes from "./actionTypes"

export const loadDatasetRequest = () => ({
  type: actionTypes.LOAD_DATASET,
})

export const getUserLatestDataset = () => ({
  type: actionTypes.USER_LATEST_LOAD_DATASET,
})

export const generateAnswer = (id) => ({
  type: actionTypes.GENERATE_ANSWER_REQUEST,
  payload: {
    id
  }
})

export const loadDatasetSuccess = payload => ({
  type: actionTypes.LOAD_DATASET_SUCCESS,
  payload,
})

export const loadDatasetFailed = payload => ({
  type: actionTypes.LOAD_DATASET_FAILED,
  payload,
})

export const submitDatasetSuccess = () => ({
  type: actionTypes.SUBMIT_DATASET_SUCCESS,
})

export const getLeaderBoardRequest = (page = 1, limit = 10) => ({
  type: actionTypes.GET_LEADER_BOARD_REQUEST,
  payload: {
    page, limit
  }
})

export const getLeaderBoardSuccess = (payload) => ({
  type: actionTypes.GET_LEADER_BOARD_SUCCESS,
  payload: payload
})

export const getLeaderBoardFailed = (error) => ({
  type: actionTypes.GET_LEADER_BOARD_FAILED,
  payload: error
})
