import React, { useState, useEffect } from "react";
import { get } from "network/http/api";
import { debounce } from "lodash";
import SectionHeader from "components/Page/SectionHeader";
import SearchComponentfrom from "components/Page/SearchComponent";
import TableComponent from "components/Page/TableComponent";
import PagginationButton from "components/PagginationButton";
import FilterComponent from "components/Page/FilterComponent";

import TableBodyRefund from "../ComponentsPage/TableBodyRefund";
import { LIST_FILTER_STATUS } from "../DefaultData";

const Refund = () => {
  const [dataInvoiceRefund, setDataInvoiceRefund] = useState([]);
  const [paramsPage, setParamsPage] = useState({
    perPage: 10,
    page: 1,
    query: "",
  });
  const [totalPage, setTotalPage] = useState(0);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchUser = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      let params = `page=${paramsPage.page}&&per_page=${paramsPage.perPage}`;
      let url = `/admin/refund/list`;
      //   if (query !== "") {
      //     url = `${url}?keyword=${query}`;
      //   } else {
      //     url = `${url}?${params}`;
      //   }

      const res = await get(`${url}?${params}`);
      setLoading(false);
      console.log(res, "res");
      //   setDataInvoice(res.users);
      //   const totalPage = res.pagination.total_item
      //     ? Math.ceil(res.pagination.total_item / paramsPage.perPage)
      //     : 0;
      //   setTotalPage(totalPage);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    if (loading) return;

    const fetch = debounce(fetchUser, 500);
    fetch();

    return () => fetch.cancel();
  }, [query, paramsPage]);

  return (
    <div className="page-container">
      <div className="page-content-costume">
        <SectionHeader
          title="Refund Dashboard"
          subtitle="Daftar dari Refund yang dimiliki oleh pengguna RISA AI, dibagi berdasarkan status sukses, menunggu, dan juga gagal."
        />
        <div className="section-content page-finance">
          <div className="section-filter">
            <SearchComponentfrom type="small" />
            <FilterComponent option={LIST_FILTER_STATUS} />
          </div>
          <div className="section-table">
            <TableComponent
              dataHead={[
                "Nomor Invoice",
                "Nama User",
                "Nominal Transaksi",
                "Status",
                "Req Refund Date",
                "Aksi",
              ]}
              componentBody={<TableBodyRefund dataBody={[]} />}
            />
            <PagginationButton
              paramsPage={paramsPage}
              setParamsPage={setParamsPage}
              amountPage={totalPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refund;
