import { takeEvery, put, call, all, fork } from "redux-saga/effects";
import * as action from './actions'
import * as types from './actionTypes'
import * as api from './api'

function* onFetchUserRequest({ payload: { page, limit } }) {
  try {
    const response = yield call(api.getUserList, page, limit)
    yield put(action.fetchUserSuccess(response))
  } catch (error) {
    yield put(action.fetchUserFailed(error))
  }
}


function* onGetUserRequest({ payload }) {
  try {
    const response = yield call(api.getUserDetail, payload)
    yield put(action.getUserSuccess(response))
  } catch (error) {
    yield put(action.getUserFailed(error))
  }
}

function* onEditUserRequest({ payload: { id, data } }) {
  try {
    const response = yield call(api.updateUserDetail, id, data)
    yield put(action.editUserSuccess(response))
  } catch (error) {
    yield put(action.editUserFailed(error))
  }
}

export function* watchUser() {
  yield takeEvery(types.FETCH_USER_REQUEST, onFetchUserRequest)
  yield takeEvery(types.GET_USER_REQUEST, onGetUserRequest)
  yield takeEvery(types.EDIT_USER, onEditUserRequest)
}

function* userSaga() {
  yield all([fork(watchUser)]);
}

export default userSaga;
