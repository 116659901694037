const { get, post, put, del } = require("network/http/api")

export const fetchProductCompany = (companyId, page = 1) => {
  return get(`product/company/${companyId}?page=${page}`)
}

export const fetchProductStore = (storeId, page = 1) => {
  return get(`admin/product/store/${storeId}?page=${page}`)
}

export const getProductDetail = (productId) => {
  return get(`admin/product/detail/${productId}`)
}

export const fetchProductCategory = (companyId) => {
  return get(`product/category/${companyId}`)
}

export const fetchCategoryIdProduct = (categoryId, page) => {
  return get(`product/cat_id/${categoryId}/?page=${page}`)
}


export const searchProduct = (keyword) => {
  return get(`product/search?keyword=${keyword}`)
}
