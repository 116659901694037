import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Card, CardBody, Col } from 'reactstrap'

export const convertInt2Money = (price) => {
  let Currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'IDR',
  });
  return Currency.format(price)
}

const ProductCardItem = ({ product, disableLink, history }) => {
  return (<Col xl="4" sm="6">
    <Card
      onClick={() => {
        if (disableLink) {
          return
        }
        history.push(
          `/product/detail/${product.id}`
        )
      }}
    >
      <CardBody>
        <Link to="#">
          <div className="product-img position-relative">
            {product.isOffer ? (
              <div className="avatar-sm product-ribbon">
                <span className="avatar-title rounded-circle  bg-primary">
                  {`-${product.offer}%`}
                </span>
              </div>
            ) : null}

            {(product.pictures && product.pictures.length > 0) &&
              <img
                src={product.pictures[0]['thumbnail_url']}
                alt=""
                className="img-fluid mx-auto d-block"
              />
            }
          </div>
        </Link>

        <div className="mt-4 text-center">
          <h5 className="mb-3 text-truncate">
            <Link
              to={
                "/product/detail/" + product.id
              }
              className="text-dark"
            >
              {product.name}{" "}
            </Link>
          </h5>
          <div className="text-muted mb-3">
            {/* <p>{product.stock}</p> */}
          </div>
          <h5 className="my-0">
            {product.campaign_active &&
              <span className="text-muted me-2">
                <del>{convertInt2Money(product.original_price)}</del>
              </span>
            }
            {" "}
            <b>{convertInt2Money(product.price)}</b>
          </h5>
        </div>
      </CardBody>
    </Card>
  </Col>)
}

export default withRouter(connect(
  null,
  {}
)(ProductCardItem))
