import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Spinner } from 'reactstrap'
// actions
import { getPlatformRequest } from 'store/platform/actions'

const SelectPlatformInput = ({ value, onChange, platforms, isRequested, getPlatformRequest }) => {

  useEffect(() => {
    if (platforms && platforms.lengt > 0) {
      return
    }

    getPlatformRequest()
  }, [])

  return (
    <div>
      <Input value={value} disabled={isRequested}
        onChange={(e) => onChange(e.target.value)}
        type='select'>
        <option value={0}>
          All Platform
        </option>
        {platforms.map((pl, i) => (
          <option value={pl.id} key={i}>
            {pl.platform_name}
          </option>
        ))}
      </Input>

    </div>
  )
}

const mapStateToProps = ({ Platform }) => {
  return Platform
}

export default connect(mapStateToProps, { getPlatformRequest })(SelectPlatformInput)
