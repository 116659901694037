import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal } from "reactstrap";
import BackButton from "components/BackButton";
import Breadcrumb from "components/Breadcrumb";
import { get } from "network/http/api";
import ContentModal from "./ComponentsPage/ContentModal";
import TableComponent from "components/Page/TableComponent";
import TableBodyPlatformIntegration from "./ComponentsPage/TableBodyPlatformIntegration";
import { STATUS_STYLES, handleStatusName } from "./DefaultData";

const DetailAccount = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [typeModalContent, setTypeModalContent] = useState("password");
  const [data, setData] = useState(null);
  const [modal, setModal] = useState(false);
  const breadcrumbItems = [
    { label: "List Daftar User", path: "/account" },
    { label: "Detail User", path: `/account/details/${id}` },
  ];

  const toggle = paramsType => {
    setTypeModalContent(paramsType);
    setModal(!modal);
  };

  const fetchUserDetail = async paramsId => {
    setLoading(true);
    try {
      const res = await get(`/admin/account/user/${paramsId}`);
      setData(res);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetail(id);
  }, [id]);

  return (
    <div className="page-container">
      <div className="page-content-costume">
        <BackButton />
        <div style={{ margin: "28px 0px 32px" }}>
          <Breadcrumb items={breadcrumbItems} />
        </div>

        <div className="page-detail-finance">
          <div className="section-content-finance">
            <span className="title">Informarsi User</span>
            <div className="content-detail">
              <div className="content-detail-items">
                <span className="items-title">ID Akun</span>
                <span className="items">{data ? data?.user.id : "-"}</span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Email Akun</span>
                <span className="items">{data ? data?.user.email : "-"}</span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">No Telephone</span>
                <span className="items">
                  {data ? data?.user.phone_number : "-"}
                </span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Nama</span>
                <span className="items">
                  {data ? data?.user.fullname : "-"}
                </span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Status User</span>
                <div className="items">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        ...STATUS_STYLES[data?.user.status],
                        textTransform: "capitalize",
                        padding: "4px 10px",
                        borderRadius: "12px",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {data ? handleStatusName(data?.user.status) : "-"}
                    </span>
                    <Button
                      outline
                      color="primary"
                      onClick={() => toggle("status")}
                    >
                      Change
                    </Button>
                  </div>
                </div>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Password Akun</span>
                <span className="items">
                  <Button
                    outline
                    color="primary"
                    onClick={() => toggle("password")}
                  >
                    Change Password
                  </Button>
                </span>
              </div>
              <div className="content-detail-items last">
                <span className="items-title">Paket Teregistrasi</span>
                <span className="items">
                  {data ? data?.billing?.plan_name : "-"}
                </span>
              </div>
              {/* <div className="content-detail-items last">
                <span className="items-title">Paket Whastapp Terdaftar</span>
                <span className="items">
                  {data ? data?.billing.plan_name : "-"}
                </span>
              </div>
              <div className="content-detail-items last">
                <span className="items-title">Paket Add-on Terdaftar</span>
                <span className="items">
                  {data ? data?.billing.plan_name : "-"}
                </span>
              </div>
              <div className="content-detail-items last">
                <span className="items-title">Kuota Terpilih</span>
                <span className="items">
                  {data ? data?.billing.plan_name : "-"}
                </span>
              </div>
              <div className="content-detail-items last">
                <span className="items-title">Eligible Date</span>
                <span className="items">
                  {data ? data?.billing.plan_name : "-"}
                </span>
              </div> */}
            </div>
          </div>
          {data?.company_information ? (
            <div className="section-content-finance">
              <span className="title">Profil Bisnis</span>
              <div className="content-detail">
                <div className="content-detail-items">
                  <span className="items-title">Nama Bisnis</span>
                  <span className="items">
                    {data?.company.name ? data?.company.name : "-"}
                  </span>
                </div>
                <div className="content-detail-items">
                  <span className="items-title">Besar Bisnis</span>
                  <span className="items">
                    {data ? data?.company_information.company_size : "-"}
                  </span>
                </div>
                <div className="content-detail-items">
                  <span className="items-title">Bisnis Industri</span>
                  <span className="items">
                    {data ? data?.company_information.business_industry : "-"}
                  </span>
                </div>
                <div className="content-detail-items">
                  <span className="items-title">Department</span>
                  <span className="items">
                    {data ? data?.company_information.department : "-"}
                  </span>
                </div>
                <div className="content-detail-items last">
                  <span className="items-title">Alamat Lengkap</span>
                  <span className="items">
                    {data ? data?.company_information.address : "-"}
                  </span>
                </div>
              </div>
              {/* <Button outline color="primary" style={{ marginTop: "30px" }}>
                Pengaturan Lengkap
              </Button> */}
            </div>
          ) : (
            <div className="section-content-finance">
              <span className="title">Profil Bisnis</span>
              <div className="content-detail">
                <div className="content-detail-items">
                  <span className="items-title">Nama Bisnis</span>
                  <span className="items">Belum Terdaftar</span>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className="page-content-costume-container"
          style={{ marginTop: "45px" }}
        >
          <span className="title">Platform Terintegrasi</span>
          <div className="section-table">
            <TableComponent
              dataHead={["Platform", "Akun terhubung", "Status", "Aksi"]}
              componentBody={<TableBodyPlatformIntegration dataBody={[]} />}
              loading={loading}
            />
          </div>
        </div>

        <Modal isOpen={modal} toggle={toggle} centered>
          <ContentModal
            id={id}
            data={data}
            toggle={toggle}
            type={typeModalContent}
          />
        </Modal>
      </div>
    </div>
  );
};

export default DetailAccount;
