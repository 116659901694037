import React, { Component, Fragment } from 'react'
import { Parser as HtmlToReactParser } from 'html-to-react'
import {
  Button,
  ListGroup,
  ListGroupItem,
  Spinner,
  UncontrolledAlert
} from 'reactstrap'

// Import Editor
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw, convertFromRaw, convertFromHTML } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { updateStorePolicy } from 'store/store/actions'
import { connect } from 'react-redux';

class StorePolicyCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editorState: EditorState.createEmpty(),
    }
  }

  componentDidMount() {
    const { policy } = this.props

    if (!policy) {
      return
    }

    const content = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(policy.information)
      )
    )

    this.setState({
      editorState: content,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps == this.props) {
      return
    }

    if (prevProps.label == this.props.label) {
      return
    }

    const { policy } = this.props
    let information = ''
    if (policy) {
      information = policy.information
    }

    const content = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(information)
      )
    )
    this.setState({
      editorState: content
    })
  }

  handleOnSubmit = () => {
    const { editorState } = this.state;
    const { updateStorePolicy, label, policy, store } = this.props;

    const data = {
      'label': label,
      'information': draftToHtml(convertToRaw(editorState.getCurrentContent())),
    }

    updateStorePolicy(store.id, data)
  }

  render() {
    const { policy, updatePolicyState, error } = this.props;
    const { defaultContent, editorState } = this.state;

    return (
      <Fragment>
        <Editor
          defaultContentState={defaultContent}
          editorState={editorState}
          onEditorStateChange={(e) => this.setState({ editorState: e })}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          placeholder="Place Your Note Here..."
        />
        {error.detail &&
          <UncontrolledAlert color="danger">{error.detail}</UncontrolledAlert>
        }
        {updatePolicyState.isSuccess &&
          <div className='text-center mt-2 mb-2'>
            <UncontrolledAlert color='primary'>Berhasil memperbarui Policy</UncontrolledAlert>
          </div>
        }
        {updatePolicyState.isRequested &&
          <div className='text-center mt-2 mb-2'>
            <Spinner type="grow" color="primary" />
          </div>
        }
        <Button
          disabled={updatePolicyState.isRequested}
          onClick={this.handleOnSubmit} className='mt-2'>
          Save
        </Button>
        <h5 className='mt-5'>Misc</h5>
        <ListGroup>
          <ListGroupItem>
            <strong>Label:</strong> {policy ? policy.label : ''}
          </ListGroupItem>
        </ListGroup>
        <ListGroup>
          <ListGroupItem>
            <strong>Information URL:</strong> {policy ? policy.information_url : ''}
          </ListGroupItem>
        </ListGroup>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ Store }) => {
  return {
    store: Store.detail.store,
    updatePolicyState: Store.updatePolicy,
    error: Store.error,
  }
}

export default connect(mapStateToProps,
  {
    'updateStorePolicy': updateStorePolicy,
  })(StorePolicyCard);
