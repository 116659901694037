import { call, put, takeEvery } from "redux-saga/effects"

// Redux States
import { GET_INVOICES, GET_INVOICE_DETAIL } from "./actionTypes"
import {
  getInvoicesSuccess,
  getInvoicesFail,
  getInvoiceDetailSuccess,
  getInvoiceDetailFail,
} from "./actions"

// http api
import * as api from './api'

function* fetchInvoices({ payload: { page, limit } }) {
  try {
    const response = yield call(api.getInvoiceList, page, limit)
    yield put(getInvoicesSuccess(response))
  } catch (error) {
    yield put(getInvoicesFail(error))
  }
}

function* fetchInvoiceDetail({ payload: { invoiceNumber } }) {
  try {
    const response = yield call(api.getInvoiceDetail, invoiceNumber)
    yield put(getInvoiceDetailSuccess(response))
  } catch (error) {
    yield put(getInvoiceDetailFail(error))
  }
}

function* invoiceSaga() {
  yield takeEvery(GET_INVOICES, fetchInvoices)
  yield takeEvery(GET_INVOICE_DETAIL, fetchInvoiceDetail)
}

export default invoiceSaga
