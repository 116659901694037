const { get, post, put, del } = require("network/http/api")

export const trainLoadDataset = () => {
  return get(`/admin/training-dataset/load-dataset`)
}

export const getUserLatestDataset = () => {
  return get(`/admin/training-dataset/user-latest-conversation`)
}

export const generateAnswer = (datasetId) => {
  return post(`/admin/training-dataset/generate-answer/${datasetId}`)
}

export const getLeaderBoard = (page, limit) => {
  return get(`/admin/training-dataset/leader-board?page=${page}&&limit=${limit}`)
}
