// REGISTER FORM
export const GET_TRAINING_DATASET_REQUEST = 'GET_TRAINING_DATASET_REQUEST'
export const GET_TRAINING_DATASET_SUCCESS = 'GET_TRAINING_DATASET_SUCCESS'
export const GET_TRAINING_DATASET_FAILED = 'GET_TRAINING_DATASET_FAILED'

export const GET_TRAINING_STATS_REQUEST = 'GET_TRAINING_STATS_REQUEST'
export const GET_TRAINING_STATS_SUCCESS = 'GET_TRAINING_STATS_SUCCESS'
export const GET_TRAINING_STATS_FAILED = 'GET_TRAINING_STATS_FAILED'

export const UPDATE_TRAINING_LABEL_REQUEST = 'UPDATE_TRAINING_LABEL_REQUEST'
export const UPDATE_TRAINING_LABEL_SUCCESS = 'UPDATE_TRAINING_LABEL_SUCCESS'
export const UPDATE_TRAINING_LABEL_FAILED = 'UPDATE_TRAINING_LABEL_FAILED'

export const CORRECT_TRAINING_LABEL_REQUEST = 'CORRECT_TRAINING_LABEL_REQUEST'
export const CORRECT_TRAINING_LABEL_SUCCESS = 'CORRECT_TRAINING_LABEL_SUCCESS'
export const CORRECT_TRAINING_LABEL_FAILED = 'CORRECT_TRAINING_LABEL_FAILED'


export const PREDICT_TRAINING_LABEL_REQUEST = 'PREDICT_TRAINING_LABEL_REQUEST'
export const PREDICT_TRAINING_LABEL_SUCCESS = 'PREDICT_TRAINING_LABEL_SUCCESS'
export const PREDICT_TRAINING_LABEL_FAILED = 'PREDICT_TRAINING_LABEL_FAILED'

