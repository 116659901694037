import { takeEvery, put, call, select } from "redux-saga/effects"

// Chat Redux States
import {
  GET_CONVERSATION,
  GET_CONVERSATION_DETAIL,
  GET_CONVERSATION_SIMULATION_STORE,
  NEW_CONVERSATION,
  UPDATE_CONVERSATION,
  GET_CONVERSATION_SIMULATION,
} from "./actionTypes"
import { resetMessageState } from "store/chat/actions"
import * as actions from "./actions"
import * as api from './api'
import { apiError } from "store/actions"

function* onGetConversation({ payload: { page, platformId, storeId } }) {
  try {
    const response = yield call(api.fetchConversation, page, platformId, storeId)

    const payload = {
      ...response,
    }

    if (page > 1) {
      yield put(actions.getConversationMoreSuccess(payload))
      return
    }
    yield put(actions.getConversationSuccess(payload))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* onGetConversationDetail({ payload }) {
  try {
    const response = yield call(api.getConversationDetail, payload)

    yield put(actions.getConversationDetailSuccess(response))
  } catch (error) {
    yield put(actions.getConversationDetailFail(error))
  }
}

function* onGetConversationSimulation() {
  try {
    yield put(resetMessageState())
    const response = yield call(api.getConversationSimulation)

    yield put(actions.getConversationDetailSuccess(response))
  } catch (error) {
    yield put(actions.getConversationDetailFail(error))
  }
}

function* onGetConversationSimulationStore({ payload }) {
  try {
    const response = yield call(api.getConversationSimulationStore, payload)
    yield put(actions.getConversationDetailSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getConversationDetailFail(error))
  }
}

function* chatSaga() {
  yield takeEvery(GET_CONVERSATION_DETAIL, onGetConversationDetail)
  yield takeEvery(GET_CONVERSATION, onGetConversation)
  yield takeEvery(GET_CONVERSATION_SIMULATION_STORE, onGetConversationSimulationStore)
  yield takeEvery(GET_CONVERSATION_SIMULATION, onGetConversationSimulation)
}

export default chatSaga
