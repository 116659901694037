import React, { useEffect } from 'react'
import { useState } from 'react'
import { Alert, Button, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'

// Import Editor
import { put, handleErrorRequest, post, get } from 'network/http/api';
import { getMessages } from 'store/actions';
import { connect } from 'react-redux';
import CommonEditor from 'components/Common/CommonEditor';

const toolbarOptions = {
  options: ['inline', 'fontSize', 'link', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline'],
  },
}

const AddToKnowledgeBase = ({ message, onGetMessages }) => {
  const [intentText, setIntentText] = useState('')
  const [questionMessage, setQuestionMessage] = useState(null)
  const [productId, setProductId] = useState(null)
  const [productURL, setProductURL] = useState(null)
  const [answerMessage, setAnswerMessage] = useState(message.message)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const onSubmit = () => {
    if (isLoading) return

    setIsLoading(true)
    const data = {
      'product_id': productId,
      'question': questionMessage,
      'answer': answerMessage,
      'intent': intentText,
      'intent_score': intentText == '' ? 0.6 : 0.8,
    }
    post(`/admin/product/knowledge/${productId}`, data)
      .then(resp => {
        setErrorMessage(null)
        setIsLoading(false)
        onGetMessages(message.conversation_id)
        setIsOpen(false)
      })
      .catch(error => {
        let errorMsg = handleErrorRequest(error)
        setIsLoading(false)
        setErrorMessage(errorMsg)
      })
  }

  const getMessageQuestion = () => {
    setIsLoading(true)

    get(`/admin/message/context/${message.id}`)
      .then(data => {
        console.log('message', data)
        setQuestionMessage(data.questions.join("<br/>"))
        setIntentText("")
        setProductId(data.product_id)
        setProductURL(data.product_url)
        setIsLoading(false)
      })
      .catch(error => {
        let errorMsg = handleErrorRequest(error)
        setIsLoading(false)
        setErrorMessage(errorMsg)
      })
  }

  useEffect(() => {
    if (isOpen) {
      getMessageQuestion()
    }

  }, [isOpen])


  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        className='m-2 font-size-10 cursor-pointer'
        onClick={() => setIsOpen(true)}>
        {message.is_rejected &&
          <>
            <i className="fa fa-folder-plus pl-2" /> Added
          </>
        }
        {!message.is_rejected &&
          <>
            <i className="fa fa-folder-minus pl-2" /> Add
          </>
        }
      </span>
      <Modal fullscreen isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalHeader>
          Add Knowledge Base
        </ModalHeader>
        <ModalBody>
          {productId &&
            <Label>Context Product: {productId} / {productURL}</Label>
          }
          {questionMessage &&
            <>
              <h4>Question</h4>
              <div className='p-2' style={{ border: '1px solid #e6e6e6' }}>
                <CommonEditor
                  id={message.id + "_question"}
                  onChange={(e) => setQuestionMessage(e)}
                  text={questionMessage} />
              </div>
              <br />
            </>
          }

          <h4>Answer</h4>
          <div className='p-2' style={{ border: '1px solid #e6e6e6' }}>
            <CommonEditor
              id={message.id + "_answer"}
              onChange={(e) => setAnswerMessage(e)}
              text={answerMessage}
            />
          </div>
          <br />

          <InputGroup>
            <InputGroupText>Intent</InputGroupText>
            <Input type="select"
              value={intentText}
              onChange={(e) => setIntentText(e.target.value)}>
              <option value="">Automation</option>
              <option value="product_guideline">Product Guideline</option>
              <option value="product_condition">Product Condition</option>
            </Input>
          </InputGroup>

          {errorMessage &&
            <Alert color='warning'>
              {errorMessage}
            </Alert>
          }

          {isLoading &&
            <div className='text-center'>
              <Spinner type='grow' color='primary' />
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <Button disabled={isLoading} onClick={() => setIsOpen(!isOpen)}>Close</Button>
          <Button disabled={isLoading} onClick={onSubmit} color='primary'>Save</Button>
        </ModalFooter>
      </Modal>

    </>
  )
}

export default connect(null, {
  'onGetMessages': getMessages,
})(AddToKnowledgeBase)
