import SanitizeHTML from "components/SanitizeHTML";
import { get, handleErrorRequest } from "network/http/api";
import React, { memo, useEffect, useState } from "react";

const MessageBubbleReply = ({ message }) => {
  const [replyMessage, setReplyMessage] = useState(null)


  useEffect(() => {
    if (message.reply_id < 1) {
      return
    }
    if (message.parent_message) {
      setReplyMessage(message.parent_message)
      return
    }

    get(`/message/${message.reply_id}`)
      .then(resp => {
        setReplyMessage(resp)
      })
  }, [message])

  if (message.reply_id < 1 || !replyMessage) {
    return null
  }

  return (<div className="chat-message-reply text-muted p-2 font-size-12 background-secondary">
    <SanitizeHTML html={replyMessage.message} />
  </div>)
}

export default memo(MessageBubbleReply)
