import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const SearchComponent = ({ type = "full", setQuery }) => {
  const onChange = event => {
    setQuery(event.target.value);
  };

  return (
    <div className={`component-search ${type === "full" ? "full" : ""}`}>
      <div className="input-icon">
        <MagnifyingGlassIcon className="icon" aria-hidden="true" />
      </div>
      <input
        type="text"
        name="query"
        id="query"
        className="input-search"
        placeholder="Cari sesuatu disini"
        onChange={onChange}
      />
    </div>
  );
};

export default SearchComponent;
