import React, { useState } from "react"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw, convertFromRaw, convertFromHTML } from 'draft-js';
import { Alert, Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { handleErrorRequest, post } from "network/http/api";
import { submitDatasetSuccess } from 'store/dataset-train/actions'
import { connect } from "react-redux";

const toolbarOptions = {
  options: ['inline', 'fontSize', 'link', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline'],
  },
}

const SubmitDataset = ({ dataset, onSubmitDatasetSuccess }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isApproved, setIsApproved] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const handleSubmitDataset = () => {
    setIsLoading(true)
    const contentSate = editorState.getCurrentContent()
    const content = draftToHtml(convertToRaw(contentSate))
    if (content == '<p></p>' || content.length < 10) {
      setIsLoading(false)
      setErrorMessage('Mohon input note, harap isi minimal 1 paragrap')
      return
    }

    const body = {
      note: content,
      is_approved: isApproved,
    }

    post(`/admin/training-dataset/submit-conversation/${dataset.id}`, body)
      .then(resp => {
        setIsLoading(false)
        setIsSuccess(true)
        setErrorMessage(null)
        onSubmitDatasetSuccess()
      })
      .catch(err => {
        const errorMessage = handleErrorRequest(err)
        setErrorMessage(errorMessage)
        setIsLoading(false)
        setIsSuccess(false)
      })
  }

  const toggleModal = () => {
    if (isOpen) {
      setEditorState(EditorState.createEmpty())
    }

    setIsOpen(!isOpen)
  }

  return (
    <div>
      <Button
        disabled={isLoading}
        onClick={() => setIsOpen(true)}
        className="p-3 font-size-14 btn-full-size"
        color="warning">
        Submit Conversation
      </Button>

      <Modal isOpen={isOpen}>
        <ModalHeader>Submit Conversation</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup switch className="mb-3">
              <Label>Is Approve</Label>
              <Input
                checked={isApproved}
                onChange={() => setIsApproved(!isApproved)}
                type="switch"
                role="switch">
              </Input>
            </FormGroup>

            <Editor
              toolbar={toolbarOptions}
              editorState={editorState}
              onEditorStateChange={(e) => setEditorState(e)}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              placeholder="Place Your Note Here..."
            />
            <Label>Please input note (is required)</Label>

          </Form>

          <div className="text-center">
            {errorMessage &&
              <Alert color="danger">
                {errorMessage}
              </Alert>
            }
            {isSuccess &&
              <Alert color="primary">
                Berhasil mengirim dataset conversation
              </Alert>
            }
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="text-center">
            {isLoading &&
              <Spinner />
            }
          </div>
          <Button
            color="warning"
            onClick={toggleModal}>Close</Button>

          <Button
            color="primary"
            disabled={isLoading}
            onClick={handleSubmitDataset}>Submit</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default connect(null, {
  'onSubmitDatasetSuccess': submitDatasetSuccess
})(SubmitDataset)
