import React, { Component, Fragment } from 'react'
import {
  Button, Modal, ModalBody, ModalHeader,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,

} from 'reactstrap';
import { connect } from 'react-redux';

// Import Editor
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AUTH_USER_KEY } from 'network/http/jwt-token-access';
import { withRouter } from 'react-router-dom';

// actions
import { addNewTicket } from 'store/ticket/actions'

class NewTicketModal extends Component {
  constructor(props){
    super(props)
    const currentUser = JSON.parse(localStorage.getItem(AUTH_USER_KEY))
    this.state = {
      isModalOpen: false,
      currentUser,
      title: '',
      note: '',
      editorState: EditorState.createEmpty()
    }
  }

  handleOnSubmit = () => {
    const { title, editorState, currentUser } = this.state;
    const { addNewTicket, history, openNewTab, conversationId } = this.props;

    const data = {
      title: title,
      note: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      company_id: currentUser.company_id,
      user_id: currentUser.id,
      conversation_id: conversationId,
    }
    addNewTicket(data, history, openNewTab)
  }

  render(){
    const {isModalOpen, title, note, editorState} = this.state
    const { isRequested, error } = this.props;

    return (<Fragment>
      <Button
        color='primary'
        onClick={()=> this.setState({isModalOpen: true})}>New Ticket</Button>
      <Modal
        size='xl'
        isOpen={isModalOpen}
        toggle={()=> this.setState({isModalOpen: !isModalOpen})}>
        <ModalHeader>
          New Ticket
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <form className="outer-repeater">
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer">
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-2"
                      >
                        Title
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          value={title}
                          onChange={(e)=> this.setState({title: e.target.value})}
                          className="form-control"
                          placeholder="Enter Title..."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Note
                      </Label>
                      <Col lg="10">
                        <Editor
                          // initialContentState={note}
                          editorState={editorState}
                          onEditorStateChange={(e) => this.setState({editorState: e})}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          placeholder="Place Your Note Here..."
                        />
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </form>
              <Alert color='warning' type="warning">

              </Alert>
              <Row className="justify-content-end">
                <Col lg="10">
                  <Button
                    disabled={isRequested}
                    onClick={this.handleOnSubmit}
                    type="submit" color="primary">
                    Create Ticket
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </Fragment>);
  }
}

const mapStateToProps = ({Ticket}) => {
  return {
    isRequested: Ticket.isRequested,
    error: Ticket.error,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {
    addNewTicket,
  }
)(NewTicketModal))
