import * as types from './actionTypes'

export const fetchCompany = (page = 1, limit = 10) => ({
  type: types.FETCH_COMPANY_REQUEST,
  payload: { page, limit }
})


export const fetchCompanySuccess = (payload) => ({
  type: types.FETCH_COMPANY_SUCCESS,
  payload: payload
})


export const fetchCompanyFailed = (error) => ({
  type: types.FETCH_COMPANY_FAILED,
  payload: payload
})

export const getCompany = (id) => ({
  type: types.GET_COMPANY_REQUEST,
  payload: id
})


export const getCompanySuccess = (payload) => ({
  type: types.GET_COMPANY_SUCCESS,
  payload: payload
})


export const getCompanyFailed = (error) => ({
  type: types.GET_COMPANY_FAILED,
  payload: payload
})
