import SanitizeHTML from "components/SanitizeHTML"
import { del, handleErrorRequest } from "network/http/api"
import React, { useState } from "react"
import { connect } from "react-redux"
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import { getMessages } from "store/actions"

const MessageDeleteButton = ({ message, getMessages }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleOnDeleteMessage = () => {
    setIsLoading(true)
    del(`/admin/message/${message.id}`)
      .then(resp => {
        setIsLoading(false)
        setIsSuccess(true)
        // refresh message
        getMessages(message.conversation_id)
      })
      .catch(err => {
        setErrorMessage(handleErrorRequest(err))
        setIsLoading(false)
        setIsSuccess(null)
      })
  }

  return (
    <>
      <small onClick={() => setIsOpen(true)} className='p-1 cursor-pointer'>
        <i className='bx bx-trash'>
        </i>
        delete
      </small>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(!isLoading)}>
        <ModalHeader>are you sure to delete this message?</ModalHeader>
        <ModalBody>
          <div className="text-muted font-size-12">
            <SanitizeHTML html={message.message} />
          </div>

          <div className="m-3 text-center">
            {isLoading &&
              <Spinner />
            }
          </div>

          {errorMessage &&
            <Alert color="danger">
              {errorMessage}
            </Alert>
          }

          {isSuccess &&
            <Alert color="primary">
              Success delete message
            </Alert>
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setIsOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button disabled={isLoading || isSuccess} onClick={handleOnDeleteMessage} color="danger">
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default connect(null, {
  'getMessages': getMessages
})(MessageDeleteButton);
