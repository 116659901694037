import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Modal } from "reactstrap";
import BackButton from "components/BackButton";
import Breadcrumb from "components/Breadcrumb";
import { get } from "network/http/api";
import ContentModal from "../ComponentsPage/ContentModal";
import { STATUS_STYLES, handleStatusName } from "../DefaultData";

const BusinessDetail = ({company}) => {
  if (!company){
    return null
  }
  return (
          <div className="section-content-finance">
            <span className="title">Informasi Business</span>
            <div className="content-detail">
              <div className="content-detail-items">
                <span className="items-title">Business Name</span>
                <span className="items">{company ? company?.name : "-"}</span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Employees</span>

                {company.employees.map(emp => (
                  <div key={emp.id}>
                    <Link
                    to={ `/account/details/${emp.id}` }
                    rel="noopener noreferrer"
                  >
                    {emp.user_id}
                  </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
  )
}

const DetailTokopedia = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [typeModalContent, setTypeModalContent] = useState("password");
  const [data, setData] = useState(null);
  const [company, setCompany] = useState(null)
  const [modal, setModal] = useState(false);
  const breadcrumbItems = [
    { label: "List Daftar Toko", path: "/business" },
    { label: "Detail Toko", path: `/business/details/${id ? id : ""}` },
  ];

  const toggle = paramsType => {
    setTypeModalContent(paramsType);
    setModal(!modal);
  };

  const fetchUserDetail = async paramsId => {
    setLoading(true);
    try {
      const res = await get(`/admin/account/store/${paramsId}`);
      setData(res.store);
      setCompany(res.company)
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetail(id);
  }, [id]);

  return (
    <div className="page-container">
      <div className="page-content-costume">
        <BackButton />
        <div style={{ margin: "28px 0px 32px" }}>
          <Breadcrumb items={breadcrumbItems} />
        </div>

        <div className="page-detail-finance">
          <div className="section-content-finance">
            <span className="title">Informasi Toko</span>
            <div className="content-detail">
              <div className="content-detail-items">
                <span className="items-title">ID Toko</span>
                <span className="items">{data ? data?.id : "-"}</span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Bisnis Name</span>
                <span className="items">{data ? data?.store_name : "-"}</span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Bisnis Url</span>
                <span className="items">
                  <Link
                    to={{ pathname: data?.store_url }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data ? data?.store_url : "-"}
                  </Link>
                </span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Bisnis Platform</span>
                <span className="items capitalize">
                  {data ? data?.platform.platform_name : "-"}
                </span>
              </div>
              <div className="content-detail-items">
                <span className="items-title">Status Bisnis</span>
                <div className="items">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        ...STATUS_STYLES[data?.authorize_status],
                        textTransform: "capitalize",
                        padding: "4px 10px",
                        borderRadius: "12px",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {data ? handleStatusName(data?.authorize_status) : "-"}
                    </span>
                    <Button
                      outline
                      color="primary"
                      onClick={() => toggle("status_bisnis")}
                    >
                      Change
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            
          </div>

          {/* BUSINESS INFO */}
          <BusinessDetail company={company} />
        </div>

        <Modal isOpen={modal} toggle={toggle} centered>
          <ContentModal
            id={id}
            fetchData={fetchUserDetail}
            data={data}
            toggle={toggle}
            type={typeModalContent}
          />
        </Modal>
      </div>
    </div>
  );
};

export default DetailTokopedia;
