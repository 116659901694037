import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Alert, Button, Card, CardBody, CardTitle, Spinner } from "reactstrap";

import AvatarDefault from 'assets/images/avatar-default.png'
import { uploadFile } from "network/http/api";
import Avatar from "components/Avatar";

const fileTypes = ["JPG", "PNG", "JPEG"];

function UploadAvatar({ user }) {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (file) => {
    setFile(file);
    setPreview(URL.createObjectURL(file))
  };


  const onUploadFile = () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    uploadFile('/user/avatar', file)
      .then(response => {
        setError(null)
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
        setIsLoading(false)
      })
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>Upload Avatar</CardTitle>
        <div className="text-center mb-3">
          <Avatar user={user} />
          {preview &&
            <div>
              <img className="mb-2 avatar-xl img-responsive" src={preview} />
              <p>{file ? `File name: ${file.name}` : ""}</p>
              <Button
                disabled={isLoading}
                onClick={onUploadFile}> Upload File </Button>
            </div>
          }
        </div>

        <FileUploader
          maxSize={2}
          handleChange={handleChange}
          name="file"
          types={fileTypes} />

        {error &&
          <Alert color="danger">
            Error: {error.detail}
          </Alert>
        }

        {isLoading &&
          <div className="text-center" >
            <Spinner />
          </div>
        }


        <small className="text-muted">
          Max file size is 2 MB
        </small>
      </CardBody>
    </Card>
  );
}

export default UploadAvatar;
