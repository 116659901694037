import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import * as actions from "./actions"
import * as api from './api'
import {
  GET_STORE_DETAIL_REQUEST, GET_STORE_REQUEST,
  UPDATE_STORE_POLICY_REQUEST, UPDATE_STORE_REQUEST, UPDATE_STORE_STATUS_REQUEST
} from "./actionTypes"

function* onFetchStore({ payload: { page, limit } }) {
  try {
    const response = yield call(api.fetchStore, page, limit)

    yield put(actions.getStoreSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getStoreFailed(error))
  }
}

function* onGetDetailStore({ payload }) {
  try {
    const response = yield call(api.fetchStoreDetail, payload)

    yield put(actions.getStoreDetailSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getStoreDetailFailed(error))
  }
}


function* onUpdateStorePolicy({ payload: { storeId, policy } }) {
  try {
    const response = yield call(api.updateStorePolicy, storeId, policy)

    yield put(actions.updateStorePolicySuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.updateStorePolicyFailed(error))
  }
}

function* onUpdateStore({ payload: { id, shop } }) {
  try {
    const response = yield call(api.updateStore, id, shop)
    yield put(actions.updateStoreSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.updateStoreFailed(error))
  }
}

function* onUpdateStoreStatus({ payload: { storeId, status } }) {
  try {
    const response = yield call(api.updateStoreStatus, storeId, status)
    yield put(actions.updateStoreSuccess(response))
  } catch (error) {
    yield put(actions.updateStoreFailed(error))
  }
}

function* shopSaga() {
  yield takeEvery(UPDATE_STORE_STATUS_REQUEST, onUpdateStoreStatus)
  yield takeEvery(UPDATE_STORE_POLICY_REQUEST, onUpdateStorePolicy)
  yield takeEvery(GET_STORE_REQUEST, onFetchStore)
  yield takeEvery(GET_STORE_DETAIL_REQUEST, onGetDetailStore)
  yield takeEvery(UPDATE_STORE_REQUEST, onUpdateStore)
}

export default shopSaga
