import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom";
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import * as actions from 'store/training/actions'

const SentimentMessage = ({ question, onPredict, isPredicting }) => {
  const button = <Button
    size="large"
    width="100%"
    disabled={isPredicting && question.message_id > 0}
    onClick={() => onPredict(question.id)}
    color="primary">
    {isPredicting ? 'Predicting...' : 'Predict'}
  </Button>

  if (!question.message) {
    return button
  }

  const { feature } = question.message
  if (!feature) {
    return button;
  }

  return (
    <div className="text-muted">
      <h5>Prediction</h5>
      <p>
        <strong>
          Intent : {feature.intention_score.label} ({feature.intention_score.score})
        </strong>
      </p>
      <p>
        <strong>
          Sentiment : {feature.sentiment_score.label}
        </strong>
      </p>

      <p>
        <strong>NER</strong>
      </p>
      <ul>
        {feature.ner_scores.map((ner, i) => (
          <li key={i}>
            [Word: {ner.word}] {" "}
            [{ner.label} ({ner.score})]
          </li>
        ))}
      </ul>

      <Button outline
        onClick={() => onPredict(question.id)} >
        <i className="fa fa-info"></i> Retry Predict
      </Button>
    </div>
  )
}

class TrainDataset extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      selectedLabel: '',
      errorLabel: ''
    }
  }

  componentDidMount() {
    const { getTrainingDataset, getStatsTrainingDataset, match } = this.props;
    const { params } = match

    getTrainingDataset()
    getStatsTrainingDataset()
  }

  handleOnPredict = (id) => {
    const { predictAnswer } = this.props;

    predictAnswer(id)
  }

  handleOnSubmitWrongLabel = () => {
    const { selectedLabel } = this.state
    if (selectedLabel === '') {
      this.setState({
        errorLabel: 'Please select label'
      })
      return
    }
    const { updateTrainingLabelDataset, getTrainingDataset, dataset } = this.props;
    const { question } = dataset

    updateTrainingLabelDataset(question.id, selectedLabel)

    this.setState({
      errorLabel: null,
      isOpen: false,
      selectedLabel: '',
    })

    getTrainingDataset()
  }

  handleOnCorrectLabel = () => {
    const { correctTrainingLabelDataset, getTrainingDataset, dataset } = this.props;
    const { question, total_item } = dataset
    correctTrainingLabelDataset(question.id)
  }

  render() {
    const { isOpen, errorLabel } = this.state;
    const { dataset, stats, isFetching, isPredicting } = this.props;
    const { question, intention_labels, total_item } = dataset

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs="12" md="3">
                <Card>
                  <CardBody>
                    <CardTitle>Stats</CardTitle>
                    <ul className="font-size-14">
                      <li>Total Dataset: <strong>{stats.total_dataset}</strong> </li>
                      <li>Total Labeld: <strong>{stats.total_labeled}</strong></li>
                      <li>Total Correct Label: <strong>{stats.total_correct_label}</strong></li>
                      <li>Total Updated Label: <strong>{stats.total_new_label}</strong></li>
                    </ul>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" md="9">
                <Card key={question.id}
                  className="mb-5"
                  style={{ boder: '1px solid #e6e6e6' }}>
                  <CardHeader>
                    {question.title}
                  </CardHeader>
                  <CardBody>
                    <h5>Question: {question.question}</h5>
                    <p>Answer: {question.answer}</p>
                    <div>
                      <h5>Response From Agent:</h5>
                      {question.response_from_agent &&
                        <p>{question.response_from_agent.message}</p>
                      }
                    </div>
                  </CardBody>
                  <CardFooter>
                    <SentimentMessage
                      isPredicting={isPredicting}
                      onPredict={this.handleOnPredict}
                      question={question} />
                  </CardFooter>

                  {question.message_id > 0 &&
                    <Row className="p-5">
                      <Col xs={6}>
                        <Button
                          size="large"
                          disabled={isPredicting && question.message_id > 0}
                          onClick={() => this.setState({ isOpen: true })}
                          color="danger">
                          Label is wrong
                        </Button>
                      </Col>
                      <Col xs={6}>
                        <Button
                          size="large"
                          disabled={isPredicting && question.message_id > 0}
                          onClick={this.handleOnCorrectLabel}
                          color="primary">
                          Label is Correct
                        </Button>
                      </Col>
                    </Row>
                  }
                </Card>
                <Modal isOpen={isOpen}>
                  <ModalHeader>
                    Update Dataset
                  </ModalHeader>
                  <ModalBody>
                    {errorLabel &&
                      <Alert color="danger">
                        {errorLabel}
                      </Alert>
                    }
                    <Form>
                      <FormGroup>
                        <Label>
                          Please choose correct label
                        </Label>
                        <Input
                          onChange={(e) => this.setState({
                            selectedLabel: e.target.value,
                          })}
                          bsSize="lg"
                          id="exampleSelect"
                          name="select"
                          type="select">
                          {intention_labels.map(label => (
                            <option key={label}>
                              {label}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={() => this.setState({ isOpen: false })}
                      color="danger">
                      Close
                    </Button>
                    <Button
                      onClick={this.handleOnSubmitWrongLabel}
                      color="primary">
                      Submit
                    </Button>
                  </ModalFooter>

                </Modal>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ TrainingDataset }) => {
  return TrainingDataset
}

export default connect(mapStateToProps, {
  getTrainingDataset: actions.getTrainingDataset,
  predictAnswer: actions.predictAnswer,
  correctTrainingLabelDataset: actions.correctTrainingLabelDataset,
  getStatsTrainingDataset: actions.getStatsTrainingDataset,
  updateTrainingLabelDataset: actions.updateTrainingLabelDataset,
})(TrainDataset)
