import React, { useState } from "react";
import { Button } from "reactstrap";
import { put } from "network/http/api";

const LikeKnowledgeBase = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOnSubmit = (isLike) => {
    let url = `/admin/product/knowledge-dislike/${data['id']}`
    if (isLike) {
      url = `/admin/product/knowledge-like/${data['id']}`
    }
    setIsLoading(true)
    put(url)
      .then(resp => {
        setErrorMessage(null)
        setIsLoading(false)
        toggle()
        data['num_of_like'] = isLike ? 1 : 0
        refreshFunc()
      })
      .catch(err => {
        setIsLoading(false)
        setErrorMessage(err.detail)
      })
  }

  return (
    <>
      <Button outline
        disabled={isLoading}
        color={data['num_of_like'] > 0 ? 'primary' : ''}
        onClick={() => handleOnSubmit(true)}>
        <i className='bx bx-like'></i>
        <span className="m-1" />
        Helpful?
      </Button>
      <Button outline
        disabled={isLoading}
        color={data['num_of_like'] < 0 ? 'danger' : ''}
        onClick={() => handleOnSubmit(false)}>
        <i className='bx bx-dislike'></i>
        <span className="m-1" />
        Not Helpful?
      </Button>
      {data['is_synced'] &&
        <Button disabled={true} outline={true} color="">
          <i className='bx bx-tag'></i>
          <span className="m-1" />
          Synced
        </Button>
      }
    </>
  )
}

export default LikeKnowledgeBase
