import React, { useState, useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import toast from "react-hot-toast";
import { post, put } from "network/http/api";
import { STATUS_DEFAULT, STATUS_DEFAULT_BISNIS } from "../DefaultData";
import DropdownSelected from "components/DropdownSelected";

const ContentModal = ({ data, fetchData, toggle, type = "password" }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const dataStatus = data.user ? data.user.status : data.authorize_status;
    const resultStatus = STATUS_DEFAULT.find(
      status => status.value === dataStatus
    );
    const resultStatusBisnis = STATUS_DEFAULT_BISNIS.find(
      status => status.value === dataStatus
    );

    setStatus(data.user ? resultStatus : resultStatusBisnis);
  }, [data, type]);

  const onChangePassword = () => {
    setLoading(true);
    post(`/admin/account/admin-change-password/${data.user.id}`, data.user.id)
      .then(async resp => {
        setLoading(false);
        toggle();
        toast.success("Sukses ganti password!");
      })
      .catch(err => {
        setLoading(false);
        toast.error(err.detail);
      });
  };

  const onSubmitChangeStatus = () => {
    setLoading(true);
    const payload = {
      user_id: data.user.id,
      email: data.user.email,
      is_email_verified: data.user.is_email_verified,
    };

    put(`/admin/account/update-user-registration-status`, payload)
      .then(async resp => {
        setLoading(false);
        toggle();
        toast.success("Sukses ganti status!");
      })
      .catch(err => {
        setLoading(false);
        toast.error(err.detail);
      });
  };

  const onSubmitChangeStatusBisnis = () => {
    setLoading(true);
    const payload = {
      store_id: data.id,
      status: status.value,
      is_email_notification_disabled: false,
    };

    put(`/admin/account/update-store-status`, payload)
      .then(async resp => {
        setLoading(false);
        toggle();
        fetchData(data.id);
        toast.success("Sukses ganti bisnis status!");
      })
      .catch(err => {
        setLoading(false);
        toast.error(err.detail);
      });
  };

  const onChangeStatus = values => {
    setStatus(values);
  };

  if (type === "status") {
    return (
      <div
        style={{
          padding: "40px 30px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          textAlign: "center",
        }}
      >
        <span style={{ fontSize: "16px", fontWeight: "600" }}>
          Apakah anda yakin mau menggubah status user?
        </span>
        <span style={{ fontSize: "14px", fontWeight: "400" }}>
          Anda mengganti status user melalui admin panel.
        </span>
        <DropdownSelected
          data={STATUS_DEFAULT}
          selected={status}
          setSelected={value => onChangeStatus(value)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            margin: "10px auto 0px",
          }}
        >
          <Button color="primary" disabled={loading} outline onClick={toggle}>
            Batal
          </Button>
          <Button color="primary" onClick={() => onSubmitChangeStatus()}>
            {loading ? (
              <Spinner color="primary" size="sm" />
            ) : (
              "Ya, Ubah Status User"
            )}
          </Button>
        </div>
      </div>
    );
  }

  if (type === "status_bisnis") {
    return (
      <div
        style={{
          padding: "40px 30px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          textAlign: "center",
        }}
      >
        <span
          style={{
            fontSize: "16px",
            fontWeight: "600",
            maxWidth: "300px",
            margin: "auto",
          }}
        >
          Apakah anda yakin mau menggubah status bisnis ini?
        </span>
        <span style={{ fontSize: "14px", fontWeight: "400" }}>
          Anda mengganti status bisnis melalui admin panel.
        </span>
        <DropdownSelected
          data={STATUS_DEFAULT_BISNIS}
          selected={status}
          setSelected={value => onChangeStatus(value)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            margin: "10px auto 0px",
          }}
        >
          <Button color="primary" disabled={loading} outline onClick={toggle}>
            Batal
          </Button>
          <Button color="primary" onClick={() => onSubmitChangeStatusBisnis()}>
            {loading ? (
              <Spinner color="white" size="sm" />
            ) : (
              "Ya, Ubah Status Bisnis"
            )}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        padding: "40px 30px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        textAlign: "center",
      }}
    >
      <span style={{ fontSize: "16px", fontWeight: "600" }}>
        Apakah Anda yakin ingin mengubah password user?
      </span>
      <span style={{ fontSize: "14px", fontWeight: "400" }}>
        Kami akan mengirimkan permintaan penggantian password kepada user
        melalui email mereka.
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          margin: "30px auto 0px",
        }}
      >
        <Button color="primary" disabled={loading} outline onClick={toggle}>
          Batal
        </Button>
        <Button color="primary" onClick={() => onChangePassword()}>
          {loading ? <Spinner color="white" size="sm" /> : "Ya, Ubah Password"}
        </Button>
      </div>
    </div>
  );
};

export default ContentModal;
