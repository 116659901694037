import React, { Component } from "react";
import StoreOverview from "components/Store/StoreOverview";

class StoreDetail extends Component {
  render() {
    return <StoreOverview />
  }
}

export default StoreDetail
