import React, { Component, Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Alert } from "reactstrap";
import MiniCards from "components/Dashboard/MiniCard";
import Skeleton from "react-loading-skeleton";
import { withRouter } from "react-router-dom";
import { get, handleErrorRequest } from "network/http/api";
import CardWelcome from "../../components/Dashboard/CardWelcome";
import ConversationStats from "components/Dashboard/ConversationStats";

const Dashboard = () => {
  const [dashboardStats, setDashboardStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    get(`/admin/dashboard`)
      .then(resp => {
        setDashboardStats(resp);
        setIsLoading(false);
        setErrorMessage(null);
      })
      .catch(err => {
        setIsLoading(false);
        setErrorMessage(handleErrorRequest(err));
      });

    return () => {
      setErrorMessage(null);
      setIsLoading(false);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <Container fluid>
          <CardWelcome />
          <Row>
            <Col xs="8">
              <Row>
                {isLoading && (
                  <div className="text-center">
                    <Skeleton count={4} />
                  </div>
                )}

                {dashboardStats && (
                  <>
                    <MiniCards
                      rowSize={6}
                      to="/account"
                      iconClass="bx bx-box"
                      title="Total Registered Company"
                      text={dashboardStats.total_company}
                    />
                    <MiniCards
                      rowSize={6}
                      to="/finance"
                      iconClass="bx bx-reciept"
                      title="Total Unpaid Invoice"
                      text={dashboardStats.total_invoice_unpaid}
                    />
                    <MiniCards
                      rowSize={12}
                      to="/list-tokpedia"
                      iconClass="bx bx-home"
                      title="Total Store"
                      text={dashboardStats.total_store}
                    />
                    <MiniCards
                      rowSize={4}
                      to="/list-tokpedia"
                      title="Registered Store"
                      text={dashboardStats.total_store_register}
                    />
                    <MiniCards
                      rowSize={4}
                      to="/list-tokpedia"
                      title="In Progress Store"
                      text={dashboardStats.total_store_in_progress}
                    />
                    <MiniCards
                      rowSize={4}
                      to="/list-tokpedia"
                      title="Active Store"
                      text={dashboardStats.total_store_completed}
                    />

                    <Col xl={12}>
                      <ConversationStats
                        conversationStats={dashboardStats.conversation_stats}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            <Col xs="4">
              <MiniCards
                to="/chat"
                title="Total Conversation"
                iconClass="bx-message"
              />
              <MiniCards
                rowSize={12}
                to="/chat-simulation"
                title="Try our agent now!"
                text="Simulation chat"
                iconClass="bx-message"
              />
              {/* <SalesAnalytics /> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Dashboard);
