import React, { useState } from "react";
import { Button } from "reactstrap";
import ProductKnowledgeModal from "./ProductKnowledgeModal";
import { post } from "network/http/api";

const AddKnowledgeBase = ({ productId, refreshFunc }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOnSubmit = (answer, question) => {
    setIsLoading(false)
    const data = {
      'product_id': productId,
      'answer': answer,
      'question': question,
      'intent': 'product_guideline',
      'intent_score': 1,
    }
    post(`/admin/product/knowledge/${productId}`, data)
      .then(resp => {
        setIsLoading(false)
        setErrorMessage(null)
        toggle()
        refreshFunc()
      })
      .catch(err => {
        setIsLoading(false)
        console.log('error', err)
        setErrorMessage(err.detail)
      })
  }

  return (
    <>
      <Button outline
        onClick={toggle}
        disabled={isLoading} color='primary mb-2'>
        <i className='bx bx-plus-circle' style={{ paddingRight: 5 }} />Add Knowledge
      </Button>
      <ProductKnowledgeModal
        toggle={toggle}
        isOpen={isOpen}
        isLoading={isLoading}
        id={0}
        errorMessage={errorMessage}
        onSubmit={handleOnSubmit}
        title="Add product knowledge"
        refreshFunc={refreshFunc}
      />
    </>
  )
}

export default AddKnowledgeBase
