const { get, post, put, del } = require("network/http/api")
import axios from 'axios';
import { API_URL } from 'network/http/api';

export const login_user = async (payload) => {
  const response = await axios.post(
    API_URL + '/admin/login',
    new URLSearchParams({
      'grant_type': 'password',
      'username': payload.email,
      'password': payload.password,
      'scope': '',
      'client_id': '',
      'client_secret': ''
    }),
    {
      headers: {
        'accept': 'application/json'
      }
    }
  ).then(response => response.data);
  return response
}
