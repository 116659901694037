import React from 'react'
import {
  Message,
  MessageSeparator,
  MessageGroup,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import moment from 'moment';
import user_logo from 'assets/images/user-avatar-default.svg'
import MessageBubbleFooter from '../Footer/MessageBubbleFooter';
import MessageBubbleText from './MessageBubbleText';
import MessageBubbleProductList from './MessageBubbleProductList';
import MessageBubbleReply from './MessageBubbleReply';
import MessageBubbleImage from './MessageBubbleImage';
import classNames from 'classnames';

const messageBubbleUIKit = (user_message, scrollToBottom, selectedMessage, questionMode, key) => {
  // key is used for react child list
  if (!user_message.user) {
    return <MessageSeparator
      key={key}
      as='h2'
      className='mt-2 mb-2'
      content={user_message.messages[0].message} />
  }

  const sender = user_message.user
  const messageDirectionQuestion = user_message.messages[0].is_question
  return (
    <MessageGroup
      key={key}
      direction={messageDirectionQuestion ? 'outgoing' : 'incoming'}
    >
      <Avatar src={user_logo} name={sender.fullname} />
      <MessageGroup.Header>
        <strong>{user_message.user.fullname}</strong>
      </MessageGroup.Header>
      <MessageGroup.Messages>

        {user_message.messages.map((message) => {
          if (message.type == 'product_list') {
            return <MessageBubbleProductList
              key={message.id}
              sender={sender}
              scrollToBottom={scrollToBottom}
              message={message}
              questionMode={questionMode} />
          }

          let className = classNames({
            'generated_by_ai': message.generated_by_ai,
            'message-bubble-active': selectedMessage == message.id,
            "message-bubble-rejected": message.is_rejected,
          })

          return (
            <div key={message.id}>
              <MessageBubbleReply message={message} />
              {message.type === 'image' &&
                <MessageBubbleImage
                  key={message.id}
                  sender={sender}
                  className={className}
                  message={message}
                  questionMode={questionMode} />
              }
              {message.type === 'text' &&
                <MessageBubbleText
                  key={message.id}
                  sender={sender}
                  className={className}
                  message={message}
                  questionMode={questionMode} />
              }
            </div>
          )
        })}
      </MessageGroup.Messages>

    </MessageGroup>
  )
}

export default messageBubbleUIKit
