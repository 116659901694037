import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { del } from "network/http/api";

const DeleteKnowledgeBase = ({ id, refreshFunc }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOnSubmit = () => {
    setIsLoading(true)
    del(`/admin/product/knowledge/${id}`)
      .then(resp => {
        toggle()
        setIsLoading(false)
        refreshFunc()
      })
      .catch(err => {
        setIsLoading(false)
        setErrorMessage(err.detail)
      })
  }

  return (
    <>
      <Button onClick={() => toggle()}
        style={{ color: "#ff5757" }}
        color='' disabled={isLoading}>
        <i className='bx bx-trash danger' style={{ paddingRight: 10, }} />
        Delete
      </Button>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalBody>
          You can&apos;t restore deleted knowledge base
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={toggle}
            disabled={isLoading}>Cancel</Button>
          <span className="m-1"></span>
          <Button
            color="primary"
            onClick={handleOnSubmit}
            disabled={isLoading}>Submit</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DeleteKnowledgeBase
