import React from 'react'
import { Sidebar, ExpansionPanel } from "@chatscope/chat-ui-kit-react"
import { connect } from 'react-redux'
import { useState } from 'react'
import { Button, Collapse, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Spinner, UncontrolledAlert } from 'reactstrap'
import SanitizeHTML from 'components/SanitizeHTML'

// redux actions
import { addMessage } from 'store/chat/actions'
import { getStore } from 'store/store/actions'
import InfoMessageFeature from '../InfoMessageFeature'
import InfoConversationContext from '../InfoConversationContext'

const TrainingRightSidebar = ({ conversationDetail, selectedMessage }) => {
  const { store, platform, object_context } = conversationDetail
  const [selectedPolicy, setSelectPolicy] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <Sidebar position="right">
      <div className='text-center'>
        {errorMessage &&
          <UncontrolledAlert color='danger'>
            {errorMessage}
          </UncontrolledAlert>
        }
        {isLoading &&
          <Spinner color='primary' />
        }
      </div>


      <Nav vertical justified pills className='p-3 font-size-14'>
        <NavItem className='mt-1'>
          <NavLink onClick={() => setCurrentTab(0)}
            active={currentTab === 0}>
            Context Conversation
          </NavLink>
        </NavItem>
        <NavItem className='mt-2'>
          <NavLink onClick={() => setCurrentTab(1)}
            active={currentTab === 1}>
            Store Policy
          </NavLink>
        </NavItem>
      </Nav>

      <Collapse isOpen={currentTab === 0} className='mt-3'>
        <InfoConversationContext
          converstationDetail={conversationDetail}
          message={selectedMessage} />

        <InfoMessageFeature message={selectedMessage} />
      </Collapse>

      <Collapse isOpen={currentTab === 1} className='mt-3'>
        {store &&
          <ListGroup className='mt-2 p-3' >
            {store.policies.map((policy, i) => (
              <ListGroupItem className='font-size-12' key={i}>
                <p><strong>{policy.policy_name}</strong></p>
                <p>
                  <a href="#"
                    onClick={() => {
                      setIsOpenModal(true)
                      setSelectPolicy(policy)
                    }}
                    className='mb-2'>View detail</a>
                </p>
                <div className='font-size-8'>
                  <i className='fa fa-tag' />
                  {" "} {policy.label}
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        }
      </Collapse>
      <Modal isOpen={isOpenModal} toggle={() => setIsOpenModal(!isOpenModal)}>
        {selectedPolicy &&
          <>
            <ModalHeader>
              {selectedPolicy.policy_name}
            </ModalHeader>
            <ModalBody>
              <SanitizeHTML html={selectedPolicy.information} />
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => setIsOpenModal(false)}>Close</Button>
            </ModalFooter>
          </>
        }
      </Modal>
    </Sidebar>)
}

const mapStateToProps = ({ Conversation, chat, Store }) => {
  return {
    conversationDetail: Conversation.conversationDetail,
    selectedMessage: chat.selectedMessage,
    shops: Store.shops,
  }
}

export default connect(
  mapStateToProps,
  {
    addMessage,
    getStore
  }
)(TrainingRightSidebar)
