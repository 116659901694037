import WebSocketClient from 'network/websocket/web_socket_client'
import {
  WEBSOCKET_SUCCESS,
  WEBSOCKET_REQUEST,
  WEBSOCKET_FAILURE,
  WEBSOCKET_CLOSED,
  RESET_WS_ERROR_COUNT,
  INCREMENT_WS_ERROR_COUNT,
} from "./actionTypes";
import store from 'store/index';
import { handleEvent } from "./websocket_handlers";
import { JWT_TOKEN_KEY } from "network/http/jwt-token-access";
import { API_URL } from 'network/http/api';

// WEBSOCKET CONFIG
const MAX_WEBSOCKET_FAILS = 7;

// redux func
const dispatch = store.dispatch;
const getState = store.getState;

export async function initialize(token_key = JWT_TOKEN_KEY) {
  if (!window.WebSocket) {
    console.log('Browser does not support websocket'); //eslint-disable-line no-console
    return;
  }

  let connUrl = process.env.REACT_APP_WEBSOCKET_URL
  const token = localStorage.getItem(token_key)
  WebSocketClient.addMessageListener((msg) => handleEvent(msg, dispatch));
  WebSocketClient.addFirstConnectListener(handleFirstConnect);
  WebSocketClient.addReconnectListener(() => reconnect(false));
  WebSocketClient.addMissedMessageListener(restart);
  WebSocketClient.addCloseListener(handleClose);
  WebSocketClient.initialize(connUrl, token);
}


function handleClose(failCount) {
  if (failCount > MAX_WEBSOCKET_FAILS) {
    dispatch({ type: 'critical', message: AnnouncementBarMessages.WEBSOCKET_PORT_ERROR });
  }
  dispatch({
    type: WEBSOCKET_FAILURE,
    timestamp: Date.now(),
  });
  incrementWsErrorCount()
}


export function close() {
  WebSocketClient.close();
}

function restart() {
  reconnect(false);

  // We fetch the client config again on the server restart.
  dispatch(getClientConfig());
}


function reconnectWebSocket() {
  close();
  initialize();
}

export function reconnect(includeWebSocket = true) {
  if (includeWebSocket) {
    reconnectWebSocket();
  }

  dispatch({
    type: WEBSOCKET_SUCCESS,
    timestamp: Date.now(),
  });

  resetWsErrorCount();
}



export function incrementWsErrorCount() {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INCREMENT_WS_ERROR_COUNT,
    });
  };
}

export function resetWsErrorCount() {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_WS_ERROR_COUNT,
    });
  };
}

function handleFirstConnect() {
  dispatch(
    {
      type: WEBSOCKET_SUCCESS,
      timestamp: Date.now(),
    },
  );
}
