import React from "react";
import { Badge } from "reactstrap";

const QnaBadges = ({ intents }) => {
  return intents.map((v, i) => (
    <Badge key={i} className='text-small p-1 m-2'>{v}</Badge>
  ))
}

export default QnaBadges
