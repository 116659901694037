// REGISTER FORM
export const SHOP_REGISTER_REQUEST = 'SHOP_REGISTER_REQUEST'
export const SHOP_REGISTER_SUCCES = 'SHOP_REGISTER_SUCCES'
export const SHOP_REGISTER_FAILED = 'SHOP_REGISTER_FAILED'
export const SHOP_REGISTER_RESET = 'SHOP_REGISTER_RESET'

export const GET_STORE_REQUEST = 'GET_STORE_REQUEST'
export const GET_STORE_SUCCES = 'GET_STORE_SUCCES'
export const GET_STORE_FAILED = 'GET_STORE_FAILED'

export const UPDATE_STORE_REQUEST = 'UPDATE_STORE_REQUEST'
export const UPDATE_STORE_SUCCES = 'UPDATE_STORE_SUCCES'
export const UPDATE_STORE_FAILED = 'UPDATE_STORE_FAILED'

export const UPDATE_STORE_POLICY_REQUEST = 'UPDATE_STORE_POLICY_REQUEST'
export const UPDATE_STORE_POLICY_SUCCES = 'UPDATE_STORE_POLICY_SUCCES'
export const UPDATE_STORE_POLICY_FAILED = 'UPDATE_STORE_POLICY_FAILED'

export const GET_STORE_DETAIL_REQUEST = 'GET_STORE_DETAIL_REQUEST'
export const GET_STORE_DETAIL_SUCCES = 'GET_STORE_DETAIL_SUCCES'
export const GET_STORE_DETAIL_FAILED = 'GET_STORE_DETAIL_FAILED'

export const GET_STORE_TRAIN_STATE_REQUEST = 'GET_STORE_TRAIN_STATE_REQUEST'
export const GET_STORE_TRAIN_STATE_SUCCES = 'GET_STORE_TRAIN_STATE_SUCCES'
export const GET_STORE_TRAIN_STATE_FAILED = 'GET_STORE_TRAIN_STATE_FAILED'

export const UPDATE_STORE_STATUS_REQUEST = 'UPDATE_STORE_STATUS_REQUEST'
