const menuList = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M2.25 12L11.204 3.04501C11.644 2.60601 12.356 2.60601 12.795 3.04501L21.75 12M4.5 9.75001V19.875C4.5 20.496 5.004 21 5.625 21H9.75V16.125C9.75 15.504 10.254 15 10.875 15H13.125C13.746 15 14.25 15.504 14.25 16.125V21H18.375C18.996 21 19.5 20.496 19.5 19.875V9.75001M8.25 21H16.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  },
  {
    name: "Accounts & Business",
    link: "/account",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
</svg>`,
    submenu: [
      {
        name: "Accounts",
        link: "/account",
      },
      // {
      //   name: "Profil Akun",
      //   link: "/profile-account",
      //   sublink: "service-knowledge",
      // },
      {
        name: "Daftar Akun Tokopedia",
        link: "/list-tokpedia",
      },
    ],
  },
  {
    name: "Finance",
    link: "/finance",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
  <path d="M2.25 18.75C7.58561 18.7457 12.898 19.4522 18.047 20.851C18.774 21.049 19.5 20.509 19.5 19.755V18.75M3.75 4.5V5.25C3.75 5.44891 3.67098 5.63968 3.53033 5.78033C3.38968 5.92098 3.19891 6 3 6H2.25M2.25 6V5.625C2.25 5.004 2.754 4.5 3.375 4.5H20.25M2.25 6V15M20.25 4.5V5.25C20.25 5.664 20.586 6 21 6H21.75M20.25 4.5H20.625C21.246 4.5 21.75 5.004 21.75 5.625V15.375C21.75 15.996 21.246 16.5 20.625 16.5H20.25M2.25 15V15.375C2.25 15.6734 2.36853 15.9595 2.5795 16.1705C2.79048 16.3815 3.07663 16.5 3.375 16.5H3.75M2.25 15H3C3.19891 15 3.38968 15.079 3.53033 15.2197C3.67098 15.3603 3.75 15.5511 3.75 15.75V16.5M20.25 16.5V15.75C20.25 15.5511 20.329 15.3603 20.4697 15.2197C20.6103 15.079 20.8011 15 21 15H21.75M20.25 16.5H3.75M15 10.5C15 11.2956 14.6839 12.0587 14.1213 12.6213C13.5587 13.1839 12.7956 13.5 12 13.5C11.2044 13.5 10.4413 13.1839 9.87868 12.6213C9.31607 12.0587 9 11.2956 9 10.5C9 9.70435 9.31607 8.94129 9.87868 8.37868C10.4413 7.81607 11.2044 7.5 12 7.5C12.7956 7.5 13.5587 7.81607 14.1213 8.37868C14.6839 8.94129 15 9.70435 15 10.5ZM18 10.5H18.008V10.508H18V10.5ZM6 10.5H6.008V10.508H6V10.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
    submenu: [
      {
        name: "Transaksi",
        link: "/finance",
        sublink: "synchronized-products",
      },
      {
        name: "Refund",
        link: "/refund",
        sublink: "service-knowledge",
      },
    ],
  },
  {
    name: "Keluar",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
  <path d="M5 22C4.44772 22 4 21.5523 4 21V3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V6H18V4H6V20H18V18H20V21C20 21.5523 19.5523 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z" />
</svg>`,
    iconBackup: "bxs-cloud",
    link: "/logout",
  },
];

export { menuList };
