import React, { useState } from "react";
import { Button } from "reactstrap";
import ProductKnowledgeModal from "./ProductKnowledgeModal";
import { put } from "network/http/api";

const EditKnowledgeBase = ({ data, refreshFunc }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOnSubmit = (answer, question) => {
    setIsLoading(false)
    const body = {
      'answer': answer,
      'question': question,
      'intent': 'product_guideline',
      'intent_score': 1,
    }
    put(`/admin/product/knowledge/${data['id']}`, body)
      .then(resp => {
        setIsLoading(false)
        setErrorMessage(null)
        toggle()
        refreshFunc()
      })
      .catch(err => {
        setIsLoading(false)
        console.log('error', err)
        setErrorMessage(err.detail)
      })
  }

  return (
    <>
      <Button color='' onClick={toggle}>
        <i className='bx bx-edit' style={{ paddingRight: 5 }} />Edit
      </Button>
      <ProductKnowledgeModal
        toggle={toggle}
        onSubmit={handleOnSubmit}
        isOpen={isOpen}
        isEditable={true}
        isLoading={isLoading}
        errorMessage={errorMessage}
        data={data}
        id={0}
        title="Edit product knowledge"
        refreshFunc={refreshFunc}
      />
    </>
  )
}

export default EditKnowledgeBase
