import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import logoRisa from "assets/images/logo-white-risa.svg";
import { menuList } from "./DefaultData";
// import logoRisaSmall from "assets/images/sidebar/risa_logo.svg";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

const NewSideBar = props => {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Cek jika lokasi berubah dan periksa apakah menu saat ini memiliki submenu
    menuList.forEach((item, idx) => {
      if (item.submenu) {
        item.submenu.forEach(subitem => {
          if (location.pathname.includes(subitem.link)) {
            setOpenSubMenu(idx);
          }
        });
      }
    });
  }, [location.pathname]);

  const handleSubMenuToggle = (index, link) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
    history.push(link);
  };

  return (
    <div className="new-sidebar-menu">
      <div className="navbar-brand-box">
        <Link to="/" className="logo">
          <img src={logoRisa} alt="" height="24" />
        </Link>
      </div>
      <div className="list-menu-sidebar">
        <ul className="list-menu">
          {menuList.map((item, idx) => {
            return (
              <li key={idx} className="list">
                <span
                  // to={item.link}
                  onClick={() => handleSubMenuToggle(idx, item.link)}
                  className={`list-link ${
                    location.pathname.includes(item.link) ||
                    (item.submenu &&
                      item.submenu.some(subItem =>
                        location.pathname.includes(subItem.link)
                      ))
                      ? "active"
                      : ""
                  } `}
                >
                  <span
                    className="icon"
                    dangerouslySetInnerHTML={{ __html: item.icon }}
                  />
                  {item.name}
                  {item.submenu && (
                    <div className="list-chevron">
                      {openSubMenu === idx ? (
                        <ChevronDownIcon className="list-chevron" />
                      ) : (
                        <ChevronRightIcon className="list-chevron" />
                      )}
                    </div>
                  )}
                </span>
                {item.submenu && openSubMenu === idx && (
                  <ul className="list-sub-menu">
                    {item.submenu.map((items, idxSub) => {
                      const isActive = location.pathname.includes(items.link);
                      return (
                        <li key={idxSub} className="list-sub">
                          <Link
                            to={items.link}
                            className={`list-link ${isActive ? "active" : ""}`}
                          >
                            {items.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default NewSideBar;
