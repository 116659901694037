import SanitizeHTML from "components/SanitizeHTML";
import { handleErrorRequest, post } from "network/http/api";
import React, { Fragment, useState } from "react"
import { connect } from "react-redux";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Tooltip } from 'reactstrap'
import { getMessages } from "store/actions";

const MessageGenerateAnswer = ({ message, getMessages, conversation }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const divId = `generate-answer${message.id}`

  const handleOnGenerateAnswer = () => {
    setIsLoading(true)
    post(`/admin/message/generate-answer/${message.id}`)
      .then(resp => {
        setIsLoading(false)
        setIsSuccess(true)
        setErrorMessage(null)
        getMessages(message.conversation_id)
      })
      .catch(err => {
        setIsLoading(false)
        setErrorMessage(handleErrorRequest(errr))
      })
  }

  return (
    <Fragment>
      <i id={divId}
        onClick={() => setIsOpen(true)}
        className="cursor-pointer bx bx-chat"></i>

      <Tooltip
        autohide={true}
        isOpen={tooltipOpen}
        target={divId}
        toggle={toggle}
      >
        Generate new answer
      </Tooltip>


      <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalHeader>are you sure want to generate new answer for this message?</ModalHeader>
        <ModalBody>
          <h4>Question:</h4>
          <div className="text-muted font-size-12">
            <SanitizeHTML html={message.message} />
          </div>

          <div className="m-3 text-center">
            {isLoading &&
              <Spinner />
            }
          </div>

          {errorMessage &&
            <Alert color="danger">
              {errorMessage}
            </Alert>
          }

          {isSuccess &&
            <Alert color="primary">
              Success generate answer
            </Alert>
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setIsOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            disabled={isLoading || isSuccess}
            onClick={handleOnGenerateAnswer} color="primary">
            Generate Answer
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default connect(null, {
  'getMessages': getMessages
})(MessageGenerateAnswer)
