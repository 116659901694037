import CommonEditor from 'components/Common/CommonEditor';
import SanitizeHTML from 'components/SanitizeHTML';
import moment from 'moment';
import { post } from 'network/http/api';
import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap';
import QnaSelectIntent from './QnaSelectIntent';

const QnaModal = ({ data, refreshList, isOpen, toggle }) => {
  if (!data) {
    return null;
  }

  const [selectedId, setSelectedId] = useState(data.id)
  const [text, setText] = useState(data.approved_answer || data.answers.join('<br/>'))
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedObs, setSelectedObs] = useState(0)
  const [primaryIntent, setPrimaryIntent] = useState(data.intents[0])
  const { addToast } = useToasts()

  const onChange = (e) => setText(e)

  useEffect(() => {
    setSelectedId(data.id)
    setText(data.approved_answer || data.answers.join("<br/>"))
    setPrimaryIntent(data.intents[0])

  }, [data])

  useEffect(() => {
    if (isOpen) {
      setText(data.answers.join("<br/>"))
      setPrimaryIntent(data.intents[0])
      setSelectedId(data.id)
      return
    }

    setSelectedObs(0)
  }, [isOpen])


  const handleOnSubmit = () => {
    let body = data
    body.approved_answer = text

    if (selectedObs === 0) {
      setErrorMessage("What your obersvation?")
      return
    }

    if (selectedObs == 1) {
      body.is_approved = true
      body.is_rejected = false
    } else if (selectedObs == 2) {
      body.is_rejected = true
      body.is_approved = false
    }

    body.intents = [primaryIntent]
    setIsLoading(true)
    post(`/admin/message/qna/observe/${data.id}`, body)
      .then(resp => {
        setIsLoading(false)
        refreshList()
        toggle()
        addToast(err.detail, {
          appearance: 'error',
          autoDismiss: true
        })
        setErrorMessage(null)
      })
      .catch(err => {
        setErrorMessage(err.detail)
        addToast(err.detail, {
          appearance: 'success',
          autoDismiss: true
        })
        setIsLoading(false)
      })
  }


  return (
    <>
      <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader>
          Submit the Question & Answer
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <small className="text-muted mb-2">
                date: {moment(data.created_at).format("DD/MM/YYYY hh:mm")}
              </small>

              <p className="font-weight-semibold">Question:</p>
              <ol>
                {data.questions.map((q, i) => (
                  <li key={i} >
                    <div className="text-wrapper">
                      <SanitizeHTML html={q} />
                    </div>
                  </li>
                ))}
              </ol>

              <p className="font-weight-semibold">Answer:</p>
              {isOpen &&
                <CommonEditor id={selectedId} text={text} onChange={(e) => onChange(e)} />
              }
            </CardBody>
            <CardFooter>
              <QnaSelectIntent onChange={(e) => setPrimaryIntent(e)} intent={primaryIntent} />
              <InputGroup>
                <InputGroupText>Your observation?</InputGroupText>
                <Input
                  onChange={(e) => setSelectedObs(e.target.value)}
                  value={selectedObs} type='select'>
                  <option value="">None</option>
                  <option value={1}>Approve</option>
                  <option value={2}>Reject</option>
                </Input>
              </InputGroup>
              {errorMessage &&
                <Alert color='danger' className='mt-2'>
                  {errorMessage}
                </Alert>
              }
            </CardFooter>
          </Card>
        </ModalBody>
        <ModalFooter>
          {isLoading &&
            <div className='text-center'>
              <Spinner type='grow' color='primary' />
            </div>
          }
          <Button
            color='warning'
            disabled={isLoading} onClick={toggle}>Cancel</Button>
          <Button disabled={isLoading}
            color='primary'
            onClick={() => handleOnSubmit()}>Submit</Button>

        </ModalFooter>
      </Modal>
    </>
  )
}

export default QnaModal;
