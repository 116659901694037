import React from "react"
import { Button, Col, Row, UncontrolledAlert } from "reactstrap"
import AllProductSyncStats from "./AllProductSyncStats"
import { useState } from "react"
import { post } from "network/http/api"

const ProductSyncVectorization = ({ store }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(null)

  const handleOnSubmitSync = () => {
    setIsLoading(true)
    const data = {
      'store_id': store.id
    }
    post(`/admin/product/sync-vectorization-product`, data)
      .then(resp => {
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        setIsError(err.detail)
      })
  }

  return (
    <>
      <Button outline disabled={isLoading} onClick={handleOnSubmitSync}>
        Vectorization Product
      </Button>
      {isError &&
        <UncontrolledAlert className="font-size-8">
          {isError}
        </UncontrolledAlert>
      }
    </>)
}

export default ProductSyncVectorization
