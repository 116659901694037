import React from 'react'

// import images
import bukalapak_logo from 'assets/images/bukalapak-logo.png';
import tokopedia_logo from 'assets/images/tokopedia-logo.png';
import shopee_logo from 'assets/images/shopee-logo.png';

const getImage = (platformName) => {
  switch (platformName) {
    case 'tokopedia':
      return tokopedia_logo
    case 'bukalapak':
      return bukalapak_logo
    case 'shopee':
      return shopee_logo;
    default:
      break;
  }
}

export default React.memo( function Child({platformName, style}){
  return (
    <img style={style} src={getImage(platformName)} alt={platformName} />
  )
})

