import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Alert,
  Button, Card, CardBody, CardTitle, Col,
  Input, InputGroup, InputGroupText,
  Label,
  Row,
  Spinner
} from 'reactstrap'
import { editUser } from 'store/user/actions';

class ProfileUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fullname: '',
      email: '',
      phoneNumber: '',
      username: '',
      status: '',
      errorInForm: '',
    }
  }

  componentDidMount() {
    const { user } = this.props
    if (user.id) {
      this.setState({
        status: user.status,
        fullname: user.fullname,
        email: user.email,
        username: user.username,
        phoneNumber: user.phone_number,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps == this.props) {
      return
    }

    const { user } = this.props
    if (user.id) {
      this.setState({
        status: user.status,
        fullname: user.fullname,
        username: user.username,
        email: user.email,
        phoneNumber: user.phone_number,
      })
    }
  }


  handleOnSubmit = () => {
    const { user, onEditProfile, isLoading } = this.props
    const { fullname, status, email, username, phoneNumber } = this.state;

    if (isLoading) {
      return
    }

    if (fullname == '') {
      this.setState({ errorInForm: 'Fullname is required' })
      return
    }

    if (email == '') {
      this.setState({ errorInForm: 'Email is required' })
      return
    }

    if (phoneNumber == '') {
      this.setState({ errorInForm: 'Phone number is required' })
      return
    }

    if (status == '') {
      this.setState({ errorInForm: 'Status is required' })
      return
    }

    const data = {
      fullname,
      username,
      email,
      status,
      phone_number: phoneNumber
    }

    onEditProfile(user.id, data)
  }

  render() {
    const { user, isLoading, error } = this.props;
    const { fullname, email, username, status, phoneNumber, errorInForm } = this.state;
    return (
      <Card>
        <CardBody>
          <CardTitle>User Detail</CardTitle>
          {isLoading &&
            <div className='text-center'>
              <Spinner />
            </div>
          }
          <div className="d-flex">
            <div className="align-self-center flex-1">
              <Row>
                <Col xs="12" className='mb-3'>
                  <Label>Fullname</Label>
                  <InputGroup>
                    <Input
                      placeholder='Fullname'
                      onChange={(e) => this.setState({ fullname: e.target.value })}
                      value={fullname} />
                  </InputGroup>
                </Col>
                <Col xs="12" className='mb-3'>
                  <Label>Username</Label>
                  <InputGroup>
                    <Input
                      placeholder='username'
                      onChange={(e) => this.setState({ username: e.target.value })}
                      value={username} />
                  </InputGroup>
                </Col>

                <Col xs="12" className='mb-3'>
                  <Label>Email</Label>
                  <InputGroup>
                    <Input type='text'
                      onChange={(e) => this.setState({ email: e.target.value })}
                      placeholder='Email'
                      value={email} />
                    <InputGroupText>
                      <i style={{ color: user.is_email_verified ? 'green' : 'grey' }} color='#000' className='fa fa-check' />
                    </InputGroupText>
                  </InputGroup>
                </Col>
                <Col xs="12" className='mb-3'>
                  <Label>Phone Number</Label>
                  <InputGroup>
                    <Input
                      onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                      placeholder='Phone number'
                      value={phoneNumber} />
                    <InputGroupText>
                      <i style={{ color: user.is_phone_number_verified ? 'green' : 'grey' }} color='#000' className='fa fa-check' />
                    </InputGroupText>
                  </InputGroup>
                </Col>
                <Col xs="12" className='mb-3'>
                  <Label>Status</Label>
                  <InputGroup>
                    <Input
                      onChange={(e) => this.setState({ status: e.target.value })}
                      placeholder='Status'
                      type='select'
                      value={status}>
                      <option value="pending">Pending</option>
                      <option value="active">Active</option>
                      <option value="deactivated">Deactivated</option>
                    </Input>
                  </InputGroup>
                </Col>
              </Row>
              {(error && error.detail) &&
                <Alert color='danger'>
                  {error.detail}
                </Alert>
              }
              {(errorInForm) &&
                <Alert color='danger'>
                  {errorInForm}
                </Alert>
              }
              {isLoading &&
                <div className='text-center'>
                  <Spinner type='grow' />
                </div>
              }
              <Button
                onClick={this.handleOnSubmit}
                disabled={isLoading}
                color='primary'>Save</Button>
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}


const mapStateToProps = ({ User }) => {
  return {
    'isLoading': User.isFetching,
    'user': User.userDetail,
    'error': User.error,
  }
};

export default withRouter(
  connect(mapStateToProps, {
    onEditProfile: editUser,
  })(ProfileUser)
);
