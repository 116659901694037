import { Message } from "@chatscope/chat-ui-kit-react";
import React, { memo, useEffect, useState } from "react";
import MessageBubbleFooter from "../Footer/MessageBubbleFooter";
import classNames from "classnames";

const MessageBubbleImage = ({ className, message, style, sender, questionMode }) => {

  if (message.attachment_id < 1) {
    return null
  }

  if (!message.attachment) {
    return null
  }

  if (message.type !== 'image') {
    return null
  }

  return (
    <Message.CustomContent key={message.id}
      className={className}
      style={style}
      model={{
        message: message.message,
        sender: sender.fullname,
        position: "normal"
      }}
    >
      <img
        src={message.attachment.image_url}
        className="image-responsive" />

      <Message.Footer>
        <MessageBubbleFooter
          questionMode={questionMode}
          message={message} />
      </Message.Footer>
    </Message.CustomContent>
  )
}

export default memo(MessageBubbleImage)
