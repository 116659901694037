import { POST_ADD_MESSAGE } from "store/chat/actionTypes"
import * as actionTypes from "./actionTypes"

const INIT_STATE = {
  dataset: {},
  leaderBoard: [],
  isRequested: false,
  error: null,
}

const DatasetTrain = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_LEADER_BOARD_REQUEST:
    case actionTypes.LOAD_DATASET:
    case actionTypes.GENERATE_ANSWER_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: null,
      }
    case actionTypes.GET_LEADER_BOARD_SUCCESS:
      return {
        ...state,
        isRequested: false,
        leaderBoard: action.payload.items,
        totalLeaderBoard: action.payload.total_item,
        error: null
      }
    case actionTypes.GET_LEADER_BOARD_FAILED:
      return {
        ...state,
        isRequested: false,
        error: action.payload,
      }
    case actionTypes.LOAD_DATASET_SUCCESS:
      return {
        ...state,
        dataset: action.payload || {},
        isRequested: false,
        error: {},
      }
    case POST_ADD_MESSAGE:
      return {
        ...state,
        isRequested: !(action.payload.generated_by_ai === true),
      }
    case actionTypes.GENERATE_ANSWER_SUCCESS:
      return {
        ...state,
        dataset: action.payload,
        isRequested: true,
        error: {}
      }
    case actionTypes.SUBMIT_DATASET_SUCCESS:
      return {
        ...state,
        dataset: {},
        isRequested: false,
        error: {},
      }
    case actionTypes.LOAD_DATASET_FAILED:
      return {
        ...state,
        error: action.payload,
        isRequested: false,
      }
    default:
      return state
  }
}

export default DatasetTrain
