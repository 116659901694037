import {
  GET_PROFILE,
  PROFILE_ERROR,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_DETAIL,
  GET_PROFILE_DETAIL_SUCCESS} from "./actionTypes"

const initialState = {
  user: {},
  profile: {},
  isLoading: false,
  error: "",
  success: "",
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
    case GET_PROFILE_DETAIL:
    case EDIT_PROFILE:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case GET_PROFILE_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profile: action.payload,
      }
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_PROFILE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        user: action.payload,
        success: action.payload.fullname,
      }
      break
    case PROFILE_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: action.payload }
      break
    case RESET_PROFILE_FLAG :
      state = { ...state, success: null }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
