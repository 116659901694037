import { call, put, takeEvery } from "redux-saga/effects"

// Redux States
import { GET_QNA_DICTIONARY_PRODUCT, DELETE_QNA_DICTIONARY, FETCH_QNA_DICTIONARY_REQUEST } from "./actionTypes"
import * as actions from "./actions"

// API endpoint
import * as api from './api'

function* onGetQNADictionaryProduct({ payload }) {
  try {
    const response = yield call(api.getQnaDictionary, payload)
    yield put(actions.getQnaDictionarySuccess(response))
  } catch (error) {
    yield put(actions.getQnaDictionaryFail(error))
  }
}

function* onDeleteQNADictionaryProduct({ payload }) {
  try {
    const response = yield call(api.getQnaDictionary, payload)
    yield put(actions.getQnaDictionarySuccess(response))
  } catch (error) {
    yield put(actions.getQnaDictionaryFail(error))
  }
}

function* onFetchQNADictionary({ payload: { page, limit, query } }) {
  try {
    const response = yield call(api.fetchQnaDictionary, page, limit, query)
    yield put(actions.fetchQnaDictionarySuccess(response))
  } catch (error) {
    yield put(actions.fetchQnaDictionaryError(error))
  }
}

function* qnaDictionarySaga() {
  yield takeEvery(FETCH_QNA_DICTIONARY_REQUEST, onFetchQNADictionary)
  yield takeEvery(GET_QNA_DICTIONARY_PRODUCT, onGetQNADictionaryProduct)
  yield takeEvery(DELETE_QNA_DICTIONARY, onDeleteQNADictionaryProduct)
}

export default qnaDictionarySaga
