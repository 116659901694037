import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

class MiniCards extends Component {
  render() {
    const { rowSize, to, iconClass } = this.props;
    return (
      <React.Fragment>
        <Col md={rowSize || 12} lg={rowSize || 12}>
          <Card className="mini-stats-wid p-1">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <Link to={to} className="text-muted fw-medium mb-2">
                    {this.props.title}
                  </Link>

                  <h4 className="mb-0">
                    <Link to={to}>
                      {this.props.text}
                    </Link>
                  </h4>
                </div>

                {iconClass &&
                  <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                    <span className="avatar-title">
                      <i
                        className={"bx " + iconClass + " font-size-24"}
                      />
                    </span>
                  </div>
                }
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default MiniCards;
