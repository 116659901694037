import SanitizeHTML from 'components/SanitizeHTML';
import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from "reactstrap"

const ProductDetailDescription = ({ product }) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className="mt-5">
      <h5 className="mb-3">Specifications :</h5>
      <div className="table-responsive">
        <Table className="table mb-0 table-bordered">
          <tbody>
            {product.details &&
              product.details.map(
                (specification, i) => (
                  <tr key={i}>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      {specification.title}
                    </th>
                    <td>
                      {specification.title.toLowerCase() == 'description' &&
                        <SanitizeHTML html={specification.text.replaceAll("\n", "<br/>")} />
                      }
                      {specification.title.toLowerCase() != 'description' &&
                        specification.text}</td>
                  </tr>
                )
              )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ProductDetailDescription;
