const { get, put, del } = require("network/http/api")

export const getQnaDictionary = (product_id) => {
  return get(`/qna-dictionary/product/${product_id}`)
}

export const deleteQnaDictionary = (id) => {
  return del(`/qna-dictionary/${id}`)
}

export const fetchQnaDictionary = (page = 1, limit = 10, query = '') => {
  let param = `page=${page}&&limit=${limit}`
  if (query != '') {
    param = `${param}&&${query}`
  }
  return get(`/admin/message/qna?${param}`)
}
