import React from 'react'
import { Sidebar, ExpansionPanel } from "@chatscope/chat-ui-kit-react"
import { connect } from 'react-redux'
import InfoMessageFeature from './InfoMessageFeature'
import ChatTicketList from './ChatTicketList'
import InfoConversationContext from './InfoConversationContext'
import { Link } from 'react-router-dom'
import TeacherModeInfo from './Teacher/TeacherModeInfo'

const ChatRightSidebar = ({ conversationDetail, selectedMessage }) => {
  const { store, platform } = conversationDetail

  return (
    <Sidebar position="right">

      <TeacherModeInfo conversation={conversationDetail} />

      {store &&
        <ExpansionPanel open title="STORE">
          <p><strong>NAME:</strong> <Link target={'_blank'} to={`/store/${store.id}`}>{store.store_name}</Link> </p>
          <p><strong>URL:</strong> <a href={store.store_url}>{store.store_url}</a></p>
          <p><strong>PLATFORM:</strong> {store.platform.platform_name}</p>
        </ExpansionPanel>
      }

      <InfoConversationContext
        converstationDetail={conversationDetail}
        message={selectedMessage} />


      <InfoMessageFeature message={selectedMessage} />

      {conversationDetail.id &&
        <ChatTicketList conversationId={conversationDetail.id} />
      }

    </Sidebar>)
}

const mapStateToProps = ({ Conversation, chat }) => {
  return {
    conversationDetail: Conversation.conversationDetail,
    selectedMessage: chat.selectedMessage,
  }
}

export default connect(
  mapStateToProps,
  null
)(ChatRightSidebar)
