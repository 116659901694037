import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Alert, Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledAlert } from 'reactstrap'
import { updateStoreStatusAction, updateStoreSuccess } from 'store/store/actions'
import { updateStoreStatus } from 'store/store/api'

const StoreIntegrationStatus = ({ store, updateStoreSuccess }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [storeStatus, setStoreStatus] = useState(store.authorize_status)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [disableEmailNotification, setDisableEmailNotification] = useState(false)

  const toggleModal = () => setIsOpen(!isOpen)

  const handleOnSubmit = () => {
    if (isLoading) return

    setIsLoading(true)
    updateStoreStatus(store.id, storeStatus, disableEmailNotification)
      .then(resp => {
        setIsLoading(false)
        setErrorMessage(null)
        updateStoreSuccess(resp)
        setIsOpen(false)
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
        setErrorMessage(error.detail)
      })
  }

  return (
    <Fragment>
      <Row className='mt-1'>
        <Col>
          <strong>Integration Status : </strong>
          {store.authorize_status.toLocaleUpperCase()}
        </Col>
        <Col xs="4">
          <Button outline onClick={() => toggleModal()}>
            <i className='bx bx-edit' />
            Update Status
          </Button>
        </Col>
      </Row>

      <br />
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader>Are sure want to change?</ModalHeader>
        <ModalBody>
          <Input type="select" value={storeStatus} onChange={(e) => setStoreStatus(e.target.value)}>
            <option value="register">Register</option>
            <option value="review_policy">Review Policy</option>
            <option value="platform_review">Platform Review</option>
            <option value="completed">Completed</option>
          </Input>

          <Input type="switch" checked={disableEmailNotification} onClick={() => setDisableEmailNotification(!disableEmailNotification)} />
          {!disableEmailNotification &&
            <Alert className='mt-2 mb-2' color='info'>
              Sistem akan mengirimkan email ke user ketika anda mengubah status ini.
            </Alert>
          }

          {isLoading &&
            <div className='text-center'>
              <Spinner type="grow" color='primary' />
            </div>
          }

          {errorMessage &&
            <UncontrolledAlert color='danger'>
              {errorMessage}
            </UncontrolledAlert>
          }

          <ModalFooter>
            <Button disabled={isLoading} onClick={toggleModal} color='danger'>Cancel</Button>
            <Button disabled={isLoading} onClick={handleOnSubmit} color='primary'>Submit</Button>
          </ModalFooter>
        </ModalBody>
      </Modal>

    </Fragment>
  )
}

const mapStateToProps = ({ Store }) => {
  return {
    error: Store.error,
    ...Store.update,
  }
}

export default connect(mapStateToProps, {
  updateStoreStatusAction,
  updateStoreSuccess,
})(StoreIntegrationStatus)
