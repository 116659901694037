import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import * as actions from "./actions"

import * as api from './api'
import { USER_LATEST_LOAD_DATASET, LOAD_DATASET, GENERATE_ANSWER_REQUEST, SUBMIT_DATASET_SUCCESS, GET_LEADER_BOARD_REQUEST } from "./actionTypes"
import { getConversationDetailSuccess, getMessages, resetMessageState } from "store/actions"

function* onLoadDataset() {
  try {
    const response = yield call(api.trainLoadDataset)
    const { conversation_detail, dataset } = response
    yield put(resetMessageState())
    yield put(actions.loadDatasetSuccess(dataset))
    yield put(getConversationDetailSuccess(conversation_detail))
    yield put(getMessages(conversation_detail.conversation.id, 0))
  } catch (error) {
    yield put(actions.loadDatasetFailed(error))
  }
}

function* onUserLatestDataset() {
  try {
    const response = yield call(api.getUserLatestDataset)
    const { conversation_detail, dataset } = response
    yield put(resetMessageState())
    yield put(actions.loadDatasetSuccess(dataset || {}))
    yield put(getConversationDetailSuccess(conversation_detail || {}))
    if (conversation_detail) {
      yield put(getMessages(conversation_detail.conversation.id, 0))
    }
  } catch (error) {
    yield put(actions.loadDatasetFailed(error))
  }
}

function* onGenerateAnswer({ payload: { id } }) {
  try {
    const response = yield call(api.generateAnswer, id)
    yield put(actions.loadDatasetSuccess(response))
  } catch (error) {
    yield put(actions.loadDatasetFailed(error))
  }
}

function* onSubmitDatasetSuccess() {
  try {
    yield put(resetMessageState())
    yield put(getConversationDetailSuccess({}))
  } catch (error) {
    console.error(error)
    yield put(actions.loadDatasetFailed(error))
  }
}


function* onGetLeaderBoard({ payload: { page, limit } }) {
  try {
    const response = yield call(api.getLeaderBoard, page, limit)
    yield put(actions.getLeaderBoardSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getLeaderBoardFailed(error))
  }
}



function* datasetTrainSaga() {
  yield takeEvery(LOAD_DATASET, onLoadDataset)
  yield takeEvery(USER_LATEST_LOAD_DATASET, onUserLatestDataset)
  yield takeEvery(GENERATE_ANSWER_REQUEST, onGenerateAnswer)
  yield takeEvery(SUBMIT_DATASET_SUCCESS, onSubmitDatasetSuccess)
  yield takeEvery(GET_LEADER_BOARD_REQUEST, onGetLeaderBoard)
}

export default datasetTrainSaga
