import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";

//Import Images
import maintanence from "../../assets/images/coming-soon.svg";
import NewTicketModal from "./NewTicketModal";

class EmptyTicket extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="my-5 pt-sm-5">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center">
                  <Row className="justify-content-center mt-5">
                    <Col sm="4">
                      <div className="maintenance-img">
                        <img
                          src={maintanence}
                          alt=""
                          className="img-fluid mx-auto d-block"
                        />
                      </div>
                    </Col>
                  </Row>
                  <h4 className="mt-5">Let&#39;s create new Ticket</h4>
                  <NewTicketModal />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EmptyTicket;
