import * as types from './actionTypes'

export const fetchUser = (page = 1, limit = 10) => ({
  type: types.FETCH_USER_REQUEST,
  payload: { page, limit }
})


export const fetchUserSuccess = (payload) => ({
  type: types.FETCH_USER_SUCCESS,
  payload: payload
})


export const fetchUserFailed = (error) => ({
  type: types.FETCH_USER_FAILED,
  payload: payload
})

export const getUser = (id) => ({
  type: types.GET_USER_REQUEST,
  payload: id
})


export const getUserSuccess = (payload) => ({
  type: types.GET_USER_SUCCESS,
  payload: payload
})


export const getUserFailed = (error) => ({
  type: types.GET_USER_FAILED,
  payload: payload
})


export const editUser = (id, data) => ({
  type: types.EDIT_USER,
  payload: { id, data }
})


export const editUserSuccess = (payload) => ({
  type: types.EDIT_USER_SUCCESS,
  payload: payload
})


export const editUserFailed = (error) => ({
  type: types.EDIT_USER_FAILED,
  payload: error
})
