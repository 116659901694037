const DUMMY_DATA = [
  {
    id: 0,
    created_at: "2024-02-17T06:42:20.823Z",
    updated_at: "2024-02-17T06:42:20.823Z",
    company_id: 0,
    billing_id: 0,
    owner_id: 0,
    plan_id: 0,
    plan_name: "FREE",
    fullname: "Johan",
    invoice_number: "INV/10/2002",
    amount: 0,
    transaction_fee: 0,
    total: 0,
    plan_price: 0,
    status: "unpaid",
    description: "",
    payment_url: "string",
    payment_gateway: "",
    payment_gateway_reference_id: "string",
    due_date: "2024-02-17T06:42:20.823Z",
    items: [
      {
        name: "string",
        price: 0,
        quantity: 0,
      },
    ],
    payment_temporary_token: "string",
  },
  {
    id: 0,
    created_at: "2024-02-17T06:42:20.823Z",
    updated_at: "2024-02-17T06:42:20.823Z",
    company_id: 0,
    billing_id: 0,
    owner_id: 0,
    plan_id: 0,
    plan_name: "PRO",
    fullname: "Alex",
    invoice_number: "INV/10/2002",
    amount: 0,
    transaction_fee: 0,
    total: 0,
    plan_price: 0,
    status: "unpaid",
    description: "",
    payment_url: "string",
    payment_gateway: "",
    payment_gateway_reference_id: "string",
    due_date: "2024-02-17T06:42:20.823Z",
    items: [
      {
        name: "string",
        price: 0,
        quantity: 0,
      },
    ],
    payment_temporary_token: "string",
  },
];

const STATUS_STYLES = {
  paid: {
    backgroundColor: "#29CCB1",
    color: "#1A806F",
  },
  expired: {
    backgroundColor: "#FCEBEB",
    color: "#E11A1A",
  },
  refund: {
    backgroundColor: "#F8D7DA",
    color: "#E3052D",
  },
  unpaid: {
    backgroundColor: "#F0F0F0",
    color: "#949494",
  },
};

const handleStatusName = paramsStatus => {
  switch (paramsStatus) {
    case "paid":
      return "Sukses";
    case "expired":
      return "Gagal";
    case "refund":
      return "Refund";
    case "unpaid":
      return "Menunggu";
    default:
      return "Unknown";
  }
};

const LIST_FILTER_STATUS = [
  { name: "Sukses", value: "success" },
  { name: "Gagal", value: "failed" },
  { name: "Menunggu", value: "waiting" },
];

export { DUMMY_DATA, STATUS_STYLES, LIST_FILTER_STATUS, handleStatusName };
