const { get, post, put, del } = require("network/http/api")

export const fetchStore = (page = 1, limit = 10) => {
  return get(`/admin/account/store?page=${page}&&limit=${limit}`)
}

export const fetchStoreDetail = (id) => {
  return get('/admin/account/store/' + id)
}

export const updateStorePolicy = (storeId, policy) => {
  return put(`/store/${storeId}/policy`, policy)
}

export const updateStore = (storeId, shop) => {
  return put(`/store/${storeId}`, shop)
}

export const updateStoreStatus = (storeId, status, emailNotifDisabled) => {
  const data = {
    'store_id': storeId,
    'status': status,
    'is_email_notification_disabled': emailNotifDisabled
  }
  return put(`/admin/account/update-store-status`, data)
}
