import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const menuList = [
  {
    icon: "bx bx-home-circle",
    path: "/dashboard",
    name: "Dashboard"
  },
  {
    path: "/chat",
    icon: "bx bx-chat chat",
    name: "All Chat",
    submenu: [
      {
        path: "/chat",
        name: 'Chat'
      },
      {
        path: "/chat-simulation",
        name: 'Simulation'
      },
    ]
  },
  {
    path: "/knowledge-base",
    icon: "bx bx-book",
    name: "KN Base",
    submenu: [
      {
        path: "/knowledge-base/qna",
        name: "Q & A"
      },
    ]
  },
  {
    path: "/account",
    icon: "bx bx-user",
    name: "Account",
    submenu: [
      {
        path: "/account/user",
        name: 'Users'
      },
      {
        path: "/account/store",
        name: 'Store'
      },
      {
        path: "/account/company",
        name: 'Company'
      },
    ]
  },
  {
    path: "/billing",
    icon: "bx bx-receipt",
    name: "Billing",
    submenu: [
      {
        path: "/billing",
        name: 'Billing User'
      },
      {
        path: "/billing/invoice",
        name: 'Invoice'
      },
    ]
  },

  {
    path: "/dataset",
    icon: "bx bx-dock-bottom",
    name: "Dataset",
    submenu: [
      {
        path: "/dataset-train",
        name: "Train",
      },
      {
        path: "/dataset-train/leader-board",
        name: "Leader Board",
      },
      {
        path: "/dataset-train/qna",
        name: "Q & A",
      }
    ]
  }
]

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    this.initMenu();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {menuList.map((menu, id) => {
                const li = (<Link to={menu.path} className={menu.submenu ? 'has-arrow' : ''}>
                  <i className={menu.icon} />
                  <span>{menu.name}</span>
                </Link>)

                let subMenu = null
                if (menu.submenu) {
                  subMenu = (<ul className="sub-menu" aria-expanded="false">
                    {menu.submenu.map(submenu => (
                      <li key={submenu.path}>
                        <Link to={submenu.path} className={submenu.name.toLowerCase()}>
                          <span>{submenu.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>)
                }

                return (<li key={id}>
                  {li}
                  {subMenu}
                </li>)
              })}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
