import { handleErrorRequest, post } from "network/http/api"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Alert, Spinner, UncontrolledAlert } from "reactstrap"
import { updateConversationSuccess } from "store/actions"

const JoinConversation = ({ conversation, onUpdateConversationSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleOnClick = () => {
    setIsLoading(true)
    post(`/admin/conversation/join/${conversation.id}`)
      .then(resp => {
        onUpdateConversationSuccess(resp)
        setErrorMessage(null)
      })
      .catch(err => {
        const errMsg = handleErrorRequest(err)
        setErrorMessage(errMsg)
        console.error(errMsg)
        setIsLoading(false)
      })
  }

  return (
    <div>
      {isLoading &&
        <div className="text-center">
          <Spinner type="grow" color="primary" />
        </div>
      }
      {errorMessage &&
        <div className="text-center">
          <UncontrolledAlert color="danger">
            {errorMessage}
          </UncontrolledAlert>
        </div>
      }

      <button disabled={isLoading} onClick={handleOnClick} type="button"
        className="p-2 btn btn-primary btn-lg btn-block btn-full-size">
        Join Conversation
      </button>

    </div>
  )
}

export default connect(null, {
  'onUpdateConversationSuccess': updateConversationSuccess,
})(JoinConversation)
