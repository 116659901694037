import React from 'react'
import { Sidebar } from "@chatscope/chat-ui-kit-react"
import { connect } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'
import { get, handleErrorRequest } from 'network/http/api'
import { Button, Collapse, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Spinner, UncontrolledAlert } from 'reactstrap'
import SanitizeHTML from 'components/SanitizeHTML'

// redux actions
import { addMessage } from 'store/chat/actions'
import { getStore } from 'store/store/actions'
import SelectStoreInput from 'components/SelectStoreInput'

const SimulationRightSidebar = ({ conversationDetail, addMessage, onChangeStore, currentStore }) => {
  const { store, platform, object_context } = conversationDetail
  const [activeProduct, setActiveProduct] = useState(object_context ? object_context.product_id : 0)
  const [products, setProducts] = useState([])
  const [selectedPolicy, setSelectPolicy] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    const { store, platform } = conversationDetail
    if (!store) {
      return
    }
    setIsLoading(true)
    get(`/admin/product/store/${store.id}`)
      .then(resp => {
        setProducts(resp.products || [])
        setIsLoading(false)
        setErrorMessage(null)
      })
      .catch(err => {
        let errMsg = handleErrorRequest(err)
        setIsLoading(false)
        setErrorMessage(errMsg)
      })
  }, [conversationDetail])

  const selectProductContext = (product) => {
    const { id } = conversationDetail
    const payload = {
      company_id: product.company_id,
      store_id: product.store_id,
      message: `<a href="${product.product_url}">${product.name}</a>`,
      type: "product",
      conversation_id: id,
      attachment_id: product.pl_product_id,
      role: "buyer",
      is_question: true
    }

    addMessage(payload)
    setActiveProduct(product.id)
    scrollToBottom()
  }

  return (
    <Sidebar position="right" className='p-3'>
      <div className='text-center'>
        {errorMessage &&
          <UncontrolledAlert color='danger'>
            {errorMessage}
          </UncontrolledAlert>
        }
        {isLoading &&
          <Spinner color='primary' />
        }
      </div>
      <SelectStoreInput onChange={onChangeStore} value={currentStore} />
      <Nav vertical justified pills>
        <NavItem active>
          <NavLink onClick={() => setCurrentTab(0)} active={currentTab === 0}>
            Product Demo
          </NavLink>
        </NavItem>
        <NavItem className='mt-2'>
          <NavLink onClick={() => setCurrentTab(1)} active={currentTab === 1}>
            Store Policy
          </NavLink>
        </NavItem>
      </Nav>

      <Collapse isOpen={currentTab === 0} className='mt-3'>
        <ListGroup className='mt-2'>
          {products.map(product => (
            <ListGroupItem
              color='secondary'
              active={product.id === activeProduct}
              style={{ cursor: 'pointer', }}
              className='font-size-12 cursor-pointer'
              key={product.id}>
              <span to="#" onClick={() => selectProductContext(product)}>
                {product.name}
              </span>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Collapse>
      <Collapse isOpen={currentTab === 1} className='mt-3'>
        {store &&
          <ListGroup className='mt-2'>
            {store.policies.map((policy, i) => (
              <ListGroupItem className='font-size-12' key={i}>
                <p><strong>{policy.policy_name}</strong></p>
                <p>
                  <a href="#"
                    onClick={() => {
                      setIsOpenModal(true)
                      setSelectPolicy(policy)
                    }}
                    className='mb-2'>View detail</a>
                </p>
                <div className='font-size-8'>
                  <i className='fa fa-tag' />
                  {" "} {policy.label}
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        }
      </Collapse>
      <Modal isOpen={isOpenModal} toggle={() => setIsOpenModal(!isOpenModal)}>
        {selectedPolicy &&
          <>
            <ModalHeader>
              {selectedPolicy.policy_name}
            </ModalHeader>
            <ModalBody>
              <SanitizeHTML html={selectedPolicy.information} />
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => setIsOpenModal(false)}>Close</Button>
            </ModalFooter>
          </>
        }
      </Modal>
    </Sidebar>)
}

const mapStateToProps = ({ Conversation, chat }) => {
  return {
    conversationDetail: Conversation.conversationDetail,
    selectedMessage: chat.selectedMessage,
  }
}

export default connect(
  mapStateToProps,
  {
    addMessage,
    getStore
  }
)(SimulationRightSidebar)
