import { post } from 'network/http/api'
import React, { useState } from 'react'
import { Button, UncontrolledAlert } from 'reactstrap'

const GroupingQNA = ({ refreshList }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleOnSynchornize = () => {
    setIsLoading(true)
    post(`/admin/message/qna-grouping`)
      .then(resp => {
        setIsLoading(false)
        setErrorMessage(null)
        refreshList()
      })
      .catch(err => {
        setIsLoading(false)
        setErrorMessage(err.detail)
      })
  }

  return (
    <>
      {errorMessage &&
        <UncontrolledAlert color='danger'>
          {errorMessage}
        </UncontrolledAlert>
      }
      <Button onClick={() => handleOnSynchornize()} disabled={isLoading}>
        Synchronize
      </Button>
    </>
  )
}

export default GroupingQNA
