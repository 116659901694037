import { post } from 'network/http/api'
import React, { useState } from 'react'
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const SynchronizeChat = ({ conversation }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  const handleOnSubmit = () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    const data = {
      'conversation_id': conversation.id,
    }

    post(`/admin/conversation/sync-platform`, data)
      .then(resp => {
        setIsLoading(false)
        setOpen(false)
        window.location.reload()
      })
      .catch(err => {
        setIsLoading(false)
        setErrorMessage(err.detail)
      })
  }


  return (
    <>
      {!conversation.is_teacher_take_over &&
        <Button
          onClick={toggle}
          style={{ marginRight: 20 }}
          className='mr-2' outline >Sync</Button>
      }

      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={() => setOpen(!open)}>
          Synchronize with platform
        </ModalHeader>
        <ModalBody>
          <p>Synchornize this conversation with platform</p>
          {errorMessage &&
            <Alert>{errorMessage}</Alert>
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleOnSubmit} disabled={isLoading} color='primary'>
            Synchronize
          </Button>
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default SynchronizeChat
