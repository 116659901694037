import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap"
import { isEmpty } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import actions
import { getProductDetail } from "store/product/actions"
import ProductCardDetail from "components/Product/ProductCardDetail"

class ProductDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      stock: 0,
      product: {},
      pictures: [],
      productId: 0,
    }
  }

  componentDidMount() {
    const {
      match: { params },
      onGetProductDetail,
    } = this.props
    if (params && params.id) {
      onGetProductDetail(params.id)
    }

    const { product } = this.props;
    if (product.id) {
      const { product } = this.props
      const { parent, variants, options } = product
      this.setState({
        productId: parent.id,
        parent: parent,
        product: parent,
        options: options,
        variants: variants,
        stock: parent.stock,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps == this.props) {
      return
    }

    const {
      match: { params },
    } = this.props


    if (this.state.productId != params.id) {
      const { product } = this.props
      const { parent, variants, options } = product
      if (!parent) {
        return
      }

      this.setState({
        productId: parent.id,
        parent: parent,
        product: parent,
        options: options,
        variants: variants,
        stock: parent.stock,
      })
    }
  }

  handleOnUpdateVariant = (variant) => {
    const { options } = this.state;
    let selectedOption = options[variant]
    if (!selectedOption) {
      return
    }

    this.setState({
      'stock': selectedOption.product.stock,
      'product': selectedOption.product,
    })
  }

  render() {
    const { product, stock, variants, parent } = this.state
    const { isFetching } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Ecommerce" breadcrumbItem="Product Details" />
            {isFetching &&
              <div className='text-center'>
                <Spinner type="grow" color="primary" />
              </div>
            }
            {!isEmpty(product) && (
              <Row>
                <Col>
                  <ProductCardDetail
                    variants={variants}
                    parent={parent}
                    onChangeVariant={this.handleOnUpdateVariant}
                    product={product} />
                </Col>
              </Row>
            )}
            {/* <RecentProducts recentProducts={product.recentProducts} /> */}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProductDetail.propTypes = {
  product: PropTypes.object,
  match: PropTypes.object,
  onGetProductDetail: PropTypes.func,
}

const mapStateToProps = ({ Product }) => ({
  product: Product.product,
  isFetching: Product.productIsFetching
})

const mapDispatchToProps = dispatch => ({
  onGetProductDetail: id => dispatch(getProductDetail(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetail)
