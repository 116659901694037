import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

const DropdownSelected = ({
  title,
  typeTitle = "bold",
  data,
  selected = null,
  setSelected,
  placeholder,
  error,
}) => {
  const onSelectedData = params => {
    setSelected(params);
  };

  return (
    <div className="dropdown-costume-selected">
      {title && (
        <span className={typeTitle === "light" ? "title-light" : "title"}>
          {title}
        </span>
      )}
      <Listbox value={selected} onChange={onSelectedData}>
        <div className="dropdown-selected">
          <Listbox.Button
            className={`dropdown-selected-button ${
              error ? "error-selected" : ""
            }`}
          >
            <span
              className={`dropdown-selected-title ${selected ? "" : "default"}`}
            >
              {selected
                ? selected.name
                  ? selected.name
                  : selected
                : placeholder
                ? placeholder
                : `Pilih ${title}`}
            </span>
            <span className="dropdown-selected-icon">
              <ChevronDownIcon className="icon" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Listbox.Options className="dropdown-selected-list">
            {data.map((item, itemIdx) => (
              <Listbox.Option
                key={itemIdx}
                value={item}
                as={Fragment}
                className="list"
              >
                <li
                  className={`list-title ${
                    selected?.name === item.name ? "active" : ""
                  }`}
                >
                  {selected?.name === item.name && (
                    <span className="list-icon">
                      <CheckIcon className="icon" />
                    </span>
                  )}
                  {item.name}
                </li>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
};

export default DropdownSelected;
