import React, { useEffect, useCallback, useState } from 'react'
import {
  Avatar,
  Conversation,
  Sidebar,
  ConversationList,
  Status,
} from "@chatscope/chat-ui-kit-react";
import { connect } from 'react-redux';
import { find } from 'lodash';

// action reducer
import { getConversation, getConversationDetailSuccess } from 'store/actions'
import avatarDefault from 'assets/images/user-avatar-default.svg'
import PlatformLogo from 'components/Channel/PlatformLogo';
import { useQuery } from 'components/URLQuery';
import SelectPlatformInput from 'components/SelectPlatformInput';
import SelectStoreInput from 'components/SelectStoreInput';
import { Button } from 'reactstrap';

const ChatLeftSidebarUI = ({
  conversations,
  users,
  platforms,
  getConversation,
  fetchingConversation,
  history,
  totalConversation,
  conversationDetail }) => {

  // param query
  let urlQuery = useQuery()
  let platformId = urlQuery.get('platform_id') || 0
  let storeId = urlQuery.get('store_id') || 0

  // set state
  const [page, setPage] = useState(1)
  const [isLoadMore, setIsLoadMore] = useState(fetchingConversation);
  const [selectedPlatform, setSelectedPlatform] = useState(platformId)
  const [selectedStore, setSelectedStore] = useState(storeId)

  useEffect(() => {
    getConversation(page, selectedPlatform, selectedStore);
  }, [])

  useEffect(() => {
    setIsLoadMore(fetchingConversation)
  }, [fetchingConversation])

  const handleOnClick = (conv) => {
    history.push({
      pathname: `/chat/${conv.id}`,
      search: `?platform_id=${selectedPlatform}&store_id=${selectedStore}`
    })
  }

  useEffect(() => {
    let platformId = urlQuery.get('platform_id')
    let storeId = urlQuery.get('store_id')
    if (platformId) {
      setSelectedPlatform(platformId)
    }

    if (storeId) {
      setSelectedStore(storeId)
    }

  }, [urlQuery])

  const handleLoadMore = useCallback(() => {
    if (totalConversation <= conversations.length) {
      return
    }

    if (isLoadMore || fetchingConversation) {
      return
    }

    setIsLoadMore(true)
    let newPage = page
    newPage += 1
    getConversation(newPage, selectedPlatform, selectedStore);
    setPage(newPage)
  })

  const getUserAvatar = (user) => {
    if (!user) return avatarDefault
    return user.avatar != "" ? user.avatar : avatarDefault
  }

  const handleOnChangePlatform = (platformId) => {
    setSelectedPlatform(platformId)
    history.push({ search: `?platform_id=${platformId}&store_id=${selectedStore}` })
    setSelectedPlatform(platformId)
    getConversation(1, platformId, selectedStore);
    setPage(1)
  }

  const handleOnChangeStore = (storeId) => {
    setSelectedPlatform(storeId)
    history.push({ search: `?platform_id=${selectedPlatform}&store_id=${storeId}` })
    setSelectedStore(storeId)
    getConversation(1, selectedPlatform, storeId);
    setPage(1)
  }

  return (
    <Sidebar position="left" scrollable={false}>
      <div className='p-2 mt-1 mb-1'>
        <SelectPlatformInput onChange={handleOnChangePlatform} value={selectedPlatform} />
        <SelectStoreInput onChange={handleOnChangeStore} value={selectedStore} />
      </div>
      <hr className='' />
      <ConversationList
        scrollable
        loadingMore={isLoadMore}
      >
        {conversations.map(conv => {
          const user = find(users, { 'id': conv.user_creator_id })
          const platform = find(platforms, { 'id': conv.platform_id })
          return <Conversation
            onClick={() => handleOnClick(conv)}
            key={conv.id}
            name={conv.title}
            active={conversationDetail.id == conv.id}
            lastSenderName={conv.last_message ? conv.last_message.message : ''}
            info={'..'}>
            <Avatar
              src={getUserAvatar(user)}
              status="available" />

            <Conversation.Operations visible>
              {conv.is_record_mode &&
                <Status status="dnd" size='xs'>
                  <div style={{
                    color: "red"
                  }}>recorded
                  </div>
                </Status>
              }
              {platform &&
                <PlatformLogo
                  style={{ width: 50 }}
                  platformName={platform.platform_name} />
              }
            </Conversation.Operations>
          </Conversation>
        })}

        {/* {(totalConversation > conversations.length) && */}
        <div className='text-center p-2'>
          <Button color='link primary' disabled={isLoadMore} onClick={handleLoadMore}>
            Load More
          </Button>
        </div>
        {/* } */}
      </ConversationList>
    </Sidebar>)
}

const mapStateToProps = ({ Conversation }) => {
  return {
    ...Conversation.conversation,
    fetchingConversation: Conversation.fetchingConversation,
    conversationDetail: Conversation.conversationDetail,
    totalConversation: Conversation.totalConversation,
  }
}

export default connect(
  mapStateToProps,
  {
    getConversation,
    getConversationDetailSuccess,
  }
)(ChatLeftSidebarUI);
