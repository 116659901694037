import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Container,
  Badge,
  Alert,
} from "reactstrap";
import { getInvoices } from "store/actions";
import { convertInt2Money } from "components/Product/ProductCardItem";
import CustomizeTable, { handleValidDate } from "components/CustomizeTable";
import queryString from "query-string";

const columns = [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, user) => <>{order.id}</>,
  },
  {
    dataField: "orderId",
    text: "Order ID",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.invoice_number}
      </Link>
    ),
  },
  {
    dataField: "fullname",
    text: "Billing Name",
    sort: true,
  },
  {
    dataField: "created_at",
    text: "Date",
    sort: true,
    formatter: (cellContent, row) => handleValidDate(row.created_at),
  },
  {
    dataField: "total",
    text: "Total",
    formatter: (cellContent, row) => {
      return convertInt2Money(row.total)
    },
    sort: true,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={"font-size-12 badge-soft-" + row.badgeclass}
        color={row.status == 'paid' ? 'primary' : 'secondary'}
        pill
      >
        {row.status}
      </Badge>
    ),
  },
  {
    dataField: "paymentMethod",
    isDummyField: true,
    text: "Payment Method",
    sort: true,
    formatter: (cellContent, row) => {
      return row.payment_method.name
    },
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: true,
    formatter: (cellContent, row) => (
      <Link
        type="button"
        color="primary"
        className="btn-sm btn-rounded"
        to={`/billing/invoice-detail/${row.id}`}
      >
        View
      </Link>
    ),
  },
]

const InvoiceList = ({
  location,
  history,
  onGetInvoice,
  error,
  isFetching,
  invoices,
  totalInvoice }) => {

  let qs = queryString.parse(location.search)
  const [page, setPage] = useState(parseInt(qs.page) || 1)
  const [pagePerSize, setPagePerSize] = useState(parseInt(qs.limit) || 10)

  useEffect(() => {
    if (isFetching) {
      return
    }

    let qs = queryString.parse(location.search)
    onGetInvoice(qs.page, qs.limit)
  }, [location])


  const handleOnPageChange = (type, { page, sizePerPage }) => {
    const { pathname } = location
    setPage(page)
    setPagePerSize(sizePerPage)
    history.replace(`${pathname}?page=${page}&&limit=${sizePerPage}`)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {(error && error.detail) &&
            <Alert color="danger">
              {error.detail}
            </Alert>
          }

          <CustomizeTable
            page={page}
            loading={isFetching}
            totalPage={totalInvoice}
            onPageChange={handleOnPageChange}
            columns={columns}
            pagePerSize={pagePerSize}
            data={invoices} />

        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = ({ Invoice }) => ({
  invoices: Invoice.invoices,
  totalInvoice: Invoice.totalInvoice,
  isFetching: Invoice.isFetching,
  error: Invoice.error,
});

export default connect(
  mapStateToProps,
  {
    'onGetInvoice': getInvoices
  }
)(withRouter(InvoiceList));
