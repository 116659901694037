/* QNA_DICTIONARY_PRODUCT */
export const GET_QNA_DICTIONARY_PRODUCT = "GET_QNA_DICTIONARY_PRODUCT"
export const GET_QNA_DICTIONARY_PRODUCT_SUCCESS = "GET_QNA_DICTIONARY_PRODUCT_SUCCESS"
export const GET_QNA_DICTIONARY_PRODUCT_FAIL = "GET_QNA_DICTIONARY_PRODUCT_FAIL"

export const FETCH_QNA_DICTIONARY_REQUEST = "FETCH_QNA_DICTIONARY_REQUEST"
export const FETCH_QNA_DICTIONARY_SUCCESS = "FETCH_QNA_DICTIONARY_SUCCESS"
export const FETCH_QNA_DICTIONARY_FAILED = "FETCH_QNA_DICTIONARY_FAILED"

export const UPDATE_QNA_DICTIONARY = "UPDATE_QNA_DICTIONARY"
export const UPDATE_QNA_DICTIONARY_SUCCESS = "UPDATE_QNA_DICTIONARY_SUCCESS"
export const UPDATE_QNA_DICTIONARY_FAIL = "UPDATE_QNA_DICTIONARY_FAIL"

export const DELETE_QNA_DICTIONARY = "DELETE_QNA_DICTIONARY"
export const DELETE_QNA_DICTIONARY_SUCCESS = "DELETE_QNA_DICTIONARY_SUCCESS"
export const DELETE_QNA_DICTIONARY_FAIL = "DELETE_QNA_DICTIONARY_FAIL"
