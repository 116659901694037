import { ExpansionPanel } from '@chatscope/chat-ui-kit-react'
import SanitizeHTML from 'components/SanitizeHTML'
import CardTaskBox from 'components/Ticket/CardTaskBox'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Row } from 'reactstrap'
import { getConversationTicket } from 'store/ticket/actions'


const ChatTicketList = ({conversationId, tickets, getConversationTicket}) => {

  useEffect(() => {
    getConversationTicket(conversationId)
  }, [])

  if(!tickets || tickets.length < 1){
    return null;
  }

  return (
    <ExpansionPanel title="TICKETS">

      {tickets.map(ticket=>(
        <ListGroup key={ticket.id}>
          <h5 className='p-1 mt-1 font-size-14'>{ticket.status.toUpperCase()}</h5>

          {ticket.cards.map(card => {
            return (
              <ListGroupItem key={card.id}>
                <Link to={`/ticket-detail/${card.id}`}>
                  {card.ticket.title}
                </Link>
              </ListGroupItem>

            )
          })}

        </ListGroup>
        ))}

    </ExpansionPanel>
  )
}

const mapStateToProps = ({Ticket}) => ({
  tickets: Ticket.conversationTickets,
})

export default connect(
  mapStateToProps,
  {
    getConversationTicket
  }
)(ChatTicketList)
