import CommonEditor from "components/Common/CommonEditor";
import React, { useState } from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

const ProductKnowledgeModal = ({
  onSubmit,
  isOpen,
  toggle,
  isLoading,
  id,
  title,
  data,
  errorMessage,
  isEditable }) => {
  const [question, setQuestion] = useState(isEditable ? data['question'] : '')
  const [answer, setAnswer] = useState(isEditable ? data['answer'] : '')

  const handleOnSubmit = () => {
    onSubmit(answer, question)
  }

  return (
    <>
      <Modal size="lg" isOpen={isOpen} toggle={toggle}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <h5>Question:</h5>
          <CommonEditor
            readOnly={isLoading}
            id={id}
            text={question} onChange={(e) => setQuestion(e)} />
          <h5 className="mt-4">Answer:</h5>
          <CommonEditor
            id={id}
            readOnly={isLoading}
            text={answer}
            onChange={(e) => setAnswer(e)} />
          <div className="text-center">
            {isLoading &&
              <Spinner />
            }
          </div>
          {errorMessage &&
            <Alert className="mt-4" color="danger">{errorMessage}</Alert>
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="danger m-1">
            Cancel
          </Button>
          <Button
            onClick={handleOnSubmit}
            color="primary">
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}


export default ProductKnowledgeModal
