const { get, post, put, del } = require("network/http/api")

export const getUserList = (page = 1, limit = 10) => {
  return get(`/admin/account/user?page=${page}&&limit=${limit}`)
}

export const getUserDetail = (userId) => {
  return get(`/admin/account/user/${userId}`)
}

export const updateUserDetail = (userId, data) => {
  return put(`/admin/account/user/${userId}`, data)
}
