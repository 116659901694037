import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { isEmpty, map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Image
import logo from 'assets/images/image2vector.svg';

// Redux
import PropTypes from "prop-types";
import { getInvoiceDetail } from "../../store/invoices/actions";
import { connect } from "react-redux";
import moment from "moment";
import { convertInt2Money } from "components/Product/ProductCardItem";

class InvoiceDetail extends Component {
  componentDidMount() {
    const {
      match: { params },
      onGetInvoiceDetail,
    } = this.props;
    if (params && params.invoiceNumber) {
      onGetInvoiceDetail(params.invoiceNumber);
    }
  }

  //Print the Invoice
  printInvoice = () => {
    window.print();
  };

  parseDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };


  render() {
    const { invoiceDetail } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" />
            {!isEmpty(invoiceDetail) && (
              <Row className="font-size-14">
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <h4 className="float-end font-size-16">
                          Order # {invoiceDetail.invoice_number}
                        </h4>
                        <div className="mb-4">
                          <img src={logo} alt="logo" height="20" />
                        </div>
                      </div>
                      <hr />
                      <Row>
                        <Col sm="6">
                          <address>
                            <strong>Billed To:</strong>
                            <br />
                            {invoiceDetail.fullname}
                          </address>
                        </Col>
                        <Col sm="6" className="text-sm-end">
                          <address className="mt-2 mt-sm-0">
                            <strong>Status To:</strong>
                            <br />
                            <span className="font-weight-bold font-size-15">{invoiceDetail.status.toUpperCase()}</span>
                          </address>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6" className="mt-3">
                          <address>
                            <strong>Payment Method:</strong>
                            <br />
                            {invoiceDetail.payment_method.name} (Virtual Account)
                            <br />
                            <div className="mt-3">
                              <strong>Virtual Account Number</strong>
                              <p>{invoiceDetail.virtual_account.account_number}</p>
                            </div>
                            {/* {invoiceDetail.email} */}
                          </address>
                        </Col>
                        <Col sm="6" className="mt-3 text-sm-end">
                          <address>
                            <strong>Order Date:</strong>
                            <br />
                            {this.parseDate(invoiceDetail.created_at)}
                            <br />
                            <br />
                          </address>
                        </Col>
                      </Row>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          Order summary
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <th style={{ width: "70px" }}>No.</th>
                              <th>Item</th>
                              <th className="text-end">Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Service Plan - {invoiceDetail.plan_name}</td>
                              <td className="text-end">{convertInt2Money(invoiceDetail.plan_price)}</td>
                            </tr>
                            {invoiceDetail.additional_agent > 0 &&
                              <tr>
                                <td>2</td>
                                <td>Additional AI Agent (x {invoiceDetail.additional_agent})</td>
                                <td className="text-end">{convertInt2Money(invoiceDetail.additional_agent_price * invoiceDetail.additional_agent)}</td>
                              </tr>
                            }
                            <tr>
                              <td colSpan="2" className="text-end">
                                Sub Total
                              </td>
                              <td className="text-end">
                                {convertInt2Money(invoiceDetail.amount)}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2" className="border-0 text-end">
                                <strong>Transfer Fee</strong>
                              </td>
                              <td className="border-0 text-end">
                                {convertInt2Money(invoiceDetail.transfer_fee || 0)}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2" className="border-0 text-end">
                                <strong>Total</strong>
                              </td>
                              <td className="border-0 text-end">
                                <h4 className="m-0">
                                  {convertInt2Money(invoiceDetail.total)}
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-print-none">
                        <div className="float-end">
                          <Link
                            to="#"
                            onClick={this.printInvoice}
                            className="btn btn-success me-1"
                          >
                            <i className="fa fa-print" />
                          </Link>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

InvoiceDetail.propTypes = {
  invoiceDetail: PropTypes.object,
  match: PropTypes.object,
  onGetInvoiceDetail: PropTypes.func,
};

const mapStateToProps = ({ Invoice }) => ({
  invoiceDetail: Invoice.invoiceDetail,
  isFetching: Invoice.isFetching,
});

export default connect(
  mapStateToProps,
  {
    onGetInvoiceDetail: getInvoiceDetail,
  }
)(withRouter(InvoiceDetail));
