import * as types from './actionTypes'

export const getTrainingDataset = (page=1) => ({
  type: types.GET_TRAINING_DATASET_REQUEST,
  payload: {page},
})

export const getTrainingDatasetSuccess = (payload) => ({
  type: types.GET_TRAINING_DATASET_SUCCESS,
  payload: payload,
})

export const getTrainingDatasetFailed = (error) => ({
  type: types.GET_TRAINING_DATASET_FAILED,
  payload: error,
})

export const getStatsTrainingDataset = () => ({
  type: types.GET_TRAINING_STATS_REQUEST,
})

export const getStatsTrainingDatasetSuccess = (payload) => ({
  type: types.GET_TRAINING_STATS_SUCCESS,
  payload: payload,
})

export const getStatsTrainingDatasetFailed = (error) => ({
  type: types.GET_TRAINING_STATS_FAILED,
  payload: error
})

export const correctTrainingLabelDataset = (id, newLabel) => ({
  type: types.CORRECT_TRAINING_LABEL_REQUEST,
  payload: {
    id,
    newLabel
  }
})

export const correctTrainingLabelDatasetSuccess = (payload) => ({
  type: types.CORRECT_TRAINING_LABEL_SUCCESS,
  payload: payload,
})

export const correctTrainingLabelDatasetFailed = (error) => ({
  type: types.CORRECT_TRAINING_LABEL_FAILED,
  payload: error
})


export const updateTrainingLabelDataset = (id, newLabel) => ({
  type: types.UPDATE_TRAINING_LABEL_REQUEST,
  payload: {
    id,
    newLabel
  }
})

export const updateTrainingLabelDatasetSuccess = (payload) => ({
  type: types.UPDATE_TRAINING_LABEL_SUCCESS,
  payload: payload,
})

export const updateTrainingLabelDatasetFailed = (payload) => ({
  type: types.UPDATE_TRAINING_LABEL_FAILED,
  payload: payload
})


export const predictAnswer = (id) => ({
  type: types.PREDICT_TRAINING_LABEL_REQUEST,
  payload: id,
})

export const predictAnswerSuccess = (payload) => ({
  type: types.PREDICT_TRAINING_LABEL_SUCCESS,
  payload,
})

export const predictAnswerFailed = (error) => ({
  type: types.PREDICT_TRAINING_LABEL_FAILED,
  payload: error,
})
