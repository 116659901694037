import React from "react";

const SectionHeader = props => {
  return (
    <div className="section-header">
      <h1 className="title">{props.title}</h1>
      <span className="subtitle">{props.subtitle}</span>
    </div>
  );
};

export default SectionHeader;
