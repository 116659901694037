import Breadcrumbs from 'components/Common/Breadcrumb';
import CustomizeTable, { handleValidDate } from 'components/CustomizeTable';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Badge, Button, Col, Container, Row, Spinner } from 'reactstrap';
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import { fetchCompany } from 'store/company/actions';

const columns = [
  {
    dataField: "id",
    text: "Company ID",
    formatter: (cellContent, row) => (
      <Link to={`/account/company/${row.id}`} className="fw-bold">
        {row.id}
      </Link>
    ),
  },
  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "category",
    text: "Category",
  },
  {
    dataField: "total_account",
    text: "Total Account",
    formatter: (cellContent, row) => {
      return row.employees.length
    }
  },
  {
    dataField: "created_at",
    text: "Created Date",
    sort: true,
    formatter: (cellContent, row) => handleValidDate(cellContent),
  },

]

const CompanyList = ({
  error,
  location,
  history,
  onFetchCompany,
  companies,
  totalCompany,
  isFetching }) => {

  let qs = queryString.parse(location.search)
  const [page, setPage] = useState(parseInt(qs.page) || 1)
  const [pagePerSize, setPagePerSize] = useState(parseInt(qs.limit) || 10)

  useEffect(() => {
    if (isFetching) {
      return
    }
    let qs = queryString.parse(location.search)
    onFetchCompany(qs.page, qs.limit)
  }, [location])

  const handleOnPageChange = (type, { page, sizePerPage }) => {
    const { pathname } = location
    setPage(page)
    setPagePerSize(sizePerPage)
    history.replace(`${pathname}?page=${page}&&limit=${sizePerPage}`)
  }

  return (<React.Fragment>
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Company" breadcrumbItem="All Companiess" />

        {(error && error.detail) &&
          <Alert color="danger">
            {error.detail}
          </Alert>
        }

        <Row>
          <Col xs="12">
            <CustomizeTable
              page={page}
              loading={isFetching}
              totalPage={totalCompany}
              onPageChange={handleOnPageChange}
              pagePerSize={pagePerSize}
              columns={columns}
              data={companies} />
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
  )

}

const mapStateToProps = ({ Company }) => ({
  'isFetching': Company.isFetching,
  'companies': Company.companies,
  'totalCompany': Company.totalCompany,
  'error': Company.errorMessage,
})

export default withRouter(connect(mapStateToProps, {
  'onFetchCompany': fetchCompany
})(CompanyList))
