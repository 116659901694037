import React, { useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Row, Spinner } from 'reactstrap'
import SanitizeHTML from 'components/SanitizeHTML'
import EditKnowledgeBase from './EditKnowledgeBase'
import DeleteKnowledgeBase from './DeleteKnowledgeBase'
import LikeKnowledgeBase from './LikeKnowledgeBase'



const ProductKnowledgeItem = ({ productId, data, refreshFunc }) => {
  return (
    <>
      <Card >
        <CardTitle></CardTitle>
        <CardBody>
          <p><strong>Question:</strong></p>
          <SanitizeHTML html={data['question'].replaceAll("\n", "<br/>")} />
          <br />
          <p><strong>Answer:</strong></p>
          <SanitizeHTML html={data['answer'].replaceAll("\n", "<br/>")} />
        </CardBody>
        <CardFooter>
          <Row>
            <Col xs={6}>
              <EditKnowledgeBase
                productId={productId}
                data={data}
                refreshFunc={refreshFunc} />
              <span className='m-1' />
              <DeleteKnowledgeBase refreshFunc={refreshFunc} id={data['id']} />
            </Col>
            <Col xs={6}>
              <LikeKnowledgeBase data={data} />
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  )
}

export default ProductKnowledgeItem;
