import React, { Component, Fragment } from 'react'

// Import Editor
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw, convertFromRaw, convertFromHTML } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const toolbarOptions = {
  options: ['inline', 'fontSize', 'link', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline'],
  },
}

class CommonEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editorState: EditorState.createEmpty(),
    }
  }

  componentDidMount() {
    const { text } = this.props

    if (!text) {
      return
    }

    const content = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(text)
      )
    )

    this.setState({
      editorState: content,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) {
      return
    }

    const { text } = this.props;
    if (this.props.id === prevProps.id || this.props.text === prevProps.text) {
      return
    }


    const content = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(text)
      )
    )

    this.setState({
      editorState: content,
    })
  }

  handleOnChangeEditor = (e) => {
    this.setState({ editorState: e })
    const { onChange } = this.props;

    if (onChange) {
      let content = draftToHtml(convertToRaw(e.getCurrentContent()))
      onChange(content)
    }

  }

  render() {
    const { defaultContent, editorState } = this.state;

    return (
      <Editor
        defaultContentState={defaultContent}
        editorState={editorState}
        toolbar={toolbarOptions}
        onEditorStateChange={(e) => this.handleOnChangeEditor(e)}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        placeholder="Place Your Note Here..."
      />
    )
  }
}

export default CommonEditor
