import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import chatSaga from "./chat/saga"
import platformSaga from "./platform/saga";
import shopSaga from './store/saga';
import ticketSaga from './ticket/saga';
import ticketCommentSaga from './ticket-comment/saga';
import conversationSaga from './conversation/saga';
import productSaga from './product/saga';
import qnaDictionarySaga from './qna-dictionary/saga';
import trainingSaga from "./training/saga"
import invoiceSaga from "./invoices/saga"
import billingSaga from "./billing/saga"
import companySaga from "./company/saga"
import datasetTrainSaga from "./dataset-train/saga"
import userSaga from './user/saga'

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(chatSaga),
    fork(platformSaga),
    fork(shopSaga),
    fork(ticketSaga),
    fork(ticketCommentSaga),
    fork(conversationSaga),
    fork(productSaga),
    fork(qnaDictionarySaga),
    fork(trainingSaga),
    fork(invoiceSaga),
    fork(billingSaga),
    fork(companySaga),
    fork(datasetTrainSaga),
    fork(userSaga)
  ])
}
