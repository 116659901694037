import React from "react";

const TableHeadComponent = ({ dataHead }) => {
  if (dataHead.length === 0) {
    return (
      <thead>
        <tr>
          <th>Head Kosong</th>
        </tr>
      </thead>
    );
  }

  return (
    <thead>
      <tr>
        {dataHead.map((items, idx) => {
          return <th key={idx}>{items}</th>;
        })}
      </tr>
    </thead>
  );
};

export default TableHeadComponent;
