import {
  GET_QNA_DICTIONARY_PRODUCT,
  GET_QNA_DICTIONARY_PRODUCT_FAIL,
  GET_QNA_DICTIONARY_PRODUCT_SUCCESS,
  DELETE_QNA_DICTIONARY,
  DELETE_QNA_DICTIONARY_SUCCESS,
  DELETE_QNA_DICTIONARY_FAIL,
  FETCH_QNA_DICTIONARY_REQUEST,
  FETCH_QNA_DICTIONARY_SUCCESS,
  FETCH_QNA_DICTIONARY_FAILED,
} from "./actionTypes"

export const getQnaDictionaryProduct = (id) => ({
  type: GET_QNA_DICTIONARY_PRODUCT,
  payload: id,
})

export const getQnaDictionarySuccess = dictionaries => ({
  type: GET_QNA_DICTIONARY_PRODUCT_SUCCESS,
  payload: dictionaries,
})

export const getQnaDictionaryFail = error => ({
  type: GET_QNA_DICTIONARY_PRODUCT_FAIL,
  payload: error,
})

export const deleteQnaDictionary = project => ({
  type: DELETE_QNA_DICTIONARY,
  payload: project,
})

export const deleteQnaDictionarySuccess = project => ({
  type: DELETE_QNA_DICTIONARY_SUCCESS,
  payload: project,
})

export const deleteQnaDictionaryFail = error => ({
  type: DELETE_QNA_DICTIONARY_FAIL,
  payload: error,
})

export const fetchQnaDictionary = (page = 1, limit = 25, query = "") => ({
  type: FETCH_QNA_DICTIONARY_REQUEST,
  payload: { page, limit, query }
})

export const fetchQnaDictionarySuccess = (payload) => ({
  type: FETCH_QNA_DICTIONARY_SUCCESS,
  payload: payload
})

export const fetchQnaDictionaryError = (error) => ({
  type: FETCH_QNA_DICTIONARY_FAILED,
  payload: error
})
