import PlanService from "components/PlanService";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { handleErrorRequest, post } from "network/http/api";
import React, { Component, useEffect } from "react"
import { withRouter } from "react-router-dom";
import { Alert, Button, Card, CardBody, Modal, ModalBody, ModalFooter, Label, ModalHeader, Spinner, UncontrolledAlert } from "reactstrap"
import * as Yup from "yup";

class InviteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      isOpen: false,
      fullname: '',
      company_name: '',
      phone_number: '',
      email: '',
      password: '',
      isLoading: false,
      plan_id: 0,
    };
  }

  componentWillUnmount() {
    this.setState({
      fullname: '',
      company_name: '',
      phone_number: '',
      email: '',
      password: '',
      isLoading: false,
      plan_id: 0,
      errorMessage: null,
    });
  }


  handleOnSubmit = (values) => {

    const { plan_id } = this.state
    if (plan_id < 1) {
      return
    }

    const { history } = this.props;
    this.setState({
      isLoading: true
    })

    const data = {
      ...values,
      plan_id,
      password: '',
    }

    post('/admin/account/user/invite', data)
      .then(resp => {
        this.props.history.push("/account/user/" + resp.id)
        this.setState({
          errorMessage: null,
          isLoading: false,
        })
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          errorMessage: err.detail,
        })
      })
  }



  render() {
    const { isOpen, isLoading, errorMessage } = this.state;

    return (
      <>
        <Button onClick={() => this.setState({ isOpen: true })}>
          Invite User
        </Button>
        <Modal isOpen={isOpen} toggle={() => this.setState({ isOpen: !isOpen })}>
          <ModalHeader>
            User Information
          </ModalHeader>
          <ModalBody>
            <Card className="overflow-hidden">
              <CardBody className="p-0">
                <div className="p-2">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      fullname: (this.state && this.state.fullname) || "",
                      company_name: (this.state && this.state.company_name) || "",
                      phone_number: (this.state && this.state.phone_number) || "",
                      email: (this.state && this.state.email) || "",
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().required(
                        "Please Enter Your Email"
                      ),
                      fullname: Yup.string().required(
                        "Please Enter Valid Full Name"
                      ),
                      phone_number: Yup.string().required(
                        "Please Enter Valid Phone Number"
                      ),
                      company_name: Yup.string().required(
                        "Please Enter Your Company Name"
                      ),
                    })}
                    onSubmit={this.handleOnSubmit}
                  >
                    {({ errors, status, touched }) => (
                      <Form className="form-horizontal">
                        <div className="mb-3">
                          <Label for="fullname" className="form-label">
                            Full Name
                          </Label>
                          <Field
                            name="fullname"
                            type="text"
                            className={
                              "form-control" +
                              (errors.fullname && touched.fullname
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="fullname"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label for="phone_number" className="form-label">
                            Phone Number
                          </Label>
                          <Field
                            name="phone_number"
                            type="text"
                            className={
                              "form-control" +
                              (errors.phone_number && touched.phone_number
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="phone_number"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label for="company_name" className="form-label">
                            Company Name
                          </Label>
                          <Field
                            name="company_name"
                            type="text"
                            className={
                              "form-control" +
                              (errors.company_name && touched.company_name
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="company_name"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label for="email" className="form-label">
                            Email
                          </Label>
                          <Field
                            name="email"
                            type="email"
                            className={
                              "form-control" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="mb-3">
                          <Label for="plan_id" className="form-label">
                            Plan Service
                          </Label>
                          <PlanService

                            onClick={(id) => this.setState({ plan_id: id })} />
                          <Field
                            type='number'
                            name='plan_id' hidden={true} />
                          <ErrorMessage
                            name="plan_id"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        {errorMessage &&
                          <UncontrolledAlert color="danger">
                            {errorMessage || 'Sorry, please try again'}
                          </UncontrolledAlert>
                        }

                        <div className="mt-4 d-grid">
                          {isLoading &&
                            <div className="text-center m-2">
                              <Spinner />
                            </div>
                          }
                          <Button
                            disabled={isLoading}
                            color="primary"
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Send Email Invitation
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

export default withRouter(InviteUser)
