import React, { useState, useEffect } from "react";
import { get } from "network/http/api";
import { debounce } from "lodash";
import SectionHeader from "components/Page/SectionHeader";
import SearchComponentfrom from "components/Page/SearchComponent";
import TableComponent from "components/Page/TableComponent";
import FilterComponent from "components/Page/FilterComponent";
import PagginationButton from "components/PagginationButton";

import TableBodyFinance from "./ComponentsPage/TableBodyFinance";
import { LIST_FILTER_STATUS } from "./DefaultData";

const Finance = () => {
  const [dataInvoice, setDataInvoice] = useState([]);
  const [paramsPage, setParamsPage] = useState({
    perPage: 10,
    page: 1,
    query: "",
  });
  const [totalPage, setTotalPage] = useState(0);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchUser = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      let params = `page=${paramsPage.page}&&limit=${paramsPage.perPage}`;
      let url = `/admin/billing/invoice`;
      if (query !== "") {
        url = `/admin/billing/search/invoice?invoice_number=${query}`;
        const res = await get(url);
        setDataInvoice(res);
        const totalPage = res ? Math.ceil(res.length / paramsPage.perPage) : 0;
        setTotalPage(totalPage);
      } else {
        url = `${url}?${params}`;
        const res = await get(url);
        const result = res.length ? res[0] : [];
        setDataInvoice(result);
        const totalPage = res[1] ? Math.ceil(res[1] / paramsPage.perPage) : 0;
        setTotalPage(totalPage);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) return;

    const fetch = debounce(fetchUser, 500);
    fetch();

    return () => fetch.cancel();
  }, [query, paramsPage]);

  return (
    <div className="page-container">
      <div className="page-content-costume">
        <SectionHeader
          title="Finance Dashboard"
          subtitle="Daftar dari transaksi/invoice yang dimiliki oleh pengguna RISA AI,
            dibagi berdasarkan status sukses, menunggu, dan juga gagal."
        />
        <div className="section-content page-finance">
          <div className="section-filter">
            <SearchComponentfrom type="small" setQuery={setQuery} />
            <FilterComponent option={LIST_FILTER_STATUS} />
          </div>
          <div className="section-table">
            <TableComponent
              dataHead={[
                "Nomor Invoice",
                "Nama User",
                "Nominal Transaksi",
                "Paket",
                "Status",
                "Aksi",
              ]}
              componentBody={<TableBodyFinance dataBody={dataInvoice} />}
            />
            <PagginationButton
              paramsPage={paramsPage}
              setParamsPage={setParamsPage}
              amountPage={totalPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finance;
