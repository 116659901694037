import * as types from "./actionTypes"
import * as ws_types from '../chat/actionTypes'
const INIT_STATE = {
  isFetching: false,
  isPredicting: false,
  isUpdating: false,
  isLoadStats: false,
  dataset: {
    question: {},
    intention_labels: []
  },
  stats: {},
  error: {},
}

const TrainingDataset = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_TRAINING_STATS_REQUEST:
      return {
        ...state,
        isLoadStats: true,
      }
    case types.GET_TRAINING_STATS_SUCCESS:
      return {
        ...state,
        isLoadStats: false,
        stats: action.payload,
      }
    case types.GET_TRAINING_STATS_FAILED:
      return {
        ...state,
        isLoadStats: false,
        error: action.payload,
      }
    case types.GET_TRAINING_DATASET_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.GET_TRAINING_DATASET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isPredicting: false,
        dataset: action.payload,
      }
    case types.GET_TRAINING_DATASET_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case types.PREDICT_TRAINING_LABEL_REQUEST:
    case types.CORRECT_TRAINING_LABEL_REQUEST:
      return {
        ...state,
        isPredicting: true,
      }
    case types.PREDICT_TRAINING_LABEL_SUCCESS:
    case types.CORRECT_TRAINING_LABEL_SUCCESS:
      return {
        ...state,
        dataset: {
          ...state.dataset,
          question: action.payload,
        },
      }
    case types.PREDICT_TRAINING_LABEL_FAILED:
    case types.CORRECT_TRAINING_LABEL_FAILED:
      return {
        ...state,
        isPredicting: false,
      }
    case types.UPDATE_TRAINING_LABEL_REQUEST:
      return {
        ...state,
        isUpdating: true,
      }
    case ws_types.POST_ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        isPredicting: false,
        dataset: {
          ...state.dataset,
          question: {
            ...state.dataset.question,
            response_from_agent: action.payload,
          }
        }
      }
    case ws_types.UPDATE_MESSAGE_RECEIVED:
      return {
        ...state,
        dataset: {
          ...state.dataset,
          question: {
            ...state.dataset.question,
            message: action.payload,
          }
        }
      }
    case types.UPDATE_TRAINING_LABEL_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      }
    case types.UPDATE_TRAINING_LABEL_FAILED:
      return {
        ...state,
        isUpdating:false,
        error: action.payload,
      }
    default:
      break;
  }

  return state;
}

export default TrainingDataset
