import React, { useState, useRef } from "react";
import { useOutsideClick } from "hooks/useOutsideClick";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";

const FilterComponent = ({ option }) => {
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick(() => {
    setIsOpen(false);
  }, modalRef);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      ref={modalRef}
      className="component-filter"
      onClick={() => handleToggleDropdown()}
    >
      <div className="btn-filter">
        <AdjustmentsHorizontalIcon />
        <span>Filter</span>
      </div>

      {isOpen && (
        <div className="option-filter">
          <span>Status</span>
          {option.map((item, index) => (
            <label key={index} className="checkbox-label">
              <input
                className="checkbox-input"
                type="checkbox"
                // value={item.name}
                // checked={selectedItems.includes(item.name)}
                // onChange={() => handleCheckboxChange(item.name)}
              />
              {item.name}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterComponent;
