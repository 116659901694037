import { get } from "network/http/api"
import React, { useEffect, useState } from "react"
import { Spinner } from "reactstrap"

const AllProductSyncStats = ({ store }) => {
  const [syncStats, setSyncStats] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    get('admin/product/store-sync-stats/' + store.id)
      .then(resp => {
        setIsLoading(false)
        setSyncStats(resp)
        setErrorMessage(null)
      })
      .catch(err => {
        setIsLoading(false)
        setErrorMessage(err.detail)
      })
  }, [store])

  if (isLoading) {
    return (<div className="text-center"><Spinner type="grow"></Spinner></div>)
  }

  if (errorMessage) {
    return (<span style={{ 'color': 'red' }}>{errorMessage}</span>)
  }

  return (<div className="text-muted text-align-right">
    <small>Total Product : {syncStats.total_product}</small>
    <small> / </small>
    <small>Total Embed : {syncStats.total_product_vectorize}</small>
  </div>)
}

export default AllProductSyncStats
