import React, { useState } from "react"
import { convertInt2Money } from "./ProductCardItem"
import ProductDetailDescription from "./ProductDetailDescription"
import ProductDetailVariants from "./ProductDetailVariants"
import ProductDetailImage from "./ProductDetailImage"
import ProductSyncState from "./ProductSync/ProductSyncState"
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import { Link } from "react-router-dom"
import ProductKnowledge from "./ProductKnowledge/ProductKnowledge"
import { isEmpty } from "lodash"

const ProductCardDetail = ({ parent, product, stock, variants, onChangeVariant }) => {
  const [activeTab, setActiveTab] = useState(1)

  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs="12">
            <ProductSyncState product={parent} />
          </Col>
          <Col xl="6">
            <ProductDetailImage

              pictures={isEmpty(product.pictures) ? parent.pictures : product.pictures}
              url={product.product_url}
            />
          </Col>

          <Col xl="6">
            <div className="mt-4 mt-xl-3">
              <Link to={`/ecommerce-products/category/${product.category_id}`} className="text-primary">
                {product.category.name}
              </Link>
              <h4 className="mt-1 mb-3">{product.name}</h4>


              <p className="text-muted mb-4">
                ( {product.reviews} Customers Review )
              </p>

              <ProductDetailVariants
                onClick={onChangeVariant}
                variants={variants} />

              <h5 className="mt-5">
                {product.discount_price &&
                  <span className="text-muted me-2">
                    <del>{convertInt2Money(product.discount_price)}</del>
                  </span>
                }
                <b>{convertInt2Money(product.price)}</b>
              </h5>

              <div className="mt-2">
                <h5>Stock: {product.stock}</h5>
              </div>
            </div>
          </Col>
        </Row>
        <Nav className="mb-2 mt-2" vertical={false} pills tabs justified horizontal="center">
          <NavItem>
            <NavLink active={activeTab == 1} onClick={() => setActiveTab(1)}>
              Product Detail
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab == 2} onClick={() => setActiveTab(2)}>
              Knowledge Base
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>

            <ProductDetailDescription product={parent} />
          </TabPane>
          <TabPane tabId={2}>
            <ProductKnowledge productId={product.pl_product_id} />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  )
}

export default ProductCardDetail;
