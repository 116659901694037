import { merge } from "lodash";
import {
  GET_QNA_DICTIONARY_PRODUCT,
  GET_QNA_DICTIONARY_PRODUCT_FAIL,
  GET_QNA_DICTIONARY_PRODUCT_SUCCESS,
  DELETE_QNA_DICTIONARY,
  DELETE_QNA_DICTIONARY_FAIL,
  DELETE_QNA_DICTIONARY_SUCCESS,
  FETCH_QNA_DICTIONARY_REQUEST,
  FETCH_QNA_DICTIONARY_SUCCESS,
  FETCH_QNA_DICTIONARY_FAILED,
} from "./actionTypes"

const INIT_STATE = {
  qnaDictionary: {},
  qnaDictionaries: [],
  isFetching: false,
  isDeleting: false,
  error: null,
  page: 1,
  totalItem: 0
}

const deleteDictionaryState = (state, id) => {
  let { qnaDictionary } = state;
  return qnaDictionary.map(v => {
    if (v.id === id) {
      return
    }

    return v
  })
}

function mergeQnaDictArray(old, newData, page) {
  if (page == 1) {
    return newData
  }
  const ll = old.filter(book => !newData.some(newBook => newBook.id === book.id));
  return ll.concat(newData);
}

const qnaDictionary = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_QNA_DICTIONARY_REQUEST:
    case GET_QNA_DICTIONARY_PRODUCT:
      return {
        ...state,
        page: action.payload.page,
        isFetching: true,
      }
    case FETCH_QNA_DICTIONARY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        qnaDictionaries: mergeQnaDictArray(state.qnaDictionaries, action.payload.qna, state.page),
        totalItem: action.payload.total_item || 0
      }
    case GET_QNA_DICTIONARY_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        qnaDictionary: action.payload,
      }

    case FETCH_QNA_DICTIONARY_FAILED:
    case GET_QNA_DICTIONARY_PRODUCT_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }

    case DELETE_QNA_DICTIONARY:
      return {
        ...state,
        isDeleting: true
      }

    case DELETE_QNA_DICTIONARY_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        qnaDictionary: deleteDictionaryState(state, action.payload)
      }

    case DELETE_QNA_DICTIONARY_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default qnaDictionary
