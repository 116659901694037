import React, { Fragment, useEffect, useRef, useState } from 'react'
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { AUTH_USER_KEY } from "network/http/jwt-token-access"
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  Avatar,
  ConversationHeader,
} from "@chatscope/chat-ui-kit-react";
import { connect } from 'react-redux';
import zoeIco from 'assets/images/logo.svg';
import {
  addMessage,
  getChats,
  getMessages,
} from "store/actions";
import { withRouter } from 'react-router-dom';
// import MessageBubbleUIKit from './MessageBubbleUIKit';
import messageBubbleUIKit from './MessageBubble/MessageBubbleUIKit';
import ChatLeftSidebarUI from './ChatLeftSidebarUI';
import ChatRightSidebar from './ChatRightSidebar';
import NewTicketModal from 'components/Ticket/NewTicketModal';
import { Button, UncontrolledAlert } from 'reactstrap';
import SynchronizeChat from './Teacher/SynchronizeChat';
import JoinConversation from './JoinConversation';

const ConversationUIKit = ({
  conversation,
  onGetMessages,
  onAddMessage,
  messages,
  selectedMessage,
  currentUser,
  hideInput,
  history,
  disableSidebar,
  fetchingDetail,
  convError,
  children,
  isQuestion,
  isRequested }) => {
  const [userJoined, setUserJoined] = useState(false)
  const [loadedMessage, setLoadedMessage] = useState([])
  const [messageGroups, setMessageGroups] = useState([])
  const [conversationId, setConversationId] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastMessageUnix, setLastMessageUnix] = useState(0)

  useEffect(() => {
    const { groups, nextGroups } = messages

    setConversationId(conversation.id)
    if (groups !== messageGroups) {
      setMessageGroups(groups)
    }

    setLoadedMessage(nextGroups)
  }, [messages])

  useEffect(() => {
    if (!conversation.id) {
      return
    }

    if (fetchingDetail) {
      return
    }

    if (conversationId == conversation.id && messageGroups.length > 0) {
      return
    }

    setMessageGroups([])
    setLoadedMessage([])
    onGetMessages(conversation.id);
    setConversationId(conversation.id)
  }, [conversation])

  useEffect(() => {
    if (!conversation.participants) {
      return
    }
    const user = JSON.parse(localStorage.getItem(AUTH_USER_KEY))
    conversation.participants.map(v => {
      if (v.user_id === user.id) {
        setUserJoined(true)
      }
    })
  }, [conversation])

  useEffect(() => {
    if (loadingMore !== isRequested) {
      setLoadingMore(isRequested)
    }
  }, [isRequested])


  const handleOnSubmit = (value) => {
    const message = {
      conversation_id: conversation.id,
      is_question: isQuestion,
      message: value,
      createdAt: new Date(),
    };

    onAddMessage(message);
  }

  const onYReachStart = () => {
    const { count, groups, hasNext, lastMessageUnixTime } = messages

    if (!conversation.id) {
      return
    }

    if (loadingMore && !isRequested) {
      setLoadingMore(false);
    }

    if (!hasNext) {
      setLoadingMore(false);
      return;
    }

    if (loadingMore) {
      return
    }

    if (!groups) {
      return
    }

    if (lastMessageUnix == lastMessageUnixTime) {
      return
    }

    /* fetch from API */
    setLoadingMore(true);
    onGetMessages(conversation.id, parseInt(lastMessageUnixTime))
    setLastMessageUnix(lastMessageUnixTime)
  };

  const msgListRef = useRef()
  const scrollToBottom = () => msgListRef.current.scrollToBottom('smooth');


  return (
    <>
      {(convError && convError.detail) &&
        <UncontrolledAlert color='danger'>
          {convError.detail}
        </UncontrolledAlert>
      }
      <MainContainer responsive>
        {!disableSidebar &&
          <ChatLeftSidebarUI
            history={history}
            currentUser={currentUser}
          />
        }
        <ChatContainer>
          <ConversationHeader>
            <Avatar src={zoeIco} name={currentUser.fullname} />
            <ConversationHeader.Content userName={conversation.title} info="Active" />
            <ConversationHeader.Actions>
              {!disableSidebar &&
                <>
                  <SynchronizeChat conversation={conversation} />

                  <NewTicketModal
                    openNewTab={true}
                    conversationId={conversation.id} />
                </>
              }
            </ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList
            ref={msgListRef}
            loading={isRequested}
            onYReachStart={onYReachStart}
          >

            {/* {loadedMessages} */}
            {loadedMessage && loadedMessage.map(group => {
              return group.user_messages.map((user_message, ind) => (
                messageBubbleUIKit(user_message, scrollToBottom, selectedMessage, isQuestion, ind)
              ))
            })}

            {messageGroups && messageGroups.map(group => {
              return group.user_messages.map((user_message, ind) => (
                messageBubbleUIKit(user_message, scrollToBottom, selectedMessage, isQuestion, ind)
              ))
            })}

          </MessageList>

          {userJoined &&
            <MessageInput
              autoFocus
              disabled={!isQuestion && conversation.is_teacher_take_over == false}
              attachButton={false}
              onSend={handleOnSubmit}
              placeholder="Type message here" />
          }

        </ChatContainer>

        {!disableSidebar &&
          <ChatRightSidebar
            history={history}
            currentUser={currentUser}
          />
        }

        {children}

      </MainContainer>
      {!userJoined &&
        <JoinConversation conversation={conversation} />
      }
    </>
  )
}


const mapStateToProps = ({ chat, Conversation }) => ({
  selectedMessage: chat.selectedMessage,
  messages: chat.messages,
  isRequested: chat.isRequested,
  conversation: Conversation.conversationDetail,
  fetchingConversation: Conversation.fetchingConversation,
  fetchingDetail: Conversation.fetchingDetail,
  convError: Conversation.error,
});

const mapDispatchToProps = dispatch => ({
  onGetChats: () => dispatch(getChats()),
  onGetMessages: (roomId, unix = 0) => dispatch(getMessages(roomId, unix)),
  onAddMessage: (payload) => dispatch(addMessage(payload)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(ConversationUIKit))
