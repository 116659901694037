import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import { EDIT_PROFILE, GET_PROFILE, GET_PROFILE_DETAIL } from "./actionTypes";
import { profileSuccess, profileError, getProfileSuccess, getProfileDetailSuccess } from "./actions";
import { logoutUser } from "../login/actions";

// http api
import * as api from './api'

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(api.updateProfile, user)
    yield put(profileSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}

function* getProfile() {
  try {
    const response = yield call(api.get_profile)
    yield put(getProfileSuccess(response));
  } catch (error) {
    if (error.status_code == 401) {
      yield put(logoutUser())
    }
    yield put(profileError(error));
  }
}

function* getProfileDetail() {
  try {
    const response = yield call(api.get_profile_detail)
    yield put(getProfileDetailSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(GET_PROFILE, getProfile);
  yield takeEvery(GET_PROFILE_DETAIL, getProfileDetail)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
