import React from 'react'
import {
  Message,
} from "@chatscope/chat-ui-kit-react";
import moment from 'moment';
import MessageBubbleFooter from '../Footer/MessageBubbleFooter';
import user_avatar from 'assets/images/user-avatar-default.svg'
import SanitizeHTML from 'components/SanitizeHTML';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { addMessage } from 'store/chat/actions'

const MessageBubbleProductList = ({ sender, scrollToBottom, message, questionMode, style, addMessage }) => {
  const data = JSON.parse(message.message)

  const handleOnSelectProduct = (product) => {
    const payload = {
      company_id: product.company_id,
      store_id: product.store_id,
      message: `<a href="${product.product_url}">${product.name}</a>`,
      type: "product",
      conversation_id: message.conversation_id,
      attachment_id: product.pl_product_id,
      role: "buyer",
      is_question: questionMode
    }

    addMessage(payload)
    scrollToBottom()
  }


  return (
    <Message key={message.id}
      style={style}
      model={{
        message: message.message,
        sentTime: moment(message.created_at).fromNow(),
        sender: sender.fullname,
        position: "normal"
      }}
    >
      <Message.CustomContent>
        <SanitizeHTML html={data.message} />
        {data.products.map(p => {
          const product = JSON.parse(p)
          return (
            <div key={product.id}
              style={{ 'textAlign': 'center' }}
              className="text-align-center product-img mt-4 position-relative">
              {product.isOffer ? (
                <div className="avatar-sm product-ribbon">
                  <span className="avatar-title rounded-circle  bg-primary">
                    {`-${product.discount_percentage}%`}
                  </span>
                </div>
              ) : null}

              <img
                style={{ width: 150 }}
                src={product.pictures[0]}
                alt=""
                className="img-fluid mx-auto d-block mb-2"
              />
              <strong className='p-2 text-align-center mb-2'>{product.name}</strong>
              <br />
              <Button onClick={() => handleOnSelectProduct(product)} color='primary' >Pilih</Button>
              <br />
              <hr />
            </div>)
        })}
      </Message.CustomContent>
      <Message.Footer
        sentTime={moment(message.created_at).fromNow()}
      >
        <MessageBubbleFooter
          questionMode={questionMode}
          message={message} />
      </Message.Footer>
    </Message>
  )
}

export default connect(
  null,
  {
    addMessage
  }
)(MessageBubbleProductList)
