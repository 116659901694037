import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, Container, Row, Label, Button, Spinner } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import {
  apiError,
  registerUser,
  registerUserFailed,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// import images
import risa_logo from '../../assets/images/logo_risa.svg'
import { get } from "network/http/api";
import PlanService from "components/PlanService";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      company_name: '',
      phone_number: '',
      email: '',
      password: '',
      isLoading: false,
      plan_id: 0,
    };
  }

  componentWillUnmount(){
    this.setState({
      fullname: '',
      company_name: '',
      phone_number: '',
      email: '',
      password: '',
      isLoading: false,
      plan_id: 0,
    });
  }

  handleOnSubmit = (values) => {

    const {plan_id} = this.state
    if(plan_id < 1){
      return
    }

    const { history } = this.props;
    this.setState({
      isLoading: true
    })

    const data = {
      ...values,
      plan_id,
    }
    this.props.registerUser(data, history);
  }

  render() {
    const { isLoading } = this.state
    const { loading, registrationError} = this.props;
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="p-0">
                    <div className="auth-logo" style={{marginBottom: 0}}>
                      <Link to="/" className="auth-logo-dark">
                        <img
                          className="m-3"
                          src={risa_logo}
                          alt=""
                        />
                        <div className="p-4">
                          <p>Your AI Assistant for Business</p>
                          <h5>Join us for free</h5>
                        </div>
                      </Link>
                    </div>
                    <div className="p-5">
                      <Formik

                        enableReinitialize={true}
                        initialValues={{
                          fullname: (this.state && this.state.fullname) || "",
                          company_name: (this.state && this.state.company_name) || "",
                          phone_number: (this.state && this.state.phone_number) || "",
                          email: (this.state && this.state.email) || "",
                          password: (this.state && this.state.password) || "",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().required(
                            "Please Enter Your Email"
                          ),
                          fullname: Yup.string().required(
                            "Please Enter Valid Full Name"
                          ),
                          phone_number: Yup.string().required(
                            "Please Enter Valid Phone Number"
                          ),
                          company_name: Yup.string().required(
                            "Please Enter Your Company Name"
                          ),
                          password: Yup.string().required(
                            "Please Enter Valid Password"
                          ),
                        })}
                        onSubmit={this.handleOnSubmit}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="fullname" className="form-label">
                                Full Name
                              </Label>
                              <Field
                                name="fullname"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.fullname && touched.fullname
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="fullname"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="phone_number" className="form-label">
                                Phone Number
                              </Label>
                              <Field
                                name="phone_number"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.phone_number && touched.phone_number
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="phone_number"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="company_name" className="form-label">
                                Company Name
                              </Label>
                              <Field
                                name="company_name"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.company_name && touched.company_name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="company_name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="email"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <Field
                                name="password"
                                autoComplete="true"
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mb-3">
                              <Label for="plan_id" className="form-label">
                                Plan Service
                              </Label>
                              <PlanService
                                onClick={(id) => this.setState({plan_id: id})}/>
                              <Field
                                type='number'
                                name='plan_id' hidden={true} />
                              <ErrorMessage
                                name="plan_id"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            {registrationError &&
                              <Alert color="warning">
                                {registrationError.detail || 'Sorry, please try again'}
                              </Alert>
                            }

                            <div className="mt-4 d-grid">
                              {loading &&
                                <div className="text-center m-2">
                                  <Spinner />
                                </div>
                              }
                              <Button
                                disabled={loading}
                                color="primary"
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Register
                              </Button>
                            </div>

                            <div className="mt-4 text-center">
                              {/* TODO: ADD TERM OF SERVICE */}
                              {/* <p className="mb-0">
                                By registering you agree to the Risa AI{" "}
                                <Link to="#" className="text-primary">
                                  Terms of Use
                                </Link>
                              </p> */}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Already have an account ?{" "}
                    <Link to="/login" className="fw-medium text-primary">
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  apiError: PropTypes.any,
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.any,
  registrationError: PropTypes.any,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      registerUser,
      apiError,
      registerUserFailed,
    })(Register)
  );
