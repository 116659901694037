import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as moment from "moment";
import {
  Card,
  CardBody,
  Spinner,
} from "reactstrap";

export const handleValidDate = date => {
  const date1 = moment(new Date(date)).format("DD MMM Y");
  return date1;
};


class CustomizeTable extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      viewmodal: false,
      modal: false,
      orders: [],
      order: "",
    };
  }

  render() {
    const {
      onPageChange,
      loading,
      page,
      totalPage,
      columns,
      data,
      pagePerSize
    } = this.props;

    //pagination customization
    const pageOptions = {
      page: page,
      sizePerPage: pagePerSize || 10,
      totalSize: totalPage, // replace later with size(Order),
    };

    const defaultSorted = [
      {
        dataField: "orderId",
        order: "desc",
      },
    ];

    return (
      <div className="table-responsive"
        style={{ minHeight: 350 }}>
        {loading &&
          <div className="text-center loading-table-overlay">
            <Spinner type="grow" color="primary" />
          </div>
        }
        <BootstrapTable
          remote
          pagination={paginationFactory(pageOptions)}
          responsive
          keyField="id"
          data={data}
          columns={columns || []}
          loading={loading}
          bordered={false}
          striped={false}
          classes={
            "table align-middle table-nowrap table-check"
          }
          onTableChange={onPageChange}
          headerWrapperClasses={"table-light"}
        />
      </div>
    );
  }
}

export default CustomizeTable
