import * as types from './actionTypes'

const initialState = {
  host: '',
  status: '',
  errorCount: 0,
  timestamp: 0,
}

const WebSocket = (state = initialState, action) => {
  switch (action.type) {
    case types.WEBSOCKET_SUCCESS:
      return {
        status: 'connected',
        timestamp: action.timestamp,
        errorCount: 0
      }
    case types.INCREMENT_WS_ERROR_COUNT:
      return {
        ...state,
        errorCount: state.errorCount+1,
      }
    case types.WEBSOCKET_FAILURE:
      return {
        ...state,
        errorCount: state.errorCount+1,
        status: "failure"
      }
    // case types.WS_CONNECTED:
    //   return {
    //     status: 'connected',
    //     host: action.host,
    //   }
    // case types.WS_CONNECTING:
    //   return {
    //     status: 'connecting',
    //     host: action.host,
    //   }
    // case types.WS_DISCONNECT:
    //   return {
    //     status: 'disconnect',
    //     host: action.host,
    //   }
    case types.WEBSOCKET_CLOSED:
      return {
        status: 'disconnected',
        host: action.host,
      }
    default:
      break;
  }

  return state;
}

export default WebSocket;
