import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Container,
  Alert,
  Row,
  Col,
  Button,
  Spinner,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

// redux action
import { fetchQnaDictionary } from "store/qna-dictionary/actions";
import GroupingQNA from "components/Dataset/GroupingQNA";
import QNACard from "components/Dataset/QnaCard";
import SelectStoreInput from "components/SelectStoreInput";
import { useQuery } from "components/URLQuery";
import QnaModal from "components/Dataset/QnaModal";


const QNAKnowledgeList = ({
  onFetchQNADictionary,
  error,
  isFetching,
  qnaDictionaries,
  totalItem }) => {

  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState(0)
  const [selectedStore, setSelectedStore] = useState(0)
  const [selectedQNA, setselectedQNA] = useState(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  useEffect(() => {
    requestDictionary()
  }, [])

  const handleOnChangeFilter = (e) => {
    setPage(1)
    setFilter(e)

    requestDictionary(1, e, selectedStore)
  }

  const handleOnLoadMore = () => {
    let incPage = page + 1
    setPage(incPage)

    requestDictionary(incPage, filter, selectedStore)
  }

  const handleOnRefreshList = () => {
    requestDictionary(page)
    requestDictionary(page, filter, selectedStore)
  }

  const handleOnChangeStore = (e) => {
    setSelectedStore(e)
    setPage(1)
    requestDictionary(1, filter, e)
  }

  const requestDictionary = (page, filter, selectedStore) => {
    let query = ''
    if (filter == 1) {
      query = "is_approved=true"
    } else if (filter == 2) {
      query = "is_rejected=true"
    }

    if (selectedStore && selectedStore != 0) {
      if (query != '') {
        query = `${query}&&store_id=${selectedStore}`
      } else {
        query = `store_id=${selectedStore}`
      }
    }

    onFetchQNADictionary(page, 25, query)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {(error && error.detail) &&
            <Alert color="danger">
              {error.detail}
            </Alert>
          }
          <Row>
            <Col xs="5" className="mb-4">
              <InputGroup>
                <InputGroupText>Filter</InputGroupText>
                <Input type="select"
                  onChange={(e) => handleOnChangeFilter(e.target.value)}
                  value={filter}>
                  <option value={0}>All</option>
                  <option value={1}>Approved</option>
                  <option value={2}>Rejected</option>
                </Input>
              </InputGroup>
            </Col>
            <Col xs="5" className="mb-4">
              <InputGroup>
                <InputGroupText>Store</InputGroupText>
                <SelectStoreInput onChange={(e) => handleOnChangeStore(e)} value={selectedStore} />
              </InputGroup>
            </Col>
            <Col xs="2">
              <GroupingQNA refreshList={onFetchQNADictionary} />
            </Col>
            <Col xs="12">
              <div className="text-muted mb-3">
                <small>
                  Show {qnaDictionaries.length} for {totalItem}
                </small>
              </div>
            </Col>


            {isFetching &&
              <div className="mb-5 text-center">
                <Spinner type="grow" color="primary" />
              </div>
            }

            {qnaDictionaries.map((qna, index) => (
              <Col xs="6" key={index}>
                <QNACard
                  observeButton={
                    <Button outline color="primary" onClick={() => {
                      setIsOpenModal(true)
                      setselectedQNA(qna)
                    }}>Observe</Button>}
                  refreshList={handleOnRefreshList}
                  qna={qna} />
              </Col>
            ))}

            <QnaModal
              isOpen={isOpenModal}
              toggle={() => setIsOpenModal(!isOpenModal)}
              data={selectedQNA}
              refreshList={handleOnRefreshList} />

            <Col xs="12" className="text-center">
              <Button disabled={isFetching} onClick={handleOnLoadMore}>{isFetching ? 'Loading...' : 'Load More'}</Button>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = ({ QNADictionary }) => ({
  qnaDictionaries: QNADictionary.qnaDictionaries,
  totalItem: QNADictionary.totalItem,
  isFetching: QNADictionary.isFetching,
  error: QNADictionary.error,
});

export default connect(
  mapStateToProps,
  {
    'onFetchQNADictionary': fetchQnaDictionary
  }
)(withRouter(QNAKnowledgeList));
