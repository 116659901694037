import { call, put, select, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_STORE_PRODUCTS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCTS,
  GET_PRODUCTS_CATEGORY,
  GET_ID_CATEGORY_PRODUCTS,
  SEARCH_PRODUCT,
} from "./actionTypes"
import {
  getProductCategoryFail,
  getProductCategorySuccess,
  getProductDetailFail,
  getProductDetailSuccess,
  getProductsFail,
  getProductsSuccess,
} from "./actions"

// HTTP API
import * as api from './api'
import { apiError } from "store/actions"
import { find } from "lodash"

function* fetchProducts({ payload: { storeId, page } }) {
  try {
    const response = yield call(api.fetchProductCompany, storeId, page)

    yield put(getProductsSuccess(response))
  } catch (error) {
    yield put(getProductsFail(error))
  }
}

function* fetchProductDetail({ productId }) {
  try {
    const response = yield call(api.getProductDetail, productId)
    yield put(getProductDetailSuccess(response))
  } catch (error) {
    yield put(getProductDetailFail(error))
  }
}

function* fetchStoreProducts({ payload: { storeId, page } }) {
  try {
    const response = yield call(api.fetchProductStore, storeId, page)
    yield put(getProductsSuccess(response))
  } catch (error) {
    yield put(getProductsFail(error))
  }
}

function* fetchProductCategory({ payload }) {
  try {
    const response = yield call(api.fetchProductCategory, payload)

    yield put(getProductCategorySuccess(response))
  } catch (error) {
    yield put(getProductCategoryFail(error))
  }
}

function* fetchCategoryIdProduct({ payload: { page, categoryId } }) {
  try {
    const response = yield call(api.fetchCategoryIdProduct, categoryId, page)

    // get product state
    const { Product } = yield select()
    const { productCategories } = Product
    const cat = find(productCategories, { 'id': parseInt(categoryId) })

    let title = `All Products`
    if (cat) {
      title = `Product Category: ${cat.name}`
    }

    yield put(getProductsSuccess(response, title))
  } catch (error) {
    yield put(getProductsFail(error))
  }
}

function* searchProduct({ payload: { keyword } }) {
  try {
    const response = yield call(api.searchProduct, keyword)
    const title = `Keyword search: ${keyword}`
    yield put(getProductsSuccess(response, title))
  } catch (error) {
    yield put(getProductsFail(error))
  }
}

function* ecommerceSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts)
  yield takeEvery(GET_PRODUCTS_CATEGORY, fetchProductCategory)
  yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail)
  yield takeEvery(GET_STORE_PRODUCTS, fetchStoreProducts)
  yield takeEvery(GET_ID_CATEGORY_PRODUCTS, fetchCategoryIdProduct)
  yield takeEvery(SEARCH_PRODUCT, searchProduct)
}

export default ecommerceSaga
