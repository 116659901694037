import React, { useState, useEffect } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
// import DropdownSelected from "components/DropdownSelected";

const dataShow = [
  {
    name: "15",
    value: 15,
  },
  {
    name: "20",
    value: 20,
  },
  {
    name: "25",
    value: 25,
  },
];

const PagginationButton = ({ paramsPage, setParamsPage, amountPage }) => {
  const [showData, setShowData] = useState(dataShow[0]);

  useEffect(() => {
    const perPageData = dataShow.find(
      item => item.value === paramsPage.perPage
    );
    setShowData(perPageData);
  }, [paramsPage]);

  const onSelectedShowData = params => {
    setShowData(params);
    setParamsPage({
      perPage: params.value,
      page: paramsPage.page,
    });
  };

  const onPrevPage = params => {
    setParamsPage({
      ...paramsPage,
      page: params - 1,
    });
  };

  const onNextPage = params => {
    setParamsPage({
      ...paramsPage,
      page: params + 1,
    });
  };

  const renderButtons = () => {
    const maxButtonsToRender = 5;

    let buttonsToRender = Math.min(maxButtonsToRender, amountPage);
    const halfButtons = Math.floor(buttonsToRender / 2);

    let startIdx = Math.max(1, paramsPage.page - halfButtons);
    let endIdx = startIdx + buttonsToRender - 1;

    if (endIdx > amountPage) {
      endIdx = amountPage;
      startIdx = Math.max(1, endIdx - buttonsToRender + 1);
    }

    return [...Array(buttonsToRender)].map((_, idx) => (
      <div
        key={startIdx + idx}
        className={`btn-paggination ${
          paramsPage.page === startIdx + idx
            ? "btn-paggination-current-active"
            : ""
        }`}
        onClick={() =>
          setParamsPage({
            ...paramsPage,
            page: startIdx + idx,
          })
        }
      >
        {startIdx + idx}
      </div>
    ));
  };

  return (
    <div className="costume-paggination">
      <div className="paggination-show-data">
        {/* <span>Menampilkan</span>
        <DropdownSelected
          data={dataShow}
          selected={showData}
          setSelected={onSelectedShowData}
        />
        <span>perhalaman </span> */}
      </div>
      <div className="paggination-action">
        <div
          className={`btn-paggination rounded-left ${
            paramsPage.page === 1 ? "disabled" : ""
          }`}
          onClick={() => {
            if (paramsPage.page !== 1) {
              setParamsPage({
                ...paramsPage,
                page: 1,
              });
            }
          }}
        >
          <ChevronDoubleLeftIcon />
        </div>
        <div
          className={`btn-paggination ${
            paramsPage.page === 1 ? "disabled" : ""
          }`}
          onClick={() => {
            if (paramsPage.page !== 1) {
              onPrevPage(paramsPage.page);
            }
          }}
        >
          <ChevronLeftIcon />
        </div>
        {renderButtons()}
        <div
          className={`btn-paggination ${
            paramsPage.page === amountPage ? "disabled" : ""
          }`}
          onClick={() => {
            if (paramsPage.page === amountPage) {
              onNextPage(paramsPage.page);
            }
          }}
        >
          <ChevronRightIcon />
        </div>
        <div
          className={`btn-paggination rounded-right last ${
            paramsPage.page === amountPage ? "disabled" : ""
          }`}
          onClick={() => {
            if (paramsPage.page === amountPage) {
              setParamsPage({
                ...paramsPage,
                page: amountPage,
              });
            }
          }}
        >
          <ChevronDoubleRightIcon />
        </div>
      </div>
    </div>
  );
};

export default PagginationButton;
