import * as types from './actionTypes'

const initialState = {
  users: [],
  userDetail: {},
  totalUser: 0,
  userGeoLocation: null,
  isFetching: false,
  errorMessage: null,
}

const userInfo = (state = initialState, action) => {
  switch (action.type) {
    case types.EDIT_USER:
    case types.FETCH_USER_REQUEST:
      state = {
        ...state,
        isFetching: true,
      }
      break
    case types.EDIT_USER_SUCCESS:
      state = {
        ...state,
        userDetail: action.payload,
        isFetching: false,
        errorMessage: null,
      }
      break
    case types.EDIT_USER_FAILED:
      state = {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      }
      break
    case types.FETCH_USER_SUCCESS:
      state = {
        ...state,
        users: action.payload[0],
        totalUser: action.payload[1],
        isFetching: false,
        errorMessage: null
      }
      break
    case types.GET_USER_REQUEST:
      state = {
        ...state,
        isFetching: true,
      }
      break
    case types.GET_USER_SUCCESS:
      state = {
        ...state,
        userDetail: action.payload[0],
        userGeoLocation: action.payload[1],
        isFetching: false,
        errorMessage: null,
      }
      break
    case types.FETCH_USER_FAILED:
    case types.GET_USER_FAILED:
      state = {
        ...state,
        error: action.payload,
        isFetching: false,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default userInfo
