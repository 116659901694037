import React from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Spinner,
  UncontrolledAlert
} from 'reactstrap'
import { updateStoreRequest } from 'store/store/actions'

const StoreInfoInput = ({ store, updateStore, isFailed, isRequested, isSuccess }) => {
  const [offlieStoreAvailable, setOfflieStoreAvailable] = useState(store.offline_store_available)
  const [offlineStoreLocation, setOfflineStoreLocation] = useState(store.offline_store_location)
  const [operationHourStart, setOperationHourStart] = useState(store.operation_hour_start)
  const [operationHourEnd, setOperationHourEnd] = useState(store.operation_hour_end)

  const handleOnSubmit = useCallback(() => {
    if (isRequested) {
      return
    }

    const data = {
      is_offline_available: offlieStoreAvailable,
      offline_store_location: offlineStoreLocation,
      operation_hour_start: operationHourStart,
      operation_hour_end: operationHourEnd,
    }
    updateStore(store.id, data)
  }, [isRequested])


  return (
    <Card>
      <CardBody>
        <CardTitle className='mb-4'>Store Operation Information</CardTitle>

        {isRequested &&
          <div className='text-center mb-2'>
            <Spinner color='primary' type="grow" />
          </div>
        }

        {isSuccess &&
          <UncontrolledAlert color='success'>
            Success update store
          </UncontrolledAlert>
        }

        {isFailed &&
          <UncontrolledAlert color="danger">
            Failed to update store, please try again.
          </UncontrolledAlert>
        }

        <ListGroup>
          <ListGroupItem>
            <FormGroup switch>
              <Label>Is Offline Store Available?</Label>
              <Input checked={offlieStoreAvailable}
                onClick={() => setOfflieStoreAvailable(!offlieStoreAvailable)}
                type="switch" />
            </FormGroup>
          </ListGroupItem>
          {offlieStoreAvailable &&
            <ListGroupItem>
              <Label>Offline Store Location</Label>:<br />
              <Input multiple type="textarea"
                onChange={(e) => setOfflineStoreLocation(e.target.value)}
                value={offlineStoreLocation} />
              <small className='p-1 mt-2 text-muted'>This will help Customers when they want to visit your offline store, please put address your offline store</small>
            </ListGroupItem>
          }
          <ListGroupItem>
            <strong>Store Location</strong>:<br />{store.store_location}
          </ListGroupItem>
          <ListGroupItem>
            <strong>Shipment Location</strong>:<br />{store.shipping_location_district}, {store.shipping_location_city}
          </ListGroupItem>
          <ListGroupItem>
            <h5 className="font-size-14">
              <i className="bx bx-calendar me-1 text-primary" />
              Operation Hour:
            </h5>
            <br />
            <div className='mb-3'>
              <Label>
                Start Hour
              </Label>
              <Input
                onChange={(e) => setOperationHourStart(e.target.value)}
                value={operationHourStart} />
            </div>
            <div className='mt-2'>
              <Label>
                End Hour
              </Label>
              <Input
                onChange={(e) => setOperationHourEnd(e.target.value)}
                value={operationHourEnd} />
            </div>
          </ListGroupItem>
        </ListGroup>
        <Button onClick={handleOnSubmit} className='mt-5'>Save</Button>
      </CardBody>
    </Card>)
}

const mapStateToProps = ({ Store }) => {
  return {
    ...Store.update,
  }
}

export default connect(
  mapStateToProps,
  {
    updateStore: updateStoreRequest
  }
)(StoreInfoInput);
