const STATUS_STYLES = {
  verified: {
    backgroundColor: "#D7DFF0",
    color: "#3660B3",
  },
  registered: {
    backgroundColor: "#EBF2EF",
    color: "#649B84",
  },
  register: {
    backgroundColor: "#EBF2EF",
    color: "#649B84",
  },
  review_policy: {
    backgroundColor: "#ffc14d",
    color: "#ffffff",
  },
  platform_review: {
    backgroundColor: "#8450ff",
    color: "#ffffff",
  },
  active: {
    backgroundColor: "#29CCB1",
    color: "#ffffff",
  },
  pending: {
    backgroundColor: "#D7DFF0",
    color: "#3660B3",
  },
  deactivated: {
    backgroundColor: "#ff1a40",
    color: "#ffffff",
  },
};

const handleStatusName = paramsStatus => {
  switch (paramsStatus) {
    case "verified":
      return "Verified";
    case "registered":
      return "Registered";
    case "register":
      return "Register";
    case "review_policy":
      return "Review Policy";
    case "platform_review":
      return "Platform Review";
    case "active":
      return "Active";
    case "deactivated":
      return "Deactivated";
    case "pending":
      return "Pending";
    default:
      return "Unknown";
  }
};

const STATUS_DEFAULT = [
  { name: "Verified", value: "verified" },
  { name: "Registered", value: "registered" },
  { name: "Pending", value: "pending" },
];

const STATUS_DEFAULT_BISNIS = [
  { name: "Register", value: "register" },
  { name: "Review Policy", value: "review_policy" },
  { name: "Platform Review", value: "platform_review" },
  { name: "Active", value: "active" },
  { name: "Deactivated", value: "deactivated" },
];

export {
  STATUS_STYLES,
  STATUS_DEFAULT,
  STATUS_DEFAULT_BISNIS,
  handleStatusName,
};
