import React from 'react'
import {
  Message,
} from "@chatscope/chat-ui-kit-react";
import MessageBubbleFooter from '../Footer/MessageBubbleFooter';

const MessageBubbleText = ({ className, sender, message, questionMode, style }) => (
  <Message key={message.id}
    style={style}
    className={className}
    model={{
      message: message.message,
      sender: sender.fullname,
      position: "normal"
    }}
  >
    <Message.Footer
    >
      <MessageBubbleFooter
        questionMode={questionMode}
        message={message} />
    </Message.Footer>
  </Message>
)

export default MessageBubbleText
