import { get } from 'network/http/api';
import React, { Component } from 'react';
import { Card, CardBody, CardFooter, Spinner, UncontrolledTooltip } from 'reactstrap';


export const convertInt2Money = (price) => {
  let Currency = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'IDR',
  });
  return Currency.format(price)
}

class PlanService extends Component{
  constructor(props){
    super(props)

    this.state = {
      isLoading: false,
      planService: [],
      activePlanId: 0,
    }
  }

  componentDidMount(){
    this.getPlanService()
  }

  getPlanService = async () => {
    this.setState({
      isLoading: false
    })
    const response = await get('/auth/plan-service')
    this.setState({
      planService: response,
      isLoading: false,
    })
  }

  handleOnClick = (id) => {
    const {onClick} = this.props;
    this.setState({
      activePlanId: id,
    })

    onClick(id)
  }

  render(){
    const {isLoading, activePlanId, planService} = this.state;
    return (
      <div className='text-ceter plan-service'>
        {isLoading &&
          <Spinner />
        }

        {activePlanId < 1 &&
          <p style={{'color': '#f27a8f'}}>
            Please choose plan service
          </p>

        }

        {planService.map(plan => (
          <Card
            className={activePlanId == plan.id ? 'active' : ''}
            onClick={() => this.handleOnClick(plan.id)} key={plan.id}>
            <CardBody>
              <h4>
                  {plan.name}
              </h4>
              Price: <strong>{convertInt2Money(plan.price)}</strong>
            </CardBody>
            <CardFooter id={'plan_service_feature_'+plan.id}>Features detail <i className='fa fa-question-circle' /></CardFooter>
            <UncontrolledTooltip
              placement="top"
              target={'plan_service_feature_'+plan.id}
              className='text-left'
            >
              <div className='text-right'>
                <p>AI Agent: {plan.agent_number}</p>
                <p>Sub Account: {plan.sub_account_number}</p>
                <p>Max Conversation: {plan.limit_conversation}</p>

              </div>
            </UncontrolledTooltip>
          </Card>
        ))}
      </div>
    )
  }
}

export default PlanService;
