import React, { Component } from "react";
import {
  Col,
  Container,
  Row,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// actions
import ProfileUser from "components/Profile/ProfileUser";
import UploadAvatar from "components/Profile/UploadAvatar";
import { getUser } from "store/user/actions";
import UserGeoLocation from "components/Profile/UserGeoLocation";
// import ProfileChangePassword from "components/Profile/ProfileChangePassword";
// import ProfileCompany from "components/Profile/ProfileCompany";

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1
    };
  }

  componentDidMount() {
    const { onGetUser, match } = this.props;
    const { params } = match
    onGetUser(params.id)
  }

  render() {
    const { user, isFetching } = this.props;
    const { activeTab } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs="8">
                <UploadAvatar user={user} />
                <ProfileUser />
              </Col>
              <Col xs="4">
                <UserGeoLocation />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = ({ User }) => {
  return {
    'user': User.userDetail,
    'isFetching': User.isFetching,
  }
};


export default withRouter(
  connect(mapStateToProps, {
    onGetUser: getUser,
  })(UserDetail)
);
