import React, { useState } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";

const QnaSelectIntent = ({ intent, onChange }) => {
  const [selectedIntent, setSelectedIntent] = useState(intent)
  let options = [... new Set([
    '',
    intent,
    'product_recomendation',
    'product_guideline',
  ])]

  const handleOnChange = (e) => {
    setSelectedIntent(e)
    onChange(e)
  }

  return (
    <InputGroup>
      <InputGroupText>Intent</InputGroupText>
      <Input value={selectedIntent} onChange={(e) => handleOnChange(e.target.value)} type="select">
        {options.map((opt, i) => (
          <option value={opt} key={i}>{opt}</option>
        ))}
      </Input>
    </InputGroup>
  )
}

export default QnaSelectIntent
