import React, { useState } from 'react'
import classnames from "classnames"
import {
  Col, Nav, NavItem, Row,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap'

const ProductDetailImage = ({ pictures, url }) => {
  if (!pictures || pictures.length < 1) {
    return null
  }

  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="product-detai-imgs">
      <Row>

        <Col md="2" xs="3">
          <Nav className="flex-column" pills>
            {pictures.map((img, i) => (
              <NavItem key={i}>
                <NavLink
                  className={classnames({
                    active: activeTab === i,
                  })}
                  onClick={() => {
                    setActiveTab(i)
                  }}
                >
                  <img
                    src={img['url300']}
                    alt=""
                    className="img-fluid mx-auto d-block rounded"
                  />
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
        <Col md={{ size: 7, offset: 1 }} xs="9">
          <TabContent activeTab={activeTab}>
            {pictures.map((img, i) => (
              <TabPane tabId={i} key={i}>
                <div>
                  <img
                    src={img['original_url']}
                    alt="img"
                    id="expandedImg1"
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </TabPane>
            ))}
          </TabContent>
          <div
            className="text-center font-size-16 m-3">
            <a href={url} target="_blank" rel="noreferrer">
              View Product
            </a>
          </div>
        </Col>
      </Row>
    </div>
  )
}


export default ProductDetailImage;
