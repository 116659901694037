import { API_URL } from 'network/http/api';
import React, { Fragment } from 'react';

const Avatar = ({user}) => {
  return (
  <Fragment>
    {(user.avatar && user.avatar == '') &&
      <img src={AvatarDefault} className="avatar-lg img-fluid"/>
    }

    {(user.avatar && user.avatar != '') &&
      <img src={`${API_URL}/file/image/${user.avatar}`} className="avatar-lg img-fluid"/>
    }
  </Fragment>
  )
}

export default Avatar
