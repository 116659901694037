import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
const { ExpansionPanel } = require("@chatscope/chat-ui-kit-react")

const InfoMessageFeature = ({ message }) => {
  if (!message) {
    return null
  }

  if (!message.feature) {
    return null
  }

  const { intent_text, intention_score, sentiment_score, ner_scores } = message.feature

  return (
    <>
      <ExpansionPanel className='font-size-16' open title="INTENTION">
        <ListGroup className='font-size-12'>
          <ListGroupItem>
            <p>
              <strong>Message Context: </strong>
              {message.feature.current_context}
            </p>
            <p>
              <strong>Confidience: </strong>
              {intention_score.score.toFixed(2)}
            </p>
          </ListGroupItem>
          <ListGroupItem>
            <p>
              <strong>Intention: </strong>
              {intent_text}
            </p>
            <p>
              <strong>Confidience: </strong>
              {intention_score.score.toFixed(2)}
            </p>
          </ListGroupItem>
          <ListGroupItem>
            <p>
              <strong>Sentiment: </strong>
              {sentiment_score.label}
            </p>
            <p>
              <strong>Confidience: </strong>
              {sentiment_score.score.toFixed(2)}
            </p>
          </ListGroupItem>
          {ner_scores.map((ner, index) => (
            <ListGroupItem key={index}>
              <p>
                <strong>Word: </strong>
                {ner.word}
              </p>
              <p>
                <strong>Label: </strong>
                {ner.label}
              </p>
              <p>
                <strong>Confidience: </strong>
                {ner.score.toFixed(2)}
              </p>
            </ListGroupItem>
          ))}
        </ListGroup>
      </ExpansionPanel>
    </>
  )
}

export default InfoMessageFeature
