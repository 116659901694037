import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
} from "reactstrap";

//Import Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// Conversation Container
import ConversationUIKit from "components/Chat/ConversationUIKit";

// Redux Action
import {
  getConversation,
  getConversationDetail
} from "store/conversation/actions";

// init websocket
import { initialize as initializeWebSocket, close as closeWebSocket } from 'store/websocket/websocket_action'

// Token Access
import { AUTH_USER_KEY } from "network/http/jwt-token-access";
import WebSocketStatus from "components/WebSocket/WebSocketStatus";

class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      webSocketInit: false,
      conversationId: null,
    }
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem(AUTH_USER_KEY))
    this.setState({
      user,
    })

    this.onGetConversationDetail()
  }

  componentWillUnmount() {
    closeWebSocket()
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) {
      return
    }

    const user = JSON.parse(localStorage.getItem(AUTH_USER_KEY))
    this.setState({
      user,
    })

    const { webSocketConnected } = this.props;
    this.onGetConversationDetail()

    if (!webSocketConnected) {
      // wait until layer rendered
      setTimeout(() => {
        // connect to websocket
        initializeWebSocket()
      }, 500);
    }
  }

  onGetConversationDetail() {
    const { conversationId } = this.state;
    const { getConversationDetail, match: { params }, match } = this.props;

    if (params.conversationId && conversationId != params.conversationId) {
      getConversationDetail(params.conversationId)
      this.setState({
        conversationId: params.conversationId
      })
    }
  }

  render() {
    const { conversationDetail } = this.props;
    const { user } = this.state;
    //meta title
    document.title = "Chat | Risa";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Risa" breadcrumbItem="Chat" />

            <WebSocketStatus />

            <div className="w-100 user-chat"
              style={{ height: '80vh', position: "relative" }}>
              <ConversationUIKit
                isQuestion={false}
                disableSidebar={false}
                conversation={conversationDetail}
                currentUser={user} />
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = ({ Conversation, WebSocket }) => ({
  conversationDetail: Conversation.conversationDetail,
  conversation: Conversation.conversation,
  fetchingConversation: Conversation.fetchingConversation,
  fetchingDetail: Conversation.fetchingDetail,
  webSocketConnected: WebSocket.status === 'connected',
});

export default connect(mapStateToProps, {
  getConversation,
  getConversationDetail,
})(Chat);
