import React, { useEffect, useState } from 'react'
import { Alert, Button, Spinner } from 'reactstrap'
import ProductKnowledgeItem from './ProductKnowledgeItem'
import AddKnowledgeBase from './AddKnowledgeBase'
import { get } from 'network/http/api'
import { isEmpty, merge } from 'lodash'



const ProductKnowledge = ({ productId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [knowledges, setKnowledges] = useState([])
  const [page, setPage] = useState(1)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    handleOnFetch()
  }, [productId, page])

  const handleOnFetch = () => {
    if (!productId) {
      return
    }
    setIsLoading(true)
    get(`/admin/product/knowledge/${productId}?page=${page}`)
      .then(resp => {
        setIsLoading(false)
        setKnowledges(merge(knowledges, resp))
      })
      .catch(err => {
        setIsLoading(false)
        setErrorMessage(err.detail)
      })
  }

  return (
    <>
      <AddKnowledgeBase productId={productId} refreshList={handleOnFetch} />
      <div className='text-center mb-2'>
        {isLoading &&
          <Spinner type='grow' color='primary' />
        }
      </div>
      <div>
        {knowledges.map((qna, id) => (
          <ProductKnowledgeItem refreshFunc={handleOnFetch} data={qna} key={id} />
        ))}

        {isLoading &&
          <div className='text-center'>
            <Spinner color='primary' type="grow" />
          </div>
        }

        {!isEmpty(knowledges) &&
          <div className='text-center'>
            <Button onClick={() => setPage(page + 1)} disabled={isLoading}>Load more</Button>
          </div>
        }

        {isEmpty(knowledges) &&
          <div className='text-center'>
            <Alert color='warning'>
              Add knowledge for this product.
            </Alert>
          </div>
        }

      </div>
    </>
  )
}

export default ProductKnowledge;
