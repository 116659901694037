import SanitizeHTML from 'components/SanitizeHTML';
import moment from 'moment';
import React from 'react'
import { Badge, Button, Card, CardBody, CardFooter, CardTitle } from 'reactstrap';
import QnaBadges from './QnaBadges';

const QNACard = ({ qna, observeButton }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle>
          {observeButton}
          <small className='p-2' />
          {qna.is_approved ? <Badge color="primary">Approved</Badge> : ''}
          {qna.is_rejected ? <Badge>Rejected</Badge> : ''}
          <small className='p-2' />
          {qna.vectorization_id && qna.vectorization_id != '' ? <Badge>Embbeded</Badge> : ''}
        </CardTitle>
        <small className="text-muted mb-2">
          date: {moment(qna.created_at).format("DD/MM/YYYY hh:mm")}
        </small>
        <p className="mt-2 font-weight-semibold">Question:</p>
        <ol>
          {qna.questions.map((q, i) => (
            <li key={i} >
              <div className="text-wrapper">
                <SanitizeHTML html={q} />
              </div>
            </li>
          ))}
        </ol>

        <p className="font-weight-semibold">Answer:</p>
        {(!qna.is_approved && qna.answers.length) > 0 &&
          <div className="text-wrapper">
            <SanitizeHTML html={qna.answers[0]} />
          </div>
        }
        {(qna.is_approved) &&
          <div className="text-wrapper">
            <SanitizeHTML html={qna.approved_answer} />
          </div>
        }
      </CardBody>
      <CardFooter>
        <QnaBadges intents={qna.intents} />
      </CardFooter>
    </Card>
  )
}

export default QNACard;
