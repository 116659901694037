const { get, post, put, del } = require("network/http/api")

export const fetchConversation = (page = 1, platformId = 0, storeId = 0) => {
  return get(`/admin/conversation?page=${page}&platform_id=${platformId}&store_id=${storeId}`)
}

export const fetchConversationPlatform = (platformId, page = 1) => {
  return get(`/admin/conversation/platform/${platformId}?page=${page}`)
}

export const fetchConversationStore = (storeId, page = 1) => {
  return get(`/admin/conversation/store/${storeId}?page=${page}&`)
}

export const fetchConversationCompany = (companyId, page = 1) => {
  return get(`/admin/conversation/company/${companyId}?page=${page}`)
}

export const getConversationDetail = (id) => {
  return get(`/admin/conversation/${id}`)
}

export const getConversationSimulation = () => {
  return get(`/admin/conversation/simulation`)
}

export const getConversationSimulationStore = (storeId) => {
  return get(`/admin/conversation/simulation-store/${storeId}`)
}
